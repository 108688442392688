import { orderBy } from "lodash"

export default function getLatestAttachmentUrl(attachments: FileAttachment[]) {
	if (!attachments.length) {
		return ""
	}

	const urls = orderBy(
		attachments.filter(a => !!a.file && !!a.file.publicUrls?.url),
		[(item: FileAttachment) => item.file.createdDate],
		["desc"],
	)

	if (!urls[0]) {
		return ""
	}

	return urls[0].file.publicUrls?.url || ""
}
