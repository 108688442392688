import { useTranslation } from "react-i18next"
import Contacts from "./Contacts"
import styled from "styled-components"
import SectionTitle from "./SectionTitle"
import mediaQuery from "../../../util/mediaQuery"

const ContactSection = () => {
	const { t } = useTranslation()
	return (
		<MainContainer id="contacts">
			<SectionTitle sectionName={t("contact_plural")} />
			<ContactContainer>
				<Contacts />
			</ContactContainer>
		</MainContainer>
	)
}

const MainContainer = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	border: ${({ theme }) => `1px solid ${theme.techPackBorder}`};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 30px;
	margin-top: 30px;
	width: 100%;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
`
const ContactContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	${mediaQuery("lg")`
		flex-direction: column;
	`}
`

export default ContactSection
