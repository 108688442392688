import styled from "styled-components"
import { ProductionSection } from "./ProductionSection"
import { Divider, Stack } from "@mui/material"

export const Facilities: React.FC<{}> = () => {
	return (
		<FacilitiesContainer>
			<ProductionSection stageSubcategory="facility" />
			<StyledDivider />
			<ProductionSection stageSubcategory="parking" />
			<StyledDivider />
			<ProductionSection stageSubcategory="informationTech" />
		</FacilitiesContainer>
	)
}

const FacilitiesContainer = styled(Stack)`
	& {
		gap: 48px;
	}
`

const StyledDivider = styled(Divider)`
	border-color: ${({ theme }) => theme.colorPalette.outline.variant};
	width: 100%;
`
