import { getFileIconName } from "@/util/attachmentUtils"
import { SVGProps } from "react"

interface DynamicFileProps extends SVGProps<SVGSVGElement> {
	filename?: string
}

export const DynamicFile = ({
	filename = "",
	width = "24px",
	height = "24px",
	...rest
}: DynamicFileProps) => {
	const extensionName = getFileIconName(filename)

	switch (extensionName) {
		case "pdf":
			return (
				<svg
					width={width}
					height={height}
					viewBox="0 0 32 33"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					{...rest}
				>
					<path
						d="M0 4.44556C0 2.25806 1.75 0.445557 4 0.445557H14V8.44556C14 9.57056 14.875 10.4456 16 10.4456H24V19.4456H11C8.75 19.4456 7 21.2581 7 23.4456V32.4456H4C1.75 32.4456 0 30.6956 0 28.4456V4.44556ZM24 8.44556H16V0.445557L24 8.44556ZM11 22.4456H13C14.875 22.4456 16.5 24.0706 16.5 25.9456C16.5 27.8831 14.875 29.4456 13 29.4456H12V31.4456C12 32.0081 11.5 32.4456 11 32.4456C10.4375 32.4456 10 32.0081 10 31.4456V28.4456V23.4456C10 22.9456 10.4375 22.4456 11 22.4456ZM13 27.4456C13.8125 27.4456 14.5 26.8206 14.5 25.9456C14.5 25.1331 13.8125 24.4456 13 24.4456H12V27.4456H13ZM19 22.4456H21C22.625 22.4456 24 23.8206 24 25.4456V29.4456C24 31.1331 22.625 32.4456 21 32.4456H19C18.4375 32.4456 18 32.0081 18 31.4456V23.4456C18 22.9456 18.4375 22.4456 19 22.4456ZM21 30.4456C21.5 30.4456 22 30.0081 22 29.4456V25.4456C22 24.9456 21.5 24.4456 21 24.4456H20V30.4456H21ZM26 23.4456C26 22.9456 26.4375 22.4456 27 22.4456H30C30.5 22.4456 31 22.9456 31 23.4456C31 24.0081 30.5 24.4456 30 24.4456H28V26.4456H30C30.5 26.4456 31 26.9456 31 27.4456C31 28.0081 30.5 28.4456 30 28.4456H28V31.4456C28 32.0081 27.5 32.4456 27 32.4456C26.4375 32.4456 26 32.0081 26 31.4456V27.4456V23.4456Z"
						fill="currentColor"
					/>
				</svg>
			)
		case "img":
			return (
				<svg
					width={width}
					height={height}
					viewBox="0 0 24 33"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					{...rest}
				>
					<path
						d="M4 0.445557H14V8.44556C14 9.57056 14.875 10.4456 16 10.4456H24V28.4456C24 30.6956 22.1875 32.4456 20 32.4456H4C1.75 32.4456 0 30.6956 0 28.4456V4.44556C0 2.25806 1.75 0.445557 4 0.445557ZM16 0.445557L24 8.44556H16V0.445557ZM4 16.4456C4 17.5706 4.875 18.4456 6 18.4456C7.0625 18.4456 8 17.5706 8 16.4456C8 15.3831 7.0625 14.4456 6 14.4456C4.875 14.4456 4 15.3831 4 16.4456ZM13.5 18.4456V18.5081C13.125 18.5081 12.8125 18.6331 12.625 18.8831L9.625 23.3206L8.8125 21.9456C8.625 21.6331 8.3125 21.4456 8 21.4456C7.625 21.4456 7.3125 21.6331 7.125 21.9456L4.125 26.9456C3.9375 27.2581 3.9375 27.6331 4.125 27.9456C4.25 28.2581 4.625 28.4456 5 28.4456H8H11H13.5H19C19.375 28.4456 19.6875 28.2581 19.875 27.9456C20 27.6331 20 27.1956 19.8125 26.8831L14.3125 18.8831C14.125 18.6331 13.8125 18.4456 13.5 18.4456Z"
						fill="currentColor"
					/>
				</svg>
			)
		case "video":
			return (
				<svg
					width={width}
					height={height}
					viewBox="0 0 18 24"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					{...rest}
				>
					<path
						d="M3 0H10.5V6C10.5 6.84375 11.1562 7.5 12 7.5H18V21C18 22.6875 16.6406 24 15 24H3C1.3125 24 0 22.6875 0 21V3C0 1.35938 1.3125 0 3 0ZM12 0L18 6H12V0ZM3 13.5V18C3 18.8438 3.65625 19.5 4.5 19.5H9C9.79688 19.5 10.5 18.8438 10.5 18V13.5C10.5 12.7031 9.79688 12 9 12H4.5C3.65625 12 3 12.7031 3 13.5ZM14.0625 18.6562C14.1562 18.75 14.2969 18.75 14.3906 18.75C14.7188 18.75 15 18.5156 15 18.1875V13.3594C15 13.0312 14.7188 12.75 14.3906 12.75C14.2969 12.75 14.1562 12.7969 14.0625 12.8906L12 14.25V17.25L14.0625 18.6562Z"
						fill="currentColor"
					/>
				</svg>
			)
		case "file":
			return (
				<svg
					width={width}
					height={height}
					viewBox="0 0 384 512"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					{...rest}
				>
					<path
						d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"
						fill="currentColor"
					/>
				</svg>
				// <svg
				// 	width={width}
				// 	height={height}
				// 	viewBox="0 0 384 512"
				// 	fill="none"
				// 	xmlns="http://www.w3.org/2000/svg"
				// >
				// 	<path
				// 		d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"
				// 		fill={color}
				// 	/>
				// </svg>
			)
	}
}
