import { Checkbox as MUICheckbox, CheckboxProps as MUICheckboxProps } from "@mui/material"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { useTheme } from "styled-components"

interface CheckboxProps extends MUICheckboxProps {
	checkboxSize?: number
}

const Checkbox: React.FC<CheckboxProps> = ({
	checked,
	onChange,
	checkboxSize = 18,
	disabled,
	...props
}) => {
	const { colorPalette } = useTheme()

	return (
		<MUICheckbox
			data-cy-component="Component-Checkbox"
			checked={checked}
			disabled={disabled}
			onChange={onChange}
			icon={
				<CheckBoxOutlineBlank
					htmlColor={colorPalette.surface.onVariant}
					sx={{
						width: `${checkboxSize}px`,
						height: `${checkboxSize}px`,
						opacity: disabled ? 0.38 : 1,
					}}
				/>
			}
			checkedIcon={
				<CheckBox
					htmlColor={colorPalette.primary.value}
					sx={{
						width: `${checkboxSize}px`,
						height: `${checkboxSize}px`,
						opacity: disabled ? 0.38 : 1,
					}}
				/>
			}
			sx={{
				margin: "0px",
				padding: "8px",
			}}
			{...props}
		/>
	)
}

export default Checkbox
