import { AudioFile, AudioFileOutlined } from "@mui/icons-material"

function TechPack({ color = "black", size = 16, fill = false }: UpdatedIconTypes) {
	const height = size * 1.25
	const width = size

	return fill ? (
		<AudioFile width={width} height={height} htmlColor={color} />
	) : (
		<AudioFileOutlined width={width} height={height} htmlColor={color} />
	)
}

export default TechPack
