const DEFAULT_VENUE: Venue = {
	id: "",
	name: "",
	address: "",
	city: "",
	state: "",
	zip: "",
	phone: "",
	website: "",
	facebook: "",
	instagram: "",
	twitter: "",
	description: "",
	image: "",
	stages: [],
	venueTypeId: "",
	addressLine1: "",
	addressLine2: "",
	country: "",
	latitude: 0,
	longitude: 0,
	ageRequirement: "",
	previousNames: [],
	notes: [],
	urls: [],
	contacts: [],
}

export default DEFAULT_VENUE
