import { forwardRef } from "react"
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from "@mui/material/MenuItem"
import { styled } from "@mui/material"

export type MenuItemProps = {
	supportText?: string
	optionSize?: "lg" | "md" | "sm"
	startIcon?: JSX.Element
	endIcon?: JSX.Element
	"data-cy-component"?: string
} & MuiMenuItemProps

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
	({ children, supportText, optionSize = "sm", startIcon, endIcon, ...rest }, ref) => {
		return (
			<StyledMenuItem
				disableRipple
				supportText={supportText}
				optionSize={optionSize}
				ref={ref}
				{...rest}
				data-cy-component={
					rest["data-cy-component"] ? rest["data-cy-component"] : "Component-MenuItem"
				}
			>
				{startIcon && (
					<IconContainer data-cy-component="Component-MenuItem_StartIcon">
						{startIcon}
					</IconContainer>
				)}
				<StyledContent data-cy-component="Component-MenuItem_Content">
					<StyledLabel id="styled-label" data-cy-component="Component-MenuItem_Label">
						{children}
					</StyledLabel>
					{supportText && (
						<StyledSupportText data-cy-component="Component-MenuItem_SupportText">
							{supportText}
						</StyledSupportText>
					)}
				</StyledContent>
				{endIcon && (
					<IconContainer data-cy-component="Component-MenuItem_EndIcon" trailing>
						{endIcon}
					</IconContainer>
				)}
			</StyledMenuItem>
		)
	},
)

const StyledMenuItem = styled(MuiMenuItem, {
	shouldForwardProp: prop => prop !== "supportText" && prop !== "optionSize",
})<{
	supportText?: string
	optionSize?: "lg" | "md" | "sm"
}>`
	display: inline-flex;
	justify-content: start;
	align-items: center;

	width: 100%;
	height: ${({ supportText, optionSize }) => {
		if (supportText) {
			return 60
		}
		switch (optionSize) {
			case "lg":
				return 56
			case "md":
				return 48
			default:
				return 40
		}
	}}px;
	padding: 8px 12px;
	margin: 0;
	gap: 12px;

	background-color: none;

	&.MuiButtonBase-root:hover {
		background-color: ${({ theme }) => theme.colorPalette.surface.on}14;
	}

	&.MuiButtonBase-root.Mui-selected {
		background-color: ${({ theme }) => theme.colorPalette.surface.container.highest};

		&:hover {
			background-color: ${({ theme }) => theme.colorPalette.surface.on}14;
		}
	}

	transition: background-color 150ms ease-out;
`

export const StyledMenuItemRoot = styled(MuiMenuItem, {
	shouldForwardProp: prop => prop !== "supportText" && prop !== "optionSize",
})<{
	supportText?: string
	optionSize?: "lg" | "md" | "sm"
}>`
	display: inline-flex;
	justify-content: start;
	align-items: center;

	width: 100%;
	height: ${({ supportText, optionSize }) => {
		if (supportText) {
			return 60
		}
		switch (optionSize) {
			case "lg":
				return 56
			case "md":
				return 48
			default:
				return 40
		}
	}}px;
	padding: 8px 12px;
	margin: 0;
	gap: 12px;

	background-color: none;

	&.MuiButtonBase-root:hover {
		background-color: ${({ theme }) => theme.colorPalette.surface.on}14;
	}

	&.MuiButtonBase-root.Mui-selected {
		background-color: ${({ theme }) => theme.colorPalette.surface.container.highest};

		&:hover {
			background-color: ${({ theme }) => theme.colorPalette.surface.on}14;
		}
	}

	transition: background-color 150ms ease-out;
`

const IconContainer = styled("div")<{ trailing?: boolean }>`
	width: 24px;
	height: 24px;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	${({ trailing }) => trailing && "justify-self: end;"}
`

const StyledContent = styled("div")`
	display: inline-block;
	width: 100%;
`
const StyledLabel = styled("p")`
	font: 400 16px/24px Signika-Regular;
	letter-spacing: 0.5px;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`
const StyledSupportText = styled("p")`
	font: 400 14px/20px Signika-Regular;
	letter-spacing: 0.25px;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
`
