import parseStageAttachments from "../util/parseStageAttachments"
import { createContext, useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import axios, { AxiosError } from "axios"
import DEFAULT_STAGE from "../util/constants/defaults/DEFAULT_STAGE"
import DEFAULT_VENUE from "../util/constants/defaults/DEFAULT_VENUE"
import ensureVarIsArray from "../util/ensureVarIsArray"
import { useParams } from "react-router-dom"
import getLatestAttachmentUrl from "../util/getLatestAttachmentUrl"
import isObjectEmptyRecursive from "../util/isObjectEmptyRecursive"
import { OopsOverlay } from "@/components/views/OopsOverlay"

/**
 * Using axios instead of useAxios to skip auth checks on tech-pack page
 *
 */

const { REACT_APP_API_URL } = process.env

const INITIAL_STATE = {
	selectedAttachment: {
		category: "",
		field: "",
		selectedIndex: 0,
	},
	showHeader: false,
}

export const TechPackContext = createContext<TechPackContextTypes>({
	state: INITIAL_STATE,
	setState: () => null,
	venue: DEFAULT_VENUE,
	modifiedDate: "",
	venueLogoUrl: "",
	stage: DEFAULT_STAGE,
	personnel: [],
	attachments: {},
	attachmentModalPrevious: () => null,
	attachmentModalNext: () => null,
	closeSelectedAttachmentModal: () => null,
	showSection: () => false,
	showSubsection: () => false,
	showSearchModal: false,
	setShowSearchModal: () => null,
})

export default function TechPackContextProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const [state, setState] = useState<TechPackStateInterface>(INITIAL_STATE)
	const [serverError, setServerError] = useState<Error | null>(null)
	const [showSearchModal, setShowSearchModal] = useState(false)
	const { venueIdOrName, stageId } = useParams()
	const venueInUrl = encodeURI(venueIdOrName || "")

	const techPackEndpointUrl = useMemo(
		() =>
			`${REACT_APP_API_URL}/venues/v1/venues/${venueInUrl}/tech-pack${
				stageId ? `/${stageId}` : ""
			}`,
		[venueInUrl, stageId],
	)

	// get tech pack data except attachments
	const {
		data = {
			data: {
				data: {
					venue: { personnel: [], fileAttachments: [], ...DEFAULT_VENUE },
					venueLogoUrl: "",
					stage: DEFAULT_STAGE,
				},
			},
		},
	} = useQuery({
		queryKey: [`tech-pack-venue-${venueInUrl}-${stageId}`],
		queryFn: async () => {
			return await axios
				.post(techPackEndpointUrl, {
					fileAttachments: false,
					includeUrls: true,
					personnel: true,
					venue: true,
					venueFileAttachments: true,
					venuePersonnel: true,
					venuePersonnelFileAttachments: true,
					venueStage: true,
				})
				.catch((error: AxiosError<ApiErrorResponseData>) => {
					const responseData = error.response?.data
					setServerError({
						name: error.request?.status,
						message: responseData?.message || error.message,
					})
				})
		},
		retry: false,
	})

	const { stage } = data?.data?.data
	let { venue } = data?.data?.data

	const modifiedDate = stage?.metadata?.venueModifiedDate ?? ""

	venue = {
		...venue,
		contacts: ensureVarIsArray(venue.contacts),
		previousNames: ensureVarIsArray(venue.previousNames),
		urls: ensureVarIsArray(venue.urls),
		notes: ensureVarIsArray(venue.notes),
	}

	const { personnel, fileAttachments: venueFileAttachments } = data.data.data.venue

	let venueLogoUrl = getLatestAttachmentUrl(venueFileAttachments)

	// get tech pack attachments
	const { data: fileAttachments = [] } = useQuery({
		queryKey: [`tech-pack-attachments-${venueInUrl}-${stageId}`],
		queryFn: async () => {
			return await axios
				.post<{
					data: {
						fileAttachments: FileAttachment[]
					}
				}>(techPackEndpointUrl, {
					fileAttachments: true,
					includeUrls: true,
					personnel: false,
					venue: false,
					venueFileAttachments: false,
					venuePersonnel: false,
					venuePersonnelFileAttachments: false,
					venueStage: false,
					urlDuration: 300,
				})
				.then(res => res.data.data.fileAttachments)
		},
		retry: false,
	})
	const attachments = parseStageAttachments(fileAttachments ?? [])

	// handle next/last button in ImageModal
	const attachmentModalPrevious = (arrayLength: number) =>
		setState(s => {
			const newIndex =
				s.selectedAttachment.selectedIndex < 1
					? arrayLength - 1
					: s.selectedAttachment.selectedIndex - 1

			return {
				...s,
				selectedAttachment: {
					...s.selectedAttachment,
					selectedIndex: newIndex,
				},
			}
		})
	const attachmentModalNext = (arrayLength: number) =>
		setState(s => {
			const max = arrayLength - 1

			const newIndex =
				s.selectedAttachment.selectedIndex >= max ? 0 : s.selectedAttachment.selectedIndex + 1

			return {
				...s,
				selectedAttachment: {
					...s.selectedAttachment,
					selectedIndex: newIndex,
				},
			}
		})

	// reset state.selectedAttachment to close ImageModal
	const closeSelectedAttachmentModal = () =>
		setState(s => ({
			...s,
			selectedAttachment: {
				selectedIndex: 0,
				category: "",
				field: "",
			},
		}))

	//show section only if fields have a value or Attachment
	const showSection = (data: string) =>
		!isObjectEmptyRecursive(stage[data]) ||
		!isObjectEmptyRecursive(attachments[data]) ||
		!isObjectEmptyRecursive(attachments[data])

	// showSection but check keys only from a subsection (eg: production.staging , facilities.parking)
	// passing in [field] is the same as a showField function (eg: used in CreateTechRow)
	const showSubsection = (data: string, fields: readonly string[]) => {
		return !isObjectEmptyRecursive(
			fields.reduce<Record<string, boolean>>((acc, field) => {
				const value = stage[data][field]
				acc[field] = !!(
					(typeof value === "object" ? !isObjectEmptyRecursive(value) : !!value) ||
					(attachments[data] ? attachments[data][field] : false)
				)
				return acc
			}, {}),
		)
	}

	return (
		<TechPackContext.Provider
			value={{
				state,
				setState,
				venue,
				modifiedDate,
				venueLogoUrl,
				stage,
				personnel,
				attachments,
				attachmentModalPrevious,
				attachmentModalNext,
				closeSelectedAttachmentModal,
				showSection,
				showSubsection,
				showSearchModal,
				setShowSearchModal,
			}}
		>
			{serverError !== null && <OopsOverlay error={serverError} info={{}} clearError={() => {}} />}
			{children}
		</TechPackContext.Provider>
	)
}
