import { useState, useMemo, useRef, useEffect } from "react"
import { PageHeader } from "../organisms/PageHeader"
import NewButton from "../atoms/NewButton"
import { AddOutlined } from "@mui/icons-material"
import AddStageModal from "../organisms/venueStage/AddStageModal"
import { Box, Stack } from "@mui/material"
import ButtonGroup from "../molecules/ButtonGroup"
import { VenueStageDetail } from "../organisms/venueStage/VenueStageDetail"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useVenuesContext } from "@/context/venues"
import { useConfirmCloseContext } from "@/context/confirmClose"
import mediaQuery from "@/util/mediaQuery"

export const VenueStage: React.FC<{}> = () => {
	const { t } = useTranslation()
	const scrollableRef = useRef<HTMLDivElement>(null)
	const [addStageModalOpen, setAddStageModalOpen] = useState(false)
	const [tabIndex, setTabIndex] = useState(0)
	const { selectedVenue } = useVenuesContext()
	const { shouldPreventClose, setShouldPreventClose, handleConfirmNavgate } =
		useConfirmCloseContext()

	const [stageHasChanges, setStageHasChanges] = useState<Record<string, boolean>>({})

	const { mainStage, activeStages, inactiveStages } = useMemo(() => {
		if (selectedVenue === null) {
			return {
				mainStage: null,
				activeStages: [],
				inactiveStages: [],
			}
		}

		return selectedVenue.stages.reduce(
			(prev, curr) => {
				if (curr.isMainStage) {
					return {
						...prev,
						mainStage: curr,
					}
				}

				if (curr.isActive) {
					return {
						...prev,
						activeStages: [...prev.activeStages, curr],
					}
				}

				return {
					...prev,
					inactiveStages: [...prev.inactiveStages, curr],
				}
			},
			{
				mainStage: null,
				activeStages: [],
				inactiveStages: [],
			} as {
				mainStage: null | Stage
				activeStages: Stage[]
				inactiveStages: Stage[]
			},
		)
	}, [selectedVenue])

	useEffect(() => {
		scrollableRef.current?.scrollTo({ top: 0, left: 0 })
	}, [tabIndex])

	useEffect(() => {
		const isEdited = Object.values(stageHasChanges).find(val => val === false) !== undefined
		if (isEdited && !shouldPreventClose) {
			setShouldPreventClose(true)
		} else if (!isEdited && shouldPreventClose) {
			setShouldPreventClose(false)
		}
	}, [stageHasChanges, setShouldPreventClose, shouldPreventClose])

	return (
		<RootContainer>
			<AddStageModal
				selectedVenueId={selectedVenue?.id || null}
				modalOpen={addStageModalOpen}
				closeModal={() => setAddStageModalOpen(false)}
			/>
			<PageHeader pageTitle={t("venueStage")}>
				<NewButton
					text={t("addNewStage")}
					buttonType="Outline"
					borderColor="primary"
					iconElement={<AddOutlined />}
					onClick={() => setAddStageModalOpen(true)}
				/>
			</PageHeader>
			<StyledButtonGroup
				buttonList={[
					{ id: 0, text: t("activeStage_plural") },
					{ id: 1, text: t("inactiveStage_plural") },
				]}
				activeButtonId={tabIndex}
				onClickDispatch={buttonId => handleConfirmNavgate(() => setTabIndex(buttonId))}
			/>
			{tabIndex === 1 && (
				<InactivePlaceholderBox>
					<InactiveWarningHeader>{t("inactiveStagesNoticeHeader")}</InactiveWarningHeader>
					<InactiveWarningBody>{t("inactiveStagesNoticeBody")}</InactiveWarningBody>
				</InactivePlaceholderBox>
			)}
			<ScrollableStack ref={scrollableRef}>
				{tabIndex === 0 ? (
					<>
						{mainStage && (
							<VenueStageDetail
								stageHasChanges={stageHasChanges}
								setStageHasChanges={setStageHasChanges}
								stageId={mainStage.id}
								techPackUrl={mainStage.publicUrls.customSlugUrl || mainStage.publicUrls.techPackUrl}
								{...mainStage}
							/>
						)}
						{activeStages.map(stage => {
							return (
								<VenueStageDetail
									key={stage.id}
									stageHasChanges={stageHasChanges}
									setStageHasChanges={setStageHasChanges}
									stageId={stage.id}
									techPackUrl={stage.publicUrls.customSlugUrl || stage.publicUrls.techPackUrl}
									{...stage}
								/>
							)
						})}
					</>
				) : (
					inactiveStages.map((stage, idx) => {
						return (
							<VenueStageDetail
								key={stage.id}
								stageHasChanges={stageHasChanges}
								setStageHasChanges={setStageHasChanges}
								hideDivider={idx === 0}
								stageId={stage.id}
								techPackUrl={stage.publicUrls.customSlugUrl || stage.publicUrls.techPackUrl}
								{...stage}
							/>
						)
					})
				)}
			</ScrollableStack>
		</RootContainer>
	)
}

const InactivePlaceholderBox = styled(Box)`
	padding-bottom: 24px;
	padding: 0 48px;
`

const StyledButtonGroup = styled(ButtonGroup)`
	padding: 0 48px;
	padding-bottom: 24px;

	${mediaQuery("md")`
		padding: 0 16px;
		padding-bottom: 24px;
	`}
`

const RootContainer = styled(Stack)`
	height: 100%;
	overflow-y: hidden;
`

const ScrollableStack = styled(Stack)`
	overflow-y: auto;
	height: auto;
	padding: 0px 48px;
	padding-bottom: 24px;

	${mediaQuery("md")`
		padding: 0 16px;
	`}
`

const InactiveWarningBody = styled("p")`
	font: 400 16px/24px Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`

const InactiveWarningHeader = styled("p")`
	font: 600 16px/24px Signika-Semibold;

	&::before {
		content: "*";
	}
`
