import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom"
import MainLayout from "./MainLayout"
import { Profile } from "./Profile"
import { VenueStage } from "./VenueStage"
import { VenueContextProvider } from "../../context/venues"
import Production from "./Production"
import { History } from "./History"
import { ConfirmCloseProvider } from "../../context/confirmClose"
import { StaffCrew } from "./StaffCrew"
import UserContextProvider from "../../context/user"
import { useEffect } from "react"
import { LOCAL_STORAGE_REDIRECT_AFTER_LOGIN } from "../../context/axios"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import useUserContext from "../../hooks/useUserContext"
import ProductionAttatchmentsContextProvider from "@/context/productionAttachments"

const AppRoutes = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const analytics = getAnalytics()
	const { user } = useUserContext()

	// user meant to be on a specific page, add redirect for no token after logging back in
	useEffect(() => {
		if (!localStorage.token) {
			localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, location.pathname)
			navigate("/login")
		} else if (location.pathname === "/") {
			navigate("/production")
		}
	}, [location, navigate])

	useEffect(() => {
		// if user is logged, update firebase with id
		if (localStorage.token && user.userId) {
			setUserId(analytics, user.userId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.userId])

	useEffect(() => {
		if (location.pathname !== "/") {
			logEvent(analytics, "page_view", {
				page_title: location.pathname,
				page_location: window.location.href,
				page_path: location.pathname,
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	return (
		<UserContextProvider>
			<ConfirmCloseProvider>
				<VenueContextProvider>
					<ProductionAttatchmentsContextProvider>
						<Routes>
							<Route path="/" element={<MainLayout />}>
								<Route path="/venuestage" element={<VenueStage />} />
								<Route path="/production" element={<Production />} />
								<Route path="/history" element={<History />} />
								<Route path="/profile" element={<Profile />} />
								<Route path="/staff-crew" element={<StaffCrew />} />
							</Route>
							<Route path="/*" element={<Navigate to="/production" />} />
						</Routes>
					</ProductionAttatchmentsContextProvider>
				</VenueContextProvider>
			</ConfirmCloseProvider>
		</UserContextProvider>
	)
}

export default AppRoutes
