import { EditOutlined } from "@mui/icons-material"
import { styled } from "@mui/material"

export const PencilOverlay: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
	return (
		<PencilOverlayBase style={style} data-cy-component="PencilOverlay_Component">
			<EditOutlined
				sx={{
					height: "64px",
					width: "64px",
				}}
			/>
		</PencilOverlayBase>
	)
}
const PencilOverlayBase = styled("div")`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 100ms;

	& svg {
		opacity: 0%;
		transition: opacity 100ms;
		color: white;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colorPalette.secondary.container.on}29;

		& svg {
			opacity: 100%;
			color: white;
		}
	}

	&:active {
		background-color: ${({ theme }) => theme.colorPalette.primary.value}14;
		& svg {
			opacity: 100%;
			color: ${({ theme }) => theme.colorPalette.outline.value};
		}
	}
`
