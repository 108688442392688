const DEFAULT_STAGE: Stage = {
	capacity: 0,
	description: "", // Does this exist anymore?
	equipmentData: {},
	facilitiesData: {},
	isActive: true,
	id: "",
	isMainStage: false,
	localCrewCallData: {},
	localCrewData: {},
	logisticsData: {},
	metadata: {
		includedInTechPack: false,
	},
	modifiedDate: "",
	name: "",
	productionData: {},
	publicUrls: {
		techPackUrl: "",
	},
	venueId: "",
	venueStageTypeId: "",
}

export default DEFAULT_STAGE
