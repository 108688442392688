import mediaQuery from "@/util/mediaQuery"
import { useVenuesContext } from "@/context/venues"
import { Stack } from "@mui/material"
import styled from "styled-components"

type PageHeaderProps = {
	children?: React.ReactNode
	pageTitle: string
}

export const PageHeader: React.FC<PageHeaderProps> = ({ pageTitle, children }) => {
	const { selectedVenue, selectedStage } = useVenuesContext()

	return (
		<StyledStack>
			<Stack justifyContent="space-between" gap="8px">
				<PageSubtitle>
					{selectedVenue?.name} - {selectedStage?.name}
				</PageSubtitle>
				<PageTitle>{pageTitle}</PageTitle>
			</Stack>
			{children && <Stack justifyContent="center">{children}</Stack>}
		</StyledStack>
	)
}

const StyledStack = styled(Stack)`
	&.MuiStack-root {
		padding: 24px 48px;
		justify-content: space-between;
		flex-direction: row;

		${mediaQuery("md")`
			padding: 24px 16px;
		`}
	}
`

const PageSubtitle = styled("p")`
	font: 500 16px/24px "FiraSans-Medium", sans-serif;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
`

const PageTitle = styled("h1")`
	font: 400 36px/44px "FiraSans-Regular", sans-serif;
	text-align: left;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`
