import {
	Box,
	Table,
	TableBody,
	TableHead,
	TableProps,
	TableSortLabel,
	TablePagination,
} from "@mui/material"
import { Dispatch, ReactNode, Ref, SetStateAction, useCallback, useMemo, useState } from "react"
import { TableCellProps } from "@mui/material/TableCell"
import { getObjectEntries } from "@util/typedObjectMapping"
import { visuallyHidden } from "@mui/utils"

import { TableRow } from "@atoms/TableRow"
import { TableCell } from "@atoms/TableCell"

type Order = "asc" | "desc"

export type CellData<DataType extends string | boolean | number> =
	| DataType
	| {
			value: DataType
			displayContent: ReactNode
			cellProps?: TableCellProps
	  }

export type RowData<TableKeyUnion extends string> = Record<
	TableKeyUnion,
	CellData<string | boolean | number>
>

export type HeaderCell<TableKeyUnion extends string> = {
	cellKey: TableKeyUnion
	label: string
	canSort?: boolean
	cellProps?: TableCellProps
}

const useSortedData = <TableKeyUnion extends string>(
	unsortedData: RowData<TableKeyUnion>[],
	sortedKey: TableKeyUnion,
	order: Order,
	isSortable: boolean,
) => {
	const getCellValue = useCallback(
		<CellDataType extends string | number | boolean>(
			cellData: CellData<CellDataType>,
		): CellDataType => {
			if (
				!(
					typeof cellData === "string" ||
					typeof cellData === "number" ||
					typeof cellData === "boolean"
				)
			) {
				return cellData.value
			}
			return cellData
		},
		[],
	)

	return useMemo(() => {
		if (!isSortable) {
			return unsortedData
		}
		return unsortedData.sort((a, b) => {
			const valA = getCellValue(a[sortedKey])
			const valB = getCellValue(b[sortedKey])

			if (typeof valA === "string" && typeof valB === "string") {
				if (order === "asc") {
					return valA.localeCompare(valB)
				}
				return valB.localeCompare(valA)
			}

			if (typeof valA === "boolean" && typeof valB === "boolean") {
				if (order === "asc") {
					return valA > valB ? -1 : 1
				} else {
					return valA > valB ? 1 : -1
				}
			}

			if (order === "asc") {
				return valA > valB ? 1 : -1
			} else {
				return valA > valB ? -1 : 1
			}
		})
	}, [unsortedData, sortedKey, order, isSortable, getCellValue])
}

interface SortedTableHeadProps<TableKeyUnion extends string> {
	order: Order
	setOrder: Dispatch<SetStateAction<"asc" | "desc">>
	sortedKey: TableKeyUnion
	setSortedKey: Dispatch<SetStateAction<TableKeyUnion>>
	headCells: HeaderCell<TableKeyUnion>[]
	isSortable: boolean
}
const SortedTableHead = <TableKeys extends string>({
	order,
	setOrder,
	sortedKey,
	setSortedKey,
	headCells,
	isSortable,
}: SortedTableHeadProps<TableKeys>) => {
	const sortLabelOnClick = useCallback(
		(cellKey: TableKeys) => {
			if (cellKey === sortedKey) {
				setOrder(val => (val === "asc" ? "desc" : "asc"))
			} else {
				setSortedKey(cellKey)
				setOrder("asc")
			}
		},
		[setSortedKey, setOrder, sortedKey],
	)

	return (
		<TableHead>
			<TableRow>
				{headCells.map(cell => (
					<TableCell
						align="left"
						{...(cell.cellProps || {})}
						sx={{
							textTransform: "capitalize",
						}}
						key={cell.label}
					>
						{cell.canSort && isSortable ? (
							<TableSortLabel
								active={sortedKey === cell.cellKey}
								direction={sortedKey === cell.cellKey ? order : "asc"}
								onClick={() => sortLabelOnClick(cell.cellKey)}
							>
								{cell.label}
								{sortedKey === cell.cellKey ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							cell.label
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

interface SortedTableRowProps<TableKeyUnion extends string> {
	idx: number
	rowData: RowData<TableKeyUnion>
}
const SortedTableRow = <TableKeyUnion extends string>({
	idx,
	rowData,
}: SortedTableRowProps<TableKeyUnion>) => {
	return (
		<TableRow
			sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			className={idx % 2 !== 0 ? "off-color" : ""}
		>
			{getObjectEntries(rowData).map(([cellKey, cellData], idx) => {
				let cellProps = {}
				let displayData: ReactNode
				if (
					!(
						typeof cellData === "string" ||
						typeof cellData === "number" ||
						typeof cellData === "boolean"
					)
				) {
					cellProps = cellData.cellProps || {}
					displayData = cellData.displayContent
				} else {
					displayData = cellData
				}
				return (
					<TableCell key={`${idx}-${cellKey}`} align="left" variant="smallText" {...cellProps}>
						{displayData}
					</TableCell>
				)
			})}
		</TableRow>
	)
}

interface SortableListProps<TableKeyUnion extends string> extends Omit<TableProps, "ref"> {
	headerData: HeaderCell<TableKeyUnion>[]
	tableContent: RowData<TableKeyUnion>[]
	tableRef?: Ref<HTMLTableElement> | null
	isSortable?: boolean
	isPaginated?: boolean
	defaultSortedColumn?: TableKeyUnion
	rowsPerPage?: number
}
export const SortableList = <TableKeyUnion extends string>({
	headerData,
	tableContent,
	defaultSortedColumn,
	tableRef,
	rowsPerPage = 9,
	isSortable = true,
	isPaginated = false,
	...rest
}: SortableListProps<TableKeyUnion>) => {
	const [order, setOrder] = useState<"asc" | "desc">("asc")
	const [sortedKey, setSortedKey] = useState<TableKeyUnion>(
		defaultSortedColumn || headerData[0].cellKey,
	)
	const [page, setPage] = useState(0)

	const sortedData = useSortedData(tableContent, sortedKey, order, isSortable)

	const visibleData = useMemo(() => {
		if (!isPaginated) {
			return sortedData
		}
		return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
	}, [sortedData, page, rowsPerPage, isPaginated])

	return (
		<div>
			<Table ref={tableRef} {...rest}>
				<SortedTableHead
					order={order}
					setOrder={setOrder}
					sortedKey={sortedKey}
					setSortedKey={setSortedKey}
					headCells={headerData}
					isSortable={isSortable}
				/>
				<TableBody>
					{visibleData.map((rowData, idx) => (
						<SortedTableRow key={idx} idx={idx} rowData={rowData} />
					))}
				</TableBody>
			</Table>
			{isPaginated && (
				<TablePagination
					component="div"
					count={sortedData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={(_, newPage) => setPage(newPage)}
					rowsPerPageOptions={[rowsPerPage]}
				/>
			)}
		</div>
	)
}
