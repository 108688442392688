import { useEffect, useState } from "react"
import NavSelect from "../../atoms/navigation/NavSelect"
import { useTranslation } from "react-i18next"
import { useVenuesContext } from "@/context/venues"

function VenueSelector({ zIndex }: { zIndex?: number }) {
	const { t } = useTranslation()
	const { selectedVenue, setSelectedStage, setSelectedVenue, stages, venues, userCanViewVenues } =
		useVenuesContext()
	const [newSelectedVenue, setNewSelectedVenue] = useState<Venue | null>(null)

	function handleChange(_: any, newValue: Venue | null) {
		setNewSelectedVenue(newValue)
	}

	useEffect(() => {
		if (newSelectedVenue !== null && newSelectedVenue !== selectedVenue) {
			const venueStages = stages.filter(stage => stage.venueId === newSelectedVenue.id)

			if (venueStages.length === 0) {
				setSelectedStage(null)
			} else {
				setSelectedStage(venueStages.find(val => val.isMainStage) || venueStages[0])
			}
			setSelectedVenue(newSelectedVenue)
		}
	}, [newSelectedVenue, selectedVenue, stages, setSelectedVenue, setSelectedStage])

	if (!selectedVenue?.id) {
		return null
	}

	if (!userCanViewVenues) {
		return null
	}

	return (
		<NavSelect
			componentName={t("selectVenues")}
			selectValue={selectedVenue}
			onChange={handleChange}
			optionValues={venues}
			noOptionsLabel={t("noAdditionalVenues")}
			zIndex={zIndex}
		/>
	)
}

export default VenueSelector
