import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Main from "./components/views/Main"
import { Login } from "./components/views/Login"
import useDarkMode from "./hooks/useDarkMode"
import getTheme from "./theme"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles"
import useOnlineStatus from "./hooks/useOnlineStatus"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import TechPack from "./components/views/TechPack"
import { ResetPassword } from "./components/views/ResetPassword"

const AppRoutes = () => {
	const isOnline = useOnlineStatus()
	const [showToast, setShowToast] = useState(!isOnline)
	const { t } = useTranslation()
	useEffect(() => {
		const notify = () => {
			toast.error(t("checkConnection"), { autoClose: false, toastId: "checkConnection" })
		}
		if (!isOnline && !showToast) {
			setShowToast(true)
			notify()
		} else if (isOnline && showToast) {
			setShowToast(false)
			toast.dismiss()
		}
	}, [isOnline, showToast, setShowToast, t])
	const { darkMode } = useDarkMode()
	const theme = getTheme(darkMode)
	const muiTheme = createTheme(theme)
	return (
		<MuiThemeProvider theme={muiTheme}>
			<ThemeProvider theme={theme}>
				<Router>
					<Routes>
						<Route path="/*" element={<Main />} />
						<Route path="/login" element={<Login />} />
						<Route path="/password/:code" element={<ResetPassword />} />
						<Route path={`/venue/:venueIdOrName/`} element={<TechPack />} />
						<Route path={`/venue/:venueIdOrName/:stageId`} element={<TechPack />} />
					</Routes>
				</Router>
			</ThemeProvider>
		</MuiThemeProvider>
	)
}

export default AppRoutes
