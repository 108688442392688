import { Cancel, Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { css, useTheme } from "styled-components"
import { toast } from "react-toastify"
import NewModal from "@/components/molecules/NewModal"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { useCallback } from "react"
import { useVenuesContext } from "@/context/venues"

export const DeleteAttachmentModal: React.FC<{}> = () => {
	const {
		state: {
			deleteAttachmentModalOpen,
			addEditModalState: { selectedAttachmentId, previewData, editFileAttachments },
		},
		setState,
		deleteAttachment,
		bulkUpdateAttachments,
	} = useProductionAttachments()
	const { selectedStage } = useVenuesContext()

	const theme = useTheme()
	const { t } = useTranslation()

	const handleSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault()
			if (!selectedAttachmentId || !selectedStage) {
				toast.error(t("tryAgain"))
			} else {
				const newAttachmentOrder = previewData.reduce<Pick<FileAttachment, "metadata" | "id">[]>(
					(prev, curr) => {
						if (curr.id !== selectedAttachmentId && editFileAttachments[curr.id] !== undefined) {
							return [
								...prev,
								{
									id: curr.id,
									metadata: {
										...editFileAttachments[curr.id].metadata,
										order: prev.length,
									},
								},
							]
						}
						return prev
					},
					[],
				)

				if (newAttachmentOrder.length > 0) {
					await bulkUpdateAttachments(newAttachmentOrder)
				}

				await deleteAttachment(selectedAttachmentId)

				toast.success(t("attachmentDeleted"))

				if (newAttachmentOrder.length === 0) {
					setState(prev => ({
						...prev,
						deleteAttachmentModalOpen: false,
						editAttachmentModalOpen: false,
						addAttachmentModalOpen: true,
						addEditModalState: {
							...prev.addEditModalState,
							addData: {},
							editData: {},
							editFileAttachments: {},
							previewData: [],
							selectedAttachmentId: undefined,
						},
					}))
				} else {
					setState(prev => ({
						...prev,
						deleteAttachmentModalOpen: false,
						addEditModalState: {
							...prev.addEditModalState,
							selectedAttachmentId: undefined,
						},
					}))
				}
				setState(prev => ({
					...prev,
					deleteAttachmentModalOpen: false,
					editAttachmentModalOpen: newAttachmentOrder.length !== 0,
					addEditModalState: {
						...prev.addEditModalState,
						selectedAttachmentId: undefined,
					},
				}))
			}
		},
		[
			t,
			setState,
			deleteAttachment,
			selectedAttachmentId,
			selectedStage,
			previewData,
			editFileAttachments,
			bulkUpdateAttachments,
		],
	)

	return (
		<NewModal
			size="sm"
			open={deleteAttachmentModalOpen}
			closeModal={() =>
				setState(prev => ({
					...prev,
					deleteAttachmentModalOpen: false,
				}))
			}
			titleIcon={<Cancel htmlColor={theme.colorPalette.error.container.on} />}
			title={t("deletePermenantly?")}
			borderColor={theme.colorPalette.error.container.on}
			subHeader={t("actionCannotBeUndone")}
			onSubmit={handleSubmit}
			component="form"
			id="delete-modal"
			footerActions={{
				end: [
					"Cancel",
					{
						iconElement: <Close htmlColor={theme.colorPalette.error.container.on} />,
						text: t("delete"),
						buttonType: "Outline",
						buttonStyles: css`
							border-color: ${theme.colorPalette.error.container.on};
							color: ${theme.colorPalette.error.container.on};
						`,
						textStyles: css`
							color: ${theme.colorPalette.error.container.on};
						`,
						type: "submit",
						form: "delete-modal",
					},
				],
			}}
		/>
	)
}
