import { Groups, GroupsOutlined } from "@mui/icons-material"

function StaffCrew({ color = "black", size = 24, fill = false }: UpdatedIconTypes) {
	const height = size / 2
	const width = size

	return fill ? (
		<Groups width={width} height={height} htmlColor={color} />
	) : (
		<GroupsOutlined width={width} height={height} htmlColor={color} />
	)
}

export default StaffCrew
