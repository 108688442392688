export const DEFAULT_FILE: FileObject = {
	id: "",
	folderPath: "",
	filename: "",
	disk: "",
	filesize: 0,
	createdDate: "",
	createdBy: "",
	modifiedDate: "",
	modifiedBy: "",
	isDeleted: 0,
	metadata: {
		filename: "",
	},
	status: {
		upload: "Waiting For Action",
	},
	publicUrls: {
		url: "",
		urlDownload: "",
		urlPreview: "",
	},
}
export const DEFAULT_FILE_ATTACHMENT: FileAttachment = {
	id: "",
	fileId: "",
	type: "",
	category: "",
	attachedToType: "object",
	attachedToId: "",
	metadata: {
		includedInTechPack: false,
	},
	file: DEFAULT_FILE,
	createdByUser: {
		createdBy: null,
		email: "",
		hasLoggedIn: null,
		includedInTechpack: false,
		isActive: false,
		lastNotifiedSignup: "",
		name: {},
		order: null,
		phone: null,
		roleName: null,
		title: null,
		userId: "",
	},
}
