import React, { useState } from "react"
import { LoginForm } from "../organisms/login/LoginForm"
import { ForgotPasswordForm } from "../organisms/login/ForgotPasswordForm"
import { LoginContextProvider } from "@/context/login"
import LoaderOverlay from "../organisms/LoaderOverlay"
import useLogin from "@/hooks/useLogin"
import { LoginWrapper } from "../organisms/login/LoginWrapper"
import { LoginFooter } from "../organisms/login/LoginFooter"

const LoginBase: React.FC<{}> = () => {
	const [isReset, setIsReset] = useState(false)
	const { loginPending, resetPasswordPending } = useLogin()

	return (
		<>
			<LoaderOverlay isLoading={loginPending || resetPasswordPending} initialLoading={false} />
			<LoginWrapper>
				{isReset ? <ForgotPasswordForm /> : <LoginForm />}
				<LoginFooter isReset={isReset} updatePage={() => setIsReset(prev => !prev)} />
			</LoginWrapper>
		</>
	)
}

export const Login: React.FC<{}> = () => {
	return (
		<LoginContextProvider>
			<LoginBase />
		</LoginContextProvider>
	)
}
