import { useProfileContext } from "@/context/profile"
import { Stack, styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useTranslation } from "react-i18next"

export const VenueLevelPermissions: React.FC<{}> = () => {
	const { t } = useTranslation()
	const { userProfile } = useProfileContext()
	return (
		<RootContainer>
			<HeaderContainer>
				<h3>{t("venueLevelPermission_plural")}</h3>
				<p>{t("venueLevelPermissionBlurb")}</p>
			</HeaderContainer>
			<StyledTable>
				<TableHead>
					<TableRow>
						<TableCell>{t("venueName")}</TableCell>
						<TableCell>{t("venueLocation")}</TableCell>
						<TableCell>{t("permissionLevel")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(userProfile?.rolesAndLocations.Venue ?? []).map(val => (
						<TableRow key={val.name ?? ""}>
							<TableCell>{val.name ?? ""}</TableCell>
							<TableCell>
								{val.state === null && val.city === null
									? "N/A"
									: [val.city ?? "", val.state ?? ""].join(", ")}
							</TableCell>
							<TableCell>{val.role ?? ""}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</StyledTable>
		</RootContainer>
	)
}

const StyledTable = styled(Table)`
	& .MuiTableCell-root {
		font-size: 14px;
		line-height: 20px;
		padding: 10px 12px;
		color: ${({ theme }) => theme.colorPalette.surface.on};
		width: 33.33%;
		border-bottom: 1px solid ${({ theme }) => theme.colorPalette.surface.container.value};
	}

	& .MuiTableHead-root .MuiTableCell-root {
		background-color: ${({ theme }) => theme.colorPalette.surface.container.value};
		font-family: Signika-Medium;
		font-weight: 500;
	}

	& .MuiTableBody-root .MuiTableCell-root {
		font-weight: 400;
		font-family: Signika-Regular;
	}
`
//rgb(250, 249, 253)
const HeaderContainer = styled(Stack)`
	& {
		gap: 16px;
		padding: 0px;
	}

	& h3 {
		width: 199px;
		font-family: Roboto-Regular;
		font-size: 24px;
		line-height: 32px;
		font-weight: 400;
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}

	& p {
		width: 198px;
		font-family: Signika-Medium;
		font-weight: 500;
		font-size: 11px;
		line-height: 16px;
		color: ${({ theme }) => theme.colorPalette.secondary.value};
	}
`

const RootContainer = styled(Stack)`
	& {
		flex-direction: row;
		gap: 48px;
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}
`
