import styled from "styled-components"
import { ProductionContextProvider, useProductionContext } from "../../context/production"
import ProductionAttatchmentsContextProvider from "@/context/productionAttachments"
import ButtonGroup from "../molecules/ButtonGroup"
import { useTranslation } from "react-i18next"
import { Stack } from "@mui/material"
import { PageHeader } from "../organisms/PageHeader"
import { SectionHeader } from "../organisms/production/SectionHeader"
import { AddEditAttachmentModal } from "../organisms/production/AddEditAttachmentModal"
import { ProductionSection } from "../organisms/production/ProductionSection"
import { Facilities } from "../organisms/production/Facilities"
import mediaQuery from "@/util/mediaQuery"
import { GeneralVenueInformation } from "../organisms/production/GeneralVenueInformation"
import { ProductionSubsection } from "../organisms/production/ProductionSubsection"
import { Contacts } from "../organisms/production/Contacts"
import LoaderOverlay from "../organisms/LoaderOverlay"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { useConfirmCloseContext } from "@/context/confirmClose"
import { useCallback } from "react"

export const PRODUCTION_PAGE_TRANSLATION_KEYS = [
	"generalVenueInfo",
	"contact_plural",
	"production",
	"facilities",
	"equipment",
	"logistic_plural",
	"localCrew",
] as const

export type ProductionPageTranslationKey = (typeof PRODUCTION_PAGE_TRANSLATION_KEYS)[number]

function ProductionPage() {
	const { t } = useTranslation()

	const {
		state: { productionTabIndex },
		updateProductionTabIndex,
	} = useProductionContext()

	const { handleConfirmNavgate } = useConfirmCloseContext()

	const { venueLogoUploadIsPending } = useProductionAttachments()

	const updateTab = useCallback(
		(idx: number) => {
			handleConfirmNavgate(() => updateProductionTabIndex(idx))
		},
		[updateProductionTabIndex, handleConfirmNavgate],
	)

	return (
		<>
			<LoaderOverlay isLoading={venueLogoUploadIsPending} />
			<AddEditAttachmentModal modalType="add" />
			<AddEditAttachmentModal modalType="edit" />
			<RootContainer>
				<PageHeader pageTitle={t("production")} />
				<StyledButtonGroup
					buttonList={PRODUCTION_PAGE_TRANSLATION_KEYS.map((val, idx) => ({
						id: idx,
						text: t(val),
					}))}
					activeButtonId={productionTabIndex}
					onClickDispatch={updateTab}
				/>
				<SectionHeader pageTranslationKey={PRODUCTION_PAGE_TRANSLATION_KEYS[productionTabIndex]} />

				<ScrollableStack>
					{productionTabIndex === 0 ? (
						<GeneralVenueInformation />
					) : productionTabIndex === 1 ? (
						<Contacts />
					) : productionTabIndex === 2 ? (
						<ProductionSubsection />
					) : productionTabIndex === 3 ? (
						<Facilities />
					) : productionTabIndex === 4 ? (
						<ProductionSection stageSubcategory="equipmentData" />
					) : productionTabIndex === 5 ? (
						<ProductionSection stageSubcategory="logisticsData" />
					) : productionTabIndex === 6 ? (
						<ProductionSection stageSubcategory="localCrewData" />
					) : (
						<></>
					)}
				</ScrollableStack>
			</RootContainer>
		</>
	)
}

export default function Production() {
	return (
		<ProductionContextProvider>
			<ProductionAttatchmentsContextProvider>
				<ProductionPage />
			</ProductionAttatchmentsContextProvider>
		</ProductionContextProvider>
	)
}

const StyledButtonGroup = styled(ButtonGroup)`
	padding: 0px 48px;

	${mediaQuery("md")`
		padding: 0px 16px;
	`}
`

const RootContainer = styled(Stack)`
	height: 100%;
	overflow-y: hidden;
`

const ScrollableStack = styled(Stack)`
	overflow-y: scroll;
	height: auto;
	padding: 0px 48px;
	padding-bottom: 24px;

	${mediaQuery("md")`
		padding: 0px 16px;
	`}
`
