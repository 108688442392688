import { breakpoints } from "../util/mediaQuery"
import { useCallback } from "react"

/** Returns a function to navigate to an element by ID */
export default function useTechPackNavigationHandler(handleClose?: () => void) {
	const getYOffset = useCallback((windowWidth: number) => {
		if (windowWidth <= breakpoints["sm"]) {
			return -90
		} else {
			return -120
		}
	}, [])

	/** Queries the document for given sectionId and scrolls window to the found element, adjusting for breakpoints */
	const handleNavigate = useCallback(
		(sectionId: string) => {
			const element = document.getElementById(sectionId)

			const yOffset = getYOffset(window.innerWidth)
			if (element) {
				const y = element.getBoundingClientRect().top + window.scrollY + yOffset
				window.scrollTo({ top: y, behavior: "smooth" })
			}
			if (handleClose) {
				handleClose()
			}
		},
		[getYOffset, handleClose],
	)

	return handleNavigate
}
