import { createContext, useMemo, useState } from "react"
import Axios, { AxiosError, AxiosInstance } from "axios"
import { useTranslation } from "react-i18next"
import { OopsOverlay } from "@/components/views/OopsOverlay"

export const LOCAL_STORAGE_REDIRECT_AFTER_LOGIN = "mv-redirect"

export const AxiosContext = createContext<AxiosInstance>(Axios)

export default function AxiosProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
	const { t } = useTranslation()
	const [serverError, setServerError] = useState<Error | null>(null)
	const handleBadRequests = (error: AxiosError<ApiErrorResponseData>) => {
		const { status } = error.request
		if (window.location.pathname !== "/login") {
			if (status === 401) {
				//invalid token
				localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, window.location.pathname)
				window.location.href = "/login"
			} else if (status === 403) {
				//revoked token
				window.location.href = "/login"
			} else {
				const responseData = error.response?.data
				setServerError({
					name: status,
					message: responseData
						? responseData.message !== ""
							? responseData.message
							: t(
									responseData.translation.key,
									Object.entries(responseData.translation.templateCodes).reduce<
										Record<string, string>
									>((prev, curr) => {
										return {
											...prev,
											[curr[0]]: t(`common.${curr[1]}`),
										}
									}, {}),
							  )
						: error.message,
				})
			}
		}
	}

	const axios = useMemo(() => {
		const axios = Axios.create({
			headers: {
				"Content-Type": "application/json",
			},
		})

		axios.interceptors.request.use(config => {
			config.baseURL = process.env.REACT_APP_API_URL

			const token = localStorage.getItem("token")
			if (token) {
				config.headers.Authorization = `Bearer ${token}`
			} else {
				// there is no token and we are not logging in, redirect
				if (config.url !== "/venues/v1/login/masterVenue") {
					if (window.location.pathname !== "/login") {
						localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, window.location.pathname)
					}
					window.location.href = "/login"
				}
			}

			return config
		}, handleBadRequests)

		axios.interceptors.response.use(response => response, handleBadRequests)

		return axios
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<AxiosContext.Provider value={axios}>
			{serverError !== null && <OopsOverlay error={serverError} info={{}} clearError={() => {}} />}
			{children}
		</AxiosContext.Provider>
	)
}
