// lists of stage translation keys by subcategory
const STAGE_CATEGORIES = [
	"equipmentData",
	"facilitiesData",
	"localCrewData",
	"logisticsData",
	"productionData",
] as const

const PRODUCTION_SUBCATEGORIES = [
	"staging",
	"loading",
	"rigging",
	"power",
	"show",
	"house",
] as const

const FACILITIES_SUBCATEGORIES = ["facility", "informationTech", "parking"] as const

const EQUIPMENT_KEYS = [
	"audio",
	"audioComments",
	"backline",
	"lighting",
	"lightComments",
	"staging",
	"video",
	"videoComments",
	"other",
] as const

const FACILITIES_FACILITY_KEYS = [
	"dressingRooms",
	"facilityNotes",
	"offices",
	"cateringLocale",
	"cateringBreakfast",
	"cateringLunch",
	"cateringDinner",
	"breakfast",
	"lunch",
	"dinner",
	"rehearsalSpace",
	"showers",
	"security",
	"merchandisers",
	"hallMerchPercent",
	"towels",
	"laundry",
	"runners",
] as const

const FACILITIES_INFORMATION_TECH_KEYS = [
	"wifiSsid",
	"username",
	"password",
	"circuit",
	"ip",
	"wanIP",
	"subnetMask",
	"gateway",
	"dns",
	"isp",
	"fees",
	"officeComments",
] as const

const FACILITIES_PARKING_KEYS = [
	"truckParking",
	"busParking",
	"shorePower",
	"guestParking",
	"parkingComments",
] as const

const LOCAL_CREW_KEYS = [
	"localUnion",
	"minimumIN",
	"minimumOUT",
	"penalties",
	"crewComments",
] as const

const LOGISTICS_KEYS = [
	"airportNotes",
	"groundTransport",
	"areaBusiness",
	"areaRestaurants",
	"areaHotels",
	"interest",
	"closestCity",
	"directions",
] as const

const PRODUCTION_HOUSE_KEYS = [
	"fohPosition",
	"snakeRun",
	"barricade",
	"roof",
	"spots",
	"venueSeats",
] as const

const PRODUCTION_LOADING_KEYS = [
	"access",
	"dockType",
	"deadCase",
	"forklifts",
	"loadComments",
] as const

const PRODUCTION_POWER_KEYS = [
	"audioPower",
	"lightingPower",
	"otherPower",
	"showPower",
	"distToStage",
	"powerComments",
] as const

const PRODUCTION_RIGGING_KEYS = ["flySystem", "lineSets", "riggingComments"] as const

const PRODUCTION_SHOW_KEYS = ["houseLights", "climateControl", "fog", "pyro", "co2"] as const

const PRODUCTION_STAGING_KEYS = [
	"dimensions",
	"proscenium",
	"stageWings",
	"paBays",
	"orchestraPit",
	"curtain",
	"fireCurtain",
	"steps",
	"trimHeight",
	"apron",
	"deckToGrid",
	"legs",
	"borders",
	"risers",
	"stage",
] as const

const STAGE_SUBCATEGORY_KEYS = {
	equipmentData: EQUIPMENT_KEYS,

	facility: FACILITIES_FACILITY_KEYS,
	informationTech: FACILITIES_INFORMATION_TECH_KEYS,
	parking: FACILITIES_PARKING_KEYS,

	localCrewData: LOCAL_CREW_KEYS,

	logisticsData: LOGISTICS_KEYS,

	house: PRODUCTION_HOUSE_KEYS,
	loading: PRODUCTION_LOADING_KEYS,
	power: PRODUCTION_POWER_KEYS,
	rigging: PRODUCTION_RIGGING_KEYS,
	show: PRODUCTION_SHOW_KEYS,
	staging: PRODUCTION_STAGING_KEYS,
} as const

const STAGE_CATEGORY_KEYS = {
	equipmentData: ["equipmentData"],
	facilitiesData: ["facility", "informationTech", "parking"],
	localCrewData: ["localCrewData"],
	logisticsData: ["logisticsData"],
	productionData: ["house", "loading", "power", "rigging", "show", "staging"],
} as const

export {
	STAGE_CATEGORIES,
	EQUIPMENT_KEYS,
	FACILITIES_FACILITY_KEYS,
	FACILITIES_INFORMATION_TECH_KEYS,
	FACILITIES_PARKING_KEYS,
	LOCAL_CREW_KEYS,
	LOGISTICS_KEYS,
	PRODUCTION_HOUSE_KEYS,
	PRODUCTION_LOADING_KEYS,
	PRODUCTION_POWER_KEYS,
	PRODUCTION_RIGGING_KEYS,
	PRODUCTION_SHOW_KEYS,
	PRODUCTION_STAGING_KEYS,
	STAGE_SUBCATEGORY_KEYS,
	STAGE_CATEGORY_KEYS,
	PRODUCTION_SUBCATEGORIES,
	FACILITIES_SUBCATEGORIES,
}
