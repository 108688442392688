import { Autocomplete, styled } from "@mui/material"
import FormField from "@atoms/FormField"
import { Chip } from "@atoms/Chip"
import { useTranslation } from "react-i18next"
import { useProductionContext } from "@/context/production"

export const PreviousNames: React.FC<{}> = () => {
	const {
		state: { venue },
		setState,
	} = useProductionContext()
	const { t } = useTranslation()

	const addPreviousName = (name: string) => {
		setState((s: ProductionStateInterface) => ({
			...s,
			venue: {
				...s.venue,
				previousNames: [...(s.venue.previousNames || []), { name }],
			},
		}))
	}

	const removePreviousName = (names: string[]) => {
		setState((s: ProductionStateInterface) => ({
			...s,
			venue: {
				...s.venue,
				previousNames: s.venue.previousNames
					? s.venue.previousNames.filter(p => names.indexOf(p.name) !== -1)
					: [],
			},
		}))
	}

	return (
		<Autocomplete
			multiple={true}
			freeSolo
			fullWidth
			autoSelect
			disableClearable
			limitTags={undefined}
			onChange={(_, updatedPreviousNames, eventType) => {
				if (eventType === "removeOption") {
					removePreviousName(updatedPreviousNames)
				} else {
					addPreviousName(updatedPreviousNames[updatedPreviousNames.length - 1])
				}
			}}
			open={false}
			value={venue.previousNames?.map(p => p.name) || []}
			options={venue.previousNames?.map(p => p.name) || []}
			renderTags={(value: readonly string[], getTagProps) =>
				value.map((option: string, index: number) => (
					<Chip label={option} {...getTagProps({ index })} key={option} />
				))
			}
			renderInput={params => (
				<TaggedFormField
					{...params}
					label={t("previousNames")}
					data-cy="production-PreviousNames-Field"
					multiline
				/>
			)}
		/>
	)
}

const TaggedFormField = styled(FormField)`
	& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
		position: static;
		align-items: start;
		padding: 8px 0px 8px 16px;
		gap: 10px;
		max-height: 92px;
		height: 92px;
		overflow-y: scroll;
		scrollbar-width: thin;
	}
`
