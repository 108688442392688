import { TableCell as MuiTableCell, styled } from "@mui/material"

declare module "@mui/material/TableCell" {
	interface TableCellPropsVariantOverrides {
		iconOnly: boolean
		smallText: boolean
	}
}

export const TableCell = styled(MuiTableCell)`
	&.MuiTableCell-root {
		color: ${({ theme }) => theme.colorPalette.surface.on};
		letter-spacing: 0;
		text-align: left;

		padding: 10px 12px;
		border-width: 1px 0px 0px 0px;
	}

	&.MuiTableCell-head {
		user-select: none;
		background-color: ${({ theme }) => theme.colorPalette.surface.dim};
		border-width: 1px 0px 0px 1px;
		&,
		.MuiButtonBase-root.MuiTableSortLabel-root {
			font: 500 14px/20px Signika-Medium;
			color: ${({ theme }) => theme.colorPalette.surface.on};

			& .MuiSvgIcon-root.MuiTableSortLabel-icon {
				color: ${({ theme }) => theme.colorPalette.surface.on};
			}
		}
	}

	&.MuiTableCell-body {
		font: 400 14px/20px Signika-Regular;
	}

	&.MuiTableCell-smallText {
		font: 400 12px/16px Signika-Regular;
	}

	&.MuiTableCell-iconOnly {
		padding-left: 28px;
	}
`
