import {
	DarkModeOutlined as MUIDarkModeOutlined,
	DarkMode as MUIDarkmode,
} from "@mui/icons-material"

function DarkMode({ color = "black", size = 18, fill = false }: UpdatedIconTypes) {
	const height = size
	const width = size

	return fill ? (
		<MUIDarkmode height={height} width={width} htmlColor={color} />
	) : (
		<MUIDarkModeOutlined height={height} width={width} htmlColor={color} />
	)
}

export default DarkMode
