import NewModal from "@/components/molecules/NewModal"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { Add, AttachFile, Check } from "@mui/icons-material"
import { SelectChangeEvent, Stack, styled, useTheme } from "@mui/material"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { css } from "styled-components"
import { Select } from "@/components/molecules/Select"
import { AttachmentList } from "@/components/molecules/AttachmentList"
import { AttachmentDetails } from "./AttachmentDetails"
import { DeleteAttachmentModal } from "./DeleteAttachmentModal"
import { getSection } from "@/util/fieldUtils"
import LoaderOverlay from "../LoaderOverlay"

interface AddEditAttachmentModalProps {
	modalType: "add" | "edit"
}

export const AddEditAttachmentModal = ({ modalType }: AddEditAttachmentModalProps) => {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()

	const {
		state: {
			addAttachmentModalOpen,
			editAttachmentModalOpen,
			category,
			translatedSectionFields,
			addEditModalState: { selectedAttachmentId, previewData, selectedField, fieldError },
		},
		setState,
		canSubmitAttachments,
		submitAddAttachments,
		submitEditAttachments,
		fileAttachments,
		requestPending,
	} = useProductionAttachments()

	const handleCloseModal = useCallback(() => {
		if (modalType === "add") {
			setState(prev => ({
				...prev,
				addAttachmentModalOpen: false,
			}))
		} else {
			setState(prev => ({
				...prev,
				editAttachmentModalOpen: false,
			}))
		}
	}, [modalType, setState])

	const handleSelectField = useCallback(
		(e: SelectChangeEvent<string>) => {
			const foundKey = translatedSectionFields.find(val => val.translatedValue === e.target.value)

			if (foundKey) {
				setState(prev => ({
					...prev,
					addEditModalState: {
						...prev.addEditModalState,
						selectedField: foundKey.translationKey,
						fieldError: false,
					},
				}))
			}
		},
		[translatedSectionFields, setState],
	)

	const selectOptions = useMemo(() => {
		if (modalType === "add") {
			if (category === "productionData") {
				return translatedSectionFields.filter(
					val => val.translationKey !== "dimensions" && val.translationKey !== "proscenium",
				)
			}
			return translatedSectionFields
		}

		if (fileAttachments === undefined) {
			return []
		}

		const sectionFields = fileAttachments.reduce<string[]>((prev, curr) => {
			const splitField = curr.category.split(".")

			if (splitField[1] !== category) {
				return prev
			}

			const newField = splitField.pop()

			if (
				newField === undefined ||
				prev.includes(newField) ||
				newField === "proscenium" ||
				newField === "dimensions"
			) {
				return prev
			}

			return [...prev, newField]
		}, [])

		return translatedSectionFields.filter(val => sectionFields.includes(val.translationKey))
	}, [fileAttachments, translatedSectionFields, category, modalType])

	return (
		<>
			<NewModal
				open={modalType === "add" ? addAttachmentModalOpen : editAttachmentModalOpen}
				closeModal={handleCloseModal}
				size={selectedAttachmentId ? "2xl" : "md"}
				title={modalType === "add" ? t("addAttachment") : t("editAttachment")}
				titleIcon={<AttachFile />}
				subHeader={
					selectedAttachmentId ? undefined : modalType === "add" ? (
						<span>
							{t("uploadFilesToSection", {
								section_name: t(getSection(selectedField)),
							})}
						</span>
					) : (
						<span>
							{t("editFilesInSection", {
								section_name: t(getSection(selectedField)),
							})}
						</span>
					)
				}
				hint={
					selectedAttachmentId
						? undefined
						: modalType === "add"
						? previewData.length === 0
							? undefined
							: t("addAttachmentModalBlurb")
						: t("editAttachmentModalBlurb")
				}
				footerActions={{
					end: [
						"Cancel",
						{
							buttonType: "Outline",
							text: modalType === "add" ? t("upload") : t("update"),
							iconElement:
								modalType === "add" ? (
									<Add
										htmlColor={colorPalette.primary.value}
										sx={{
											height: "18px",
											width: "18px",
										}}
									/>
								) : (
									<Check
										htmlColor={colorPalette.primary.value}
										sx={{
											height: "18px",
											width: "18px",
										}}
									/>
								),
							stateLayerStyles: css`
								width: 116px;
								padding: 10px 16px 10px 12px;
							`,
							disabled: !canSubmitAttachments || requestPending,
							onClick: () =>
								modalType === "add" ? submitAddAttachments() : submitEditAttachments(),
						},
					],
				}}
			>
				<Stack
					direction="row"
					gap="16px"
					padding="8px 11px 0px 11px"
					justifyContent="center"
					alignItems="start"
				>
					<LeftStack gap="10px" alignItems="center" flexGrow={2} alignSelf="stretch">
						{!selectedAttachmentId ? (
							<></>
						) : modalType === "add" ? (
							<ExpandedHeaderContainer>
								<p>
									{t("uploadFilesToSection", {
										section_name: t(getSection(selectedField)),
									})}
								</p>
								<small>{t("addAttachmentModalBlurb")}</small>
							</ExpandedHeaderContainer>
						) : (
							<ExpandedHeaderContainer>
								<p>
									{t("editFilesInSection", {
										section_name: t(getSection(selectedField)),
									})}
								</p>
								<small>{t("editAttachmentModalBlurb")}</small>
							</ExpandedHeaderContainer>
						)}

						{!selectedAttachmentId &&
							((previewData.length > 0 && modalType === "add") || modalType === "edit") && (
								<Select
									onChange={handleSelectField}
									value={t(selectedField)}
									simple
									optionValues={selectOptions.map(val => val.translatedValue)}
									label={t("selectField")}
									placeholder={t("selectField")}
									fullWidth
									MenuProps={{
										sx: {
											"& .MuiPaper-root": {
												maxHeight: "267px",
											},
										},
									}}
									error={modalType === "add" && fieldError}
									disabled={modalType === "edit" && canSubmitAttachments}
								/>
							)}
						<AttachmentList listType={modalType} />
					</LeftStack>
					{selectedAttachmentId && <AttachmentDetails detailType={modalType} />}
				</Stack>
			</NewModal>
			{modalType === "edit" && <DeleteAttachmentModal />}
			<LoaderOverlay isLoading={requestPending} />
		</>
	)
}

const LeftStack = styled(Stack)`
	width: 496px;
	height: inherit !important;
	gap: 16px;
`

const ExpandedHeaderContainer = styled("div")`
	padding: 0px 16px;
	gap: 16px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;

	p {
		font: 400 14px/20px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		text-align: center;
	}

	small {
		font: 400 12px/16px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		text-align: left;

		&::before {
			content: "*";
		}
	}
`
