import useAxios from "@/hooks/useAxios"
import { useVenuesContext } from "./venues"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import React, { createContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

export interface HistoryState {
	filterFieldName: string | null
	filterResultsPerPage: number
	filterUserId: string | null
	page: number
	modalOpen: boolean
	selectedDatalogId?: string
}

export interface HistoryContextType {
	state: HistoryState
	setState: React.Dispatch<React.SetStateAction<HistoryState>>
	objectChangelog?: HistoryObjectChangelogResponse
	dataPending: boolean
	selectedDatalog?: HistoryDataChangeLog
}

const INITIAL_STATE: HistoryState = {
	filterFieldName: null,
	filterResultsPerPage: 10,
	filterUserId: null,
	page: 1,
	modalOpen: false,
	selectedDatalogId: undefined,
}

export const HistoryContext = createContext<HistoryContextType>({
	state: INITIAL_STATE,
	setState: () => null,
	dataPending: true,
	objectChangelog: undefined,
})

export default function HistoryContextProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const [state, setState] = useState<HistoryState>(INITIAL_STATE)
	const { selectedVenue } = useVenuesContext()

	const { t } = useTranslation()
	const axios = useAxios()

	const { data, isPending } = useQuery({
		queryKey: [
			"data-logs",
			{
				filterFieldName: state.filterFieldName,
				filterResultsPerPage: state.filterResultsPerPage,
				filterUserId: state.filterUserId,
				page: state.page,
				venueId: selectedVenue?.id,
			},
		],
		queryFn: async () => {
			const uploadPackage: Record<string, string | null | number> = {
				filterFieldName: state.filterFieldName === "" ? null : state.filterFieldName,
				filterResultsPerPage: state.filterResultsPerPage,
				filterUserId: state.filterUserId === "" ? null : state.filterUserId,
				page: state.page,
			}

			if (uploadPackage.filterFieldName === null) {
				delete uploadPackage.filterFieldName
			}

			if (uploadPackage.filterUserId === null) {
				delete uploadPackage.filterUserId
			}

			return axios
				.post<{ data: HistoryObjectChangelogResponse }>(
					`/venues/v1/data-change-logs/Venue/${selectedVenue?.id || ""}`,
					uploadPackage,
				)
				.then(res => res.data.data)
		},
		placeholderData: keepPreviousData,
		meta: {
			errorMessage: t("errorTryAgain"),
		},
		retry: false,
	})

	const selectedDatalog = useMemo(() => {
		if (data === undefined) return undefined
		return data.dataChangeLogs.find(val => val.id === state.selectedDatalogId)
	}, [state.selectedDatalogId, data])

	return (
		<HistoryContext.Provider
			value={{
				state,
				setState,
				dataPending: isPending,
				objectChangelog: data,
				selectedDatalog,
			}}
		>
			{children}
		</HistoryContext.Provider>
	)
}
