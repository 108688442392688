import FormField from "@/components/atoms/FormField"
import { LogoImage } from "@/components/molecules/LogoImage"
import { PreviousNames } from "@/components/molecules/production/PreviousNames"
import countries from "@/util/constants/COUNTRIES"
import { Select } from "@/components/molecules/Select"
import { Divider, Stack, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { GeneralVenueContacts } from "./GeneralVenueContacts"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useVenuesContext } from "@/context/venues"
import DEFAULT_VENUE from "@/util/constants/defaults/DEFAULT_VENUE"
import { useProductionContext } from "@/context/production"
import useProductionAttachments from "@/hooks/useProductionAttachments"

export const GeneralVenueInformation: React.FC<{}> = () => {
	const { t } = useTranslation()
	const { selectedVenue } = useVenuesContext()
	const { setState: setProductionState, state: productionState } = useProductionContext()
	const { venueLogo, handleLogoUpload } = useProductionAttachments()

	const [venueState, setVenueState] = useState(selectedVenue ?? DEFAULT_VENUE)
	const [venueWebsite, setVenueWebsite] = useState(
		selectedVenue?.urls.find(val => val.label === "primary")?.url ?? "",
	)

	const [country, setCountry] = useState<string>(
		selectedVenue == null || selectedVenue.country === ""
			? ""
			: t(`country${selectedVenue.country}`, { ns: "countries" }),
	)
	const [publicNotes, setPublicNotes] = useState(
		selectedVenue?.notes?.find(val => val.label === "public")?.notes ?? "",
	)

	const countryCodes = useMemo(
		() => countries.map(country => t(`country${country}`, { ns: "countries" })),
		[t],
	)

	const updateVenueState = useCallback(
		(fieldKey: keyof Venue | "publicNotes", newValue: string) => {
			switch (fieldKey) {
				case "publicNotes":
					setPublicNotes(newValue)
					break
				case "website":
					setVenueWebsite(newValue)
					break
				default:
					setVenueState(prev => ({
						...prev,
						[fieldKey]: newValue,
					}))
			}
		},
		[setVenueState],
	)

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setProductionState(prev => {
				const newNotes = prev.venue.notes.filter(val => val.label !== "public")
				const newUrls = prev.venue.urls.filter(val => val.label !== "primary")

				return {
					...prev,
					venue: {
						...prev.venue,
						name: venueState.name,
						addressLine1: venueState.addressLine1,
						addressLine2: venueState.addressLine2,
						city: venueState.city,
						zip: venueState.zip,
						latitude: venueState.latitude,
						longitude: venueState.longitude,
						ageRequirement: venueState.ageRequirement,
						notes: [
							...newNotes,
							{
								label: "public",
								notes: publicNotes,
							},
						],
						urls: [
							...newUrls,
							{
								label: "primary",
								url: venueWebsite,
							},
						],
						country:
							countries.find(val => t(`country${val}`, { ns: "countries" }) === country) ?? "",
						state: venueState.state,
					},
				}
			})
		}, 500)

		return () => clearTimeout(delayDebounceFn)
	}, [venueState, venueWebsite, country, publicNotes, setProductionState, t])

	useEffect(() => {
		if (selectedVenue !== null && selectedVenue.id !== venueState.id) {
			setVenueState(selectedVenue)
			setPublicNotes(selectedVenue?.notes?.find(val => val.label === "public")?.notes ?? "")
			setCountry(
				selectedVenue.country === ""
					? ""
					: t(`country${selectedVenue.country}`, { ns: "countries" }),
			)
		}
	}, [setVenueState, selectedVenue, venueState, setPublicNotes, t])

	useEffect(() => {
		setVenueState(prev => ({
			...prev,
			latitude: productionState.venue.latitude,
			longitude: productionState.venue.longitude,
		}))
	}, [setVenueState, productionState.venue.latitude, productionState.venue.longitude])

	return (
		<RootContainer>
			<SplitRowContainer>
				<LogoImage
					aspectRatio={[4, 3]}
					idealSize={[1024, 768]}
					captionText={t("defaultVenueImageLogo")}
					imageWidth={336}
					handleInputChange={handleLogoUpload}
					previewUrl={venueLogo?.file.publicUrls.urlPreview}
					alt={venueLogo?.file.filename}
				/>
				<TextContainer paddingTop="24px">
					<FormField
						label={t("venueName")}
						value={venueState.name ?? ""}
						onChange={e => updateVenueState("name", e.target.value)}
						fullWidth
						required
					/>
					<FormField
						label={t("venueWebsite")}
						value={venueWebsite}
						onChange={e => updateVenueState("website", e.target.value)}
						fullWidth
					/>
					<PreviousNames />
				</TextContainer>
			</SplitRowContainer>

			<SplitRowContainer>
				<TextContainer>
					<FormField
						label={t("addressLine1")}
						value={venueState.addressLine1 ?? ""}
						onChange={e => updateVenueState("addressLine1", e.target.value)}
						fullWidth
					/>
					<FormField
						label={t("addressLine2")}
						value={venueState.addressLine2 ?? ""}
						onChange={e => updateVenueState("addressLine2", e.target.value)}
						fullWidth
					/>
					<FormField
						label={t("city")}
						value={venueState.city ?? ""}
						onChange={e => updateVenueState("city", e.target.value)}
						fullWidth
					/>
					<DoubleInputContainer>
						<FormField
							label={t("stateProvince")}
							value={venueState.state ?? ""}
							onChange={e => updateVenueState("state", e.target.value)}
							fullWidth
						/>

						<FormField
							label={t("zipPostalCode")}
							value={venueState.zip ?? ""}
							onChange={e => updateVenueState("zip", e.target.value)}
							fullWidth
						/>
					</DoubleInputContainer>
					<Select
						label={t("country")}
						value={country}
						onChange={e => setCountry(e.target.value)}
						optionValues={countryCodes}
						fullWidth
						sx={{
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "4px",
							},
						}}
						MenuProps={{
							sx: {
								"& .MuiPaper-root": {
									maxHeight: "200px",
								},
							},
						}}
					/>
					<DoubleInputContainer>
						<FormField
							label={t("latitude")}
							onChange={e => updateVenueState("latitude", e.target.value)}
							value={venueState.latitude ?? ""}
							fullWidth
						/>
						<FormField
							label={t("longitude")}
							onChange={e => updateVenueState("longitude", e.target.value)}
							value={venueState.longitude ?? ""}
							fullWidth
						/>
					</DoubleInputContainer>
				</TextContainer>
				<StyledDivider orientation="vertical" />
				<TextContainer>
					<FormField
						label={t("ageRestriction_plural")}
						value={venueState.ageRequirement ?? ""}
						onChange={e => updateVenueState("ageRequirement", e.target.value)}
						fullWidth
					/>
					<StyledTextArea
						label={t("publicNotes")}
						value={publicNotes}
						onChange={e => updateVenueState("publicNotes", e.target.value)}
						fullWidth
						multiline
						sx={{
							height: "100%",
						}}
						maxRows={14}
					/>
				</TextContainer>
			</SplitRowContainer>

			<StyledDivider />
			<GeneralVenueContacts />
		</RootContainer>
	)
}

const StyledTextArea = styled(FormField)`
	& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
		overflow-y: scroll;
		scrollbar-width: thin;
		position: static;
		align-items: start;
		gap: 10px;
		max-height: 100%;
		height: 100%;
		overflow-y: scroll;
		scrollbar-width: thin;
	}
`

const RootContainer = styled(Stack)`
	& {
		width: 100%;
		gap: 48px;
		padding-top: 24px;
	}
`

const SplitRowContainer = styled(Stack)`
	& {
		min-width: 1056px;
		width: 100%;
		flex-direction: row;
		gap: 48px;
	}
`

const TextContainer = styled(Stack)`
	& {
		width: 100%;
		gap: 24px;
	}
`

const DoubleInputContainer = styled(Stack)`
	& {
		flex-direction: row;
		gap: 24px;
	}
`

const StyledDivider = styled(Divider)`
	border-color: ${({ theme }) => theme.colorPalette.outline.variant};
`
