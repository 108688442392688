import { getFileIconName, getPreviewUrl } from "@util/attachmentUtils"
import styled, { useTheme } from "styled-components"
import VideoPlayer from "@atoms/VideoPlayer"
import { FileImg } from "@/components/atoms/FileImg"
import { OpenInNew } from "@mui/icons-material"
import mediaQuery from "@/util/mediaQuery"
import { useTranslation } from "react-i18next"

const ShowPreview = ({ attachment }: { attachment: FileAttachment }) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const { file } = attachment
	const fileIsReady = file?.status?.streaming === "Complete"
	const videoUrl = file?.publicUrls?.streaming?.url
	const fileType = getFileIconName(file.filename)
	const previewUrl = getPreviewUrl(file)

	return fileType === "video" && videoUrl && fileIsReady ? (
		<VideoPlayer file={file} />
	) : fileType === "img" && previewUrl !== undefined ? (
		<ImageWrapper>
			<FileImg
				filename={file.filename}
				previewUrl={previewUrl}
				height="100%"
				width="100%"
				style={{
					objectFit: "contain",
					borderRadius: "8px",
					border: `2px solid ${theme.colorPalette.secondary.value}`,
					boxShadow: `0px 0px 10px 0px rgba(0, 0, 0, 0.25)`,
					color: theme.colorPalette.primary.value,
					width: "fit-content",
					height: "auto",
					maxHeight: "100%",
					maxWidth: "100%",
				}}
			/>
			<ExpandContainer
				onClick={() => window.open(previewUrl, "_blank", `rel="noopener noreferrer"`)}
			>
				<OpenInNew /> <p>{t("openInNewTab")}</p>
			</ExpandContainer>
		</ImageWrapper>
	) : (
		<PlaceholderImage fileName={file.filename} />
	)
}

const ExpandContainer = styled("div")`
	display: flex;
	justify-content: start;
	align-items: center;

	cursor: pointer;
	user-select: none;

	gap: 5px;
	opacity: 1;

	&:hover {
		opacity: 0.75;
	}

	&:active {
		opacity: 0.9;
	}

	svg {
		font-family: Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}

	p {
		text-decoration: none;
		font-family: Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font-size: 14px;
		line-height: normal;
	}

	${mediaQuery("md")`
		p {
			font-size: 12px;
			b {
				font-size: 12px;
			}
		}
	`}
`

const ImageWrapper = styled("div")`
	position: relative;
	flex-grow: 1;
	max-height: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 5px;
	padding-bottom: 5px;

	& > div {
		max-height: 100%;
		max-width: 100%;
		div {
			background: none;
			max-height: 100%;
			max-width: 100%;
		}
	}
`

const PlaceholderImage = ({ fileName }: { fileName: string }) => {
	return (
		<PlaceholderContainer>
			<FileImg filename={fileName} />
			<p>No Preview Available</p>
		</PlaceholderContainer>
	)
}

const PlaceholderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 32px;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	width: 100%;
	border-radius: 8px;
	border: 2px solid ${({ theme }) => theme.colorPalette.secondary.value};
	background-color: ${({ theme }) => theme.colorPalette.primary.inverse};

	& > div > div {
		background: none;
		color: ${({ theme }) => theme.colorPalette.primary.value};
		width: 132px;
		height: 132px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	& > p {
		color: black;
		font: 700 22px/25.78px Roboto-Bold;
	}
`

export default ShowPreview
