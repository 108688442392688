import getInitials from "../../../util/getInitials"
import { forwardRef, useEffect, useRef, useState } from "react"
import styled, { css, useTheme } from "styled-components"
import { useOption } from "@mui/base/useOption"
import { Add, ArrowDropDown, ArrowRight, Star } from "@mui/icons-material"
import {
	useSelect,
	UseSelectButtonSlotProps,
	SelectProvider,
	UseSelectParameters,
} from "@mui/base/useSelect"

function NavSelect<
	TValue extends {
		id: string
		name: string
		isMainStage?: boolean
		noOptionsLabel?: string
		addButton?: {
			label: string
			onClick: () => void
		}
		zIndex?: number
	},
>({
	selectValue,
	onChange,
	optionValues,
	componentName,
	noOptionsLabel,
	addButton,
	zIndex,
}: {
	componentName?: string
	selectValue: TValue
	onChange: UseSelectParameters<TValue, false>["onChange"]
	optionValues: TValue[]
	noOptionsLabel?: string
	addButton?: {
		label: string
		onClick: () => void
	}
	zIndex?: number
}) {
	const listboxRef = useRef<HTMLUListElement>(null)
	const rootRef = useRef<HTMLDivElement>(null)
	const [listboxVisible, setListboxVisible] = useState(false)

	const { getButtonProps, getListboxProps, contextValue, value } = useSelect<TValue>({
		listboxRef,
		onOpenChange: setListboxVisible,
		open: listboxVisible,
		value: selectValue,
		onChange: onChange,
	})

	useEffect(() => {
		// Handles closing on click when outside select
		function handleClickOutside(e: MouseEvent) {
			if (rootRef.current && e.target && !rootRef.current.contains(e.target as Element)) {
				setListboxVisible(false)
			}
		}

		if (listboxVisible) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [listboxVisible, setListboxVisible])

	return (
		<RootContainer ref={rootRef}>
			<Popup visible={listboxVisible} zIndexValue={zIndex}>
				<Listbox {...getListboxProps()}>
					<SelectProvider value={contextValue}>
						{optionValues.length === 1 && noOptionsLabel !== undefined ? (
							<NoOptionsContainer>
								<NoOptionsAvatar />
								<GrayText>{noOptionsLabel}</GrayText>
							</NoOptionsContainer>
						) : (
							optionValues
								.filter(option => option.id !== selectValue.id)
								.map((option, key) => {
									return <Option key={`${option.name}-${key}`} value={option} />
								})
						)}
					</SelectProvider>
					{addButton !== undefined && (
						<AddOptionContainer onClick={addButton.onClick}>
							<GrayText>
								<Add fontSize="inherit" />
								{addButton.label[0].toUpperCase() + addButton.label.slice(1).toLowerCase()}
							</GrayText>
						</AddOptionContainer>
					)}
				</Listbox>
			</Popup>
			<Button
				zIndex={zIndex}
				isMainStage={selectValue.isMainStage}
				componentName={componentName}
				{...getButtonProps()}
				label={value?.name || "N A"}
				listboxVisible={listboxVisible}
			/>
		</RootContainer>
	)
}

const Button = forwardRef(function SelectButton(
	props: {
		label: string
		componentName?: string
		isMainStage?: boolean
		listboxVisible: boolean
		zIndex?: number
	} & UseSelectButtonSlotProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	const { label, componentName, isMainStage, listboxVisible, zIndex, ...rest } = props

	const { colorPalette } = useTheme()
	const [pressed, setPressed] = useState(false)
	const [iconClassNames, setIconClassNames] = useState<string>("right")

	function handleMouseOut(e: any) {
		if (e.buttons !== 0) {
			document.addEventListener(
				"mouseup",
				() => {
					setPressed(false)
				},
				{ once: true },
			)
		}
	}

	useEffect(() => {
		if (listboxVisible) {
			if (iconClassNames !== "down") {
				setIconClassNames("down")
			}
		} else {
			if (pressed) {
				if (iconClassNames !== "down") {
					setIconClassNames("down")
				}
			} else if (iconClassNames !== "right") {
				setIconClassNames("right")
			}
		}
	}, [iconClassNames, listboxVisible, pressed, setIconClassNames])

	return (
		<ButtonRoot
			name={componentName}
			{...rest}
			onMouseDown={() => setPressed(true)}
			onMouseUp={() => setPressed(false)}
			onMouseOut={handleMouseOut}
			isPressed={listboxVisible}
			ref={ref}
			zIndexValue={zIndex}
		>
			<ButtonAvatar>{getInitials(label)}</ButtonAvatar>
			<BaseText>
				{isMainStage && (
					<Star
						fontSize="inherit"
						sx={{
							marginBottom: "-2px",
						}}
					/>
				)}
				{" " + label}
			</BaseText>
			<IconContainer className={`iconContainer ${iconClassNames}`}>
				<ArrowDropDown
					htmlColor={
						pressed && !rest["aria-expanded"]
							? colorPalette.secondary.container.on
							: colorPalette.primary.value
					}
					sx={{
						opacity: 0.85,
					}}
					className="downArrow"
				/>
				<ArrowRight
					htmlColor={
						rest["aria-expanded"] ? colorPalette.primary.value : colorPalette.secondary.container.on
					}
					sx={{
						opacity: rest["aria-expanded"] ? 0.85 : 1,
					}}
					className="rightArrow"
				/>
			</IconContainer>
		</ButtonRoot>
	)
})

function Option<
	TValue extends {
		id: string
		name: string
		isMainStage?: boolean
	},
>({ value, disabled }: { value: TValue; disabled?: boolean }) {
	const { getRootProps } = useOption<TValue>({
		value: value,
		disabled: disabled || false,
		label: value.name,
	})

	return (
		<OptionContainer {...getRootProps()} value={value.id}>
			<OptionAvatar>{getInitials(value.name || "")}</OptionAvatar>
			<BaseText>
				{value.isMainStage && (
					<Star
						fontSize="inherit"
						sx={{
							marginBottom: "-2px",
						}}
					/>
				)}
				{" " + value.name}
			</BaseText>
		</OptionContainer>
	)
}

/** Base Classes */

const BaseRoundedContainer = css<{ zIndexValue?: number }>`
	position: relative;
	z-index: ${({ zIndexValue }) => (zIndexValue ? zIndexValue : 100)};

	display: flex;
	align-items: center;
	justify-content: start;

	width: 264px;
	height: 56px;
	padding: 16px;
	gap: 12px;
	border: 0;
	border-radius: 100px;

	cursor: pointer;
`

const BaseText = styled.p`
	display: -webkit-box;

	max-height: 34.55px;
	width: 152px;
	gap: 4px;
	vertical-align: middle;

	font-family: Signika-Semibold;
	font-size: 14px;
	text-transform: capitalize;
	text-align: left;
	line-height: normal;

	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;

	color: ${({ theme }) => theme.colorPalette.secondary.container.on};

	transition: color 175ms ease-out;
`

/* Button Classes */
const ButtonRoot = styled.button<{ isPressed: boolean; zIndexValue?: number }>`
	${BaseRoundedContainer}

	background-color: ${({ theme }) => theme.colorPalette.secondary.container.value};

	&:hover {
		box-shadow: ${({ isPressed }) =>
			isPressed ? undefined : "0px 4px 8px 3px #00000026, 0px 1px 3px 0px #0000004D"};
	}

	&:active {
		box-shadow: none;
		opacity: 0.85;
	}

	.right {
		.rightArrow {
			display: block;
			visibility: visible;
			transition: visibility 150ms ease-out;
		}

		.downArrow {
			display: none;
			visibility: hidden;
			transition: visibility 150ms ease-out;
		}
	}

	.down {
		.rightArrow {
			display: none;
			visibility: hidden;
			transition: visibility 150ms ease-out;
		}

		.downArrow {
			display: block;
			visibility: visible;
			transition: visibility 150ms ease-out;
		}
	}

	transition: box-shadow 200ms ease-out, opacity 200ms ease-out;
`

const IconContainer = styled.div`
	margin: auto;
	margin-right: 0;

	.right {
		.rightArrow {
			visibility: visible;
			transition: visibility 150ms ease-out;
		}

		.downArrow {
			visibility: hidden;
			transition: visibility 150ms ease-out;
		}
	}

	.down {
		.rightArrow {
			display: hidden;
			transition: visibility 150ms ease-out;
		}

		.downArrow {
			display: visible;
			transition: visibility 150ms ease-out;
		}
	}
`

/* Option Classes */
const OptionContainer = styled.li<{ zIndexValue?: number }>`
	${BaseRoundedContainer}
	background-color: ${({ theme }) => theme.colorPalette.surface.container.value};

	&:hover {
		background-color: ${({ theme }) => theme.colorPalette.surface.variant};
	}

	&:active {
		background-color: ${({ theme }) => theme.colorPalette.surface.dim};
	}

	transition: background-color 175ms ease-out;
`

const NoOptionsContainer = styled.li<{ zIndexValue?: number }>`
	${BaseRoundedContainer}
	background-color: ${({ theme }) => theme.colorPalette.surface.container.value};
	cursor: default;
`

const GrayText = styled(BaseText)`
	color: ${({ theme }) => theme.colorPalette.outline.value};
	display: flex;
	align-items: center;
	text-transform: none;
`

const AddOptionContainer = styled(OptionContainer)`
	width: 264px;
	height: 40px;
	gap: 12px;
	border-radius: 100px;
	margin-bottom: 8px;
`

/* Avatar Classes */
const BaseAvatar = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	height: 32px;
	width: 32px;
	margin: 0;
	border-radius: 100%;

	font: 12px Signika-Semibold;
`

const OptionAvatar = styled(BaseAvatar)`
	background-color: ${({ theme }) => theme.colorPalette.primary.container.on};
	color: ${({ theme }) => theme.colorPalette.primary.fixed.dim};
`

const NoOptionsAvatar = styled.div`
	background-color: ${({ theme }) => theme.colorPalette.surface.dim};
	height: 32px;
	width: 32px;
	margin: 0;
	border-radius: 100%;
	display: block;
`

const ButtonAvatar = styled(BaseAvatar)`
	background-color: ${({ theme }) => theme.colorPalette.primary.value};
	color: ${({ theme }) => theme.colorPalette.primary.on};
`

/* High Level Container Classes */

const RootContainer = styled.div`
	position: relative;
	width: 264px;
	height: 56px;
	user-select: none;
`

const Popup = styled.div<{ visible: boolean; zIndexValue?: number }>`
	position: absolute;
	top: 0;
	left: 0;
	z-index: ${({ zIndexValue }) => (zIndexValue ? zIndexValue : 100)};

	width: 264px;

	visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
	opacity: ${({ visible }) => (visible ? "1" : "0")};

	transition: visibility 150ms, opacity 150ms ease-out;
`

const Listbox = styled.ul`
	margin-top: 0;
	padding-top: 56px !important;
	border-radius: 30px;

	list-style: none;
	background-color: ${({ theme }) => theme.colorPalette.surface.container.value};
	box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
	overflow: hidden;
`

export default NavSelect
