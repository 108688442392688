import React from "react"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import App from "./RouterRoot"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { library } from "@fortawesome/fontawesome-svg-core"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
	faAnglesRight,
	faArrowLeft,
	faCartFlatbedSuitcase,
	faChevronRight,
	faChevronLeft,
	faChevronUp,
	faClipboardList,
	faCloudArrowDown,
	faHouse,
	faUser,
	faPeopleCarryBox,
	faPhotoFilm,
	faStopwatch,
	faTriangleExclamation,
	faWarning,
	faTrashCan,
	faXmark,
	faInfoCircle,
	faPencil,
	faSquarePlus,
	faLink,
	faSignOut,
	faHistory,
} from "@fortawesome/free-solid-svg-icons"
import AxiosProvider from "./context/axios"
import TokenProvider from "./context/token"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import semverUtils from "semver-utils"
import DarkModeProvider from "./context/darkmode"
import { OnlineStatusProvider } from "./context/onlineStatus"
import CssBaseline from "@mui/material/CssBaseline"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import isPropValid from "@emotion/is-prop-valid"
import { StyleSheetManager } from "styled-components"
import { OopsOverlay } from "./components/views/OopsOverlay"

declare module "@tanstack/react-query" {
	interface Register {
		queryMeta: {
			errorMessage?: string
		}
		mutationMeta: Record<string, unknown>
	}
}

const firebaseConfig = {
	apiKey: "AIzaSyCxJGiyKG22_23-6zSb-KazpFBXv6o-yVk",
	authDomain: "mastertourvenue.firebaseapp.com",
	projectId: "mastertourvenue",
	storageBucket: "mastertourvenue.appspot.com",
	messagingSenderId: "369835293673",
	appId: "1:369835293673:web:6abc3f8ccf131fd5ca2978",
	measurementId: "G-J148792J0V",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app)

const VERSION = require("../package.json").version
const semver = semverUtils.parse(VERSION)

Bugsnag.start({
	apiKey: "e44f26ef0e9c85493674d8cd966d39c5",
	appVersion: VERSION,
	plugins: [new BugsnagPluginReact()],
	releaseStage: (semver.release && semver.release.split(".")[0]) || "production",
})

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// Exponential backoff of retry attempts
			retryDelay: attemptIndex => Math.min(3000 * 2 ** attemptIndex, 30000),
			staleTime: 45000,
		},
	},
	queryCache: new QueryCache({
		onError: (error, query) => {
			if (query.meta?.errorMessage) {
				toast.error(query.meta.errorMessage)
			}
		},
	}),
})

library.add(
	faAnglesRight,
	faArrowLeft,
	faCartFlatbedSuitcase,
	faChevronRight,
	faChevronLeft,
	faChevronUp,
	faClipboardList,
	faCloudArrowDown,
	faHouse,
	faUser,
	faPeopleCarryBox,
	faPhotoFilm,
	faStopwatch,
	faTriangleExclamation,
	faWarning,
	faTrashCan,
	faXmark,
	faInfoCircle,
	faPencil,
	faSquarePlus,
	faLink,
	faSignOut,
	faHistory,
)

function shouldForwardProp(propName: string, target: any) {
	if (typeof target === "string") {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName)
	}
	// For other elements, forward all props
	return true
}

const Root = () => {
	const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)

	return (
		<>
			<CssBaseline />
			<I18nextProvider i18n={i18n}>
				<ErrorBoundary FallbackComponent={OopsOverlay}>
					<AxiosProvider>
						<QueryClientProvider client={queryClient}>
							<TokenProvider>
								<ReactQueryDevtools initialIsOpen={false} position="bottom" />
								<ToastContainer
									theme="dark"
									position="top-center"
									closeOnClick
									stacked
									hideProgressBar
								/>
								<DarkModeProvider>
									<OnlineStatusProvider>
										<StyleSheetManager shouldForwardProp={shouldForwardProp}>
											<App />
										</StyleSheetManager>
									</OnlineStatusProvider>
								</DarkModeProvider>
							</TokenProvider>
						</QueryClientProvider>
					</AxiosProvider>
				</ErrorBoundary>
			</I18nextProvider>
		</>
	)
}

export default Root
