import useTechPack from "./useTechPack"
import {
	PRODUCTION_STAGING_KEYS,
	PRODUCTION_LOADING_KEYS,
	PRODUCTION_RIGGING_KEYS,
	PRODUCTION_POWER_KEYS,
	PRODUCTION_SHOW_KEYS,
	PRODUCTION_HOUSE_KEYS,
	FACILITIES_FACILITY_KEYS,
	FACILITIES_PARKING_KEYS,
	FACILITIES_INFORMATION_TECH_KEYS,
	EQUIPMENT_KEYS,
	LOGISTICS_KEYS,
	LOCAL_CREW_KEYS,
} from "../util/constants/STAGE_SUBCATEGORY_KEYS"

export default function useTechPackNavigation() {
	const { stage, personnel, showSection, attachments } = useTechPack()

	const getKeysThatExistInVenueData = (dataKey: string, keys: readonly string[]) => {
		// return a list of keys that exist in the venue data

		// attachments return as subcategory (ie steps, legs, distToStage, etc.)
		// this should be revisted when the production section attachments are updated
		const stageData = stage[dataKey as keyof Stage]
		if (stageData) {
			return keys.reduce((arr, key) => {
				const keyData = stageData[key as keyof typeof stageData]
				if (typeof keyData !== "string" && keyData) {
					let shouldAddKey = false
					Object.keys(keyData).forEach(keyItem => {
						if (keyData[keyItem]) {
							shouldAddKey = true
						}
					})
					if (shouldAddKey) {
						arr.push(key)
					}
				} else {
					if (
						keyData ||
						(attachments[dataKey] && attachments[dataKey][key] && attachments[dataKey][key].length)
					) {
						arr.push(key)
					}
				}
				return arr
			}, [] as string[])
		}
	}

	return {
		keysToShow: {
			equipment: getKeysThatExistInVenueData("equipmentData", [...EQUIPMENT_KEYS]),
			facilities: {
				facility: getKeysThatExistInVenueData("facilitiesData", FACILITIES_FACILITY_KEYS),
				parking: getKeysThatExistInVenueData("facilitiesData", FACILITIES_PARKING_KEYS),
				informationTech: getKeysThatExistInVenueData(
					"facilitiesData",
					FACILITIES_INFORMATION_TECH_KEYS,
				),
			},
			localCrew: getKeysThatExistInVenueData("localCrewData", [...LOCAL_CREW_KEYS]),
			logistics: getKeysThatExistInVenueData("logisticsData", [...LOGISTICS_KEYS]),
			production: {
				staging: getKeysThatExistInVenueData("productionData", PRODUCTION_STAGING_KEYS),
				loading: getKeysThatExistInVenueData("productionData", PRODUCTION_LOADING_KEYS),
				rigging: getKeysThatExistInVenueData("productionData", PRODUCTION_RIGGING_KEYS),
				power: getKeysThatExistInVenueData("productionData", PRODUCTION_POWER_KEYS),
				show: getKeysThatExistInVenueData("productionData", PRODUCTION_SHOW_KEYS),
				house: getKeysThatExistInVenueData("productionData", PRODUCTION_HOUSE_KEYS),
			},
		},
		showPersonnel: !!personnel.length,
		showProduction: showSection("productionData"),
		showFacilities: showSection("facilitiesData"),
		showEquipment: showSection("equipmentData"),
		showLogistics: showSection("logisticsData"),
		showLocalCrew: showSection("localCrewData"),
	}
}
