import { useQuery } from "@tanstack/react-query"
import useAxios from "./useAxios"

export const useProductRoles: () => { productRoles: ProductRole[] } = () => {
	const axios = useAxios()

	const { data: productRoles = [] } = useQuery({
		queryKey: ["user-permissions"],
		queryFn: () =>
			axios.get<{ data: ProductRole[] }>("/venues/v1/roles").then(data => data.data.data),
	})

	return {
		productRoles,
	}
}
