import { FC, useMemo } from "react"
import styled from "styled-components"
import LinkButton from "../../atoms/LinkButton"

interface LinkButtonProps {
	sectionName?: string
}

const SectionTitle: FC<LinkButtonProps> = ({ sectionName }) => {
	const textToCopy = useMemo(() => {
		if (!sectionName) {
			return `${window.location.origin}${window.location.pathname}`
		}
		const splitSectionName = sectionName.replace(/ /g, "").split("")
		splitSectionName[0] = splitSectionName[0].toLowerCase()
		return `${window.location.origin}${window.location.pathname}#${splitSectionName.join("")}`
	}, [sectionName])
	return (
		<MainContainer>
			<TitleLabel
				data-cy={`techPack-sectionTitle_${sectionName}`}
				// id={sectionName?.toLowerCase().replace(/ /g, "")}
			>
				{sectionName}
			</TitleLabel>
			<ButtonContainer>
				<LinkButton data-cy={`techPack-copyLink_${sectionName}`} textToCopy={textToCopy} />
			</ButtonContainer>
		</MainContainer>
	)
}

const MainContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 50px;
	border-bottom: 3px solid ${({ theme }) => theme.techPackTextHeadings};
	color: ${({ theme }) => theme.techPackTextHeadings};
`

const TitleLabel = styled.h1`
	margin: 0;
	font-family: "Signika-Bold" !important;
	padding-bottom: 25px;
`

const ButtonContainer = styled.div`
	cursor: pointer;
	margin-bottom: 20px;
`

export default SectionTitle
