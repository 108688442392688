import { styled } from "@mui/material"
import { getCustomBorder } from "@/util/stylingUtils"
import { useRef, useState } from "react"
import { FileImg } from "../atoms/FileImg"
import { PencilOverlay } from "../atoms/PencilOverlay"
import { getFileIconName } from "@/util/attachmentUtils"
import { Trans, useTranslation } from "react-i18next"

export const PLACEHOLDER_useLogoImage = () => {
	const [previewUrl, setPreviewUrl] = useState<string>()

	const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (e.target.files !== null) {
			const currFile = e.target.files.item(0)
			if (currFile === null) {
				return
			}

			setPreviewUrl(
				getFileIconName(currFile.name) === "img" ? URL.createObjectURL(currFile) : undefined,
			)
		}
	}

	return {
		previewUrl,
		handleFileChange,
	}
}

interface LogoImageProps {
	aspectRatio: [number, number]
	captionText: string
	handleInputChange: React.ChangeEventHandler<HTMLInputElement>
	idealSize: [number, number]
	imageWidth: number
	previewUrl?: string
	variant?: "square" | "circle"
	alt?: string
}

export const LogoImage: React.FC<LogoImageProps> = ({
	aspectRatio,
	captionText,
	handleInputChange,
	idealSize,
	imageWidth,
	previewUrl = "",
	variant = "square",
	alt = previewUrl,
}) => {
	const { t } = useTranslation()
	const fileInputRef = useRef<HTMLInputElement>(null)
	const imageHeight = imageWidth * (aspectRatio[1] / aspectRatio[0])
	return (
		<BaseContainer width={imageWidth}>
			<ImageTitle>{captionText}</ImageTitle>
			<ImageContainer
				variant={variant}
				width={imageWidth}
				height={imageHeight}
				onClick={e => {
					if (previewUrl !== "") {
						e.stopPropagation()
						fileInputRef?.current?.click()
					}
				}}
			>
				{previewUrl !== "" && (
					<PencilOverlay
						style={{
							borderRadius: variant === "circle" ? imageWidth + "px" : 4,
						}}
					/>
				)}

				{previewUrl !== "" ? (
					<FileImg
						previewUrl={previewUrl}
						height={imageHeight + "px"}
						width={imageWidth + "px"}
						style={{ borderRadius: variant === "circle" ? imageWidth + "px" : 4 }}
						filename={alt}
					/>
				) : (
					<PlaceholderContainer variant={variant} width={imageWidth} height={imageHeight}>
						<p>
							<Trans i18nKey="addFileDragAndDrop">
								<span
									onClick={e => {
										e.stopPropagation()
										fileInputRef?.current?.click()
									}}
								>
									Add file
								</span>{" "}
								or drag & drop file here
							</Trans>
						</p>
						<ul>
							<li>{t("fileFormatsPngJpgGif")}</li>
							<li>
								{t("aspectRatio", {
									width: aspectRatio[0],
									height: aspectRatio[1],
								})}
							</li>
							<li>
								{t("idealSize", {
									size: idealSize.join("x"),
								})}
							</li>
						</ul>
					</PlaceholderContainer>
				)}
			</ImageContainer>
			<input
				ref={fileInputRef}
				type="file"
				style={{ display: "none" }}
				onChange={e => {
					e.stopPropagation()
					handleInputChange(e)
				}}
				accept={"image/png, image/jpg, image/gif"}
			/>
		</BaseContainer>
	)
}

const BaseContainer = styled("div", {
	shouldForwardProp: prop => prop !== "width",
})<{ width: number }>`
	width: ${({ width }) => width + "px"};
`

const ImageTitle = styled("p")`
	text-align: start;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	font: 400 12px/16px Roboto-Regular;
	margin-bottom: 8px;
`

const ImageContainer = styled("div", {
	shouldForwardProp: prop =>
		prop !== "variant" && prop !== "width" && prop !== "height" && prop !== "",
})<{ variant: "square" | "circle"; width: number; height: number }>`
	position: relative;
	height: ${({ height }) => height + "px"};
	width: ${({ width }) => width + "px"};
	user-select: none;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};

	& div > div {
		border-radius: ${({ width, variant }) => (variant === "square" ? 4 : width + "px")};
	}
`

const PlaceholderContainer = styled("div", {
	shouldForwardProp: prop =>
		prop !== "variant" && prop !== "width" && prop !== "height" && prop !== "",
})<{ variant: "square" | "circle"; width: number; height: number }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	height: 100%;
	width: 100%;

	background-image: ${({ theme, variant, width }) =>
		getCustomBorder({
			strokeWidth: 1,
			color: theme.colorPalette.surface.onVariant,
			dashArray: [8, 10],
			dashOffset: 4,
			borderRadius: variant === "square" ? 4 : width,
		})};

	& p {
		font: 400 16px/24px Signika-Regular;
		span {
			font: inherit;
			cursor: pointer;
			color: ${({ theme }) => theme.colorPalette.primary.value};
		}
	}

	& ul {
		gap: 4px;
		text-align: start;
		li {
			font: 400 14px/20px Signika-Regular;
		}
	}
`
