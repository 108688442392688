import { TableRow as MuiTableRow, styled } from "@mui/material"

export const TableRow = styled(MuiTableRow)`
	&.MuiTableRow-root {
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}

	&.off-color {
		background-color: ${({ theme }) => theme.colorPalette.surface.container.value} !important;
	}
`
