import { useTranslation } from "react-i18next"
import { css } from "styled-components"
import { styled } from "@mui/material"
import { KeyboardEvent, useCallback, useState } from "react"
import NewButton from "../../atoms/NewButton"
import { HintFormField } from "@/components/molecules/HintFormField"
import useLogin from "@/hooks/useLogin"

export const LoginForm: React.FC<{}> = () => {
	const { t } = useTranslation()
	const [invalidFields, setInvalidFields] = useState<"" | "email" | "password" | "email/password">(
		"",
	)

	const {
		state: { email, password },
		setState,
		login,
		loginPending,
	} = useLogin()

	const handleLogin = useCallback(() => {
		if (loginPending) {
			return
		}
		if (email === "" || password === "") {
			setInvalidFields(
				email === "" && password === "" ? "email/password" : email === "" ? "email" : "password",
			)
			return
		}
		setInvalidFields("")
		login()
	}, [email, password, setInvalidFields, login, loginPending])

	return (
		<FormContainer
			onKeyDown={(e: KeyboardEvent) => {
				if (e.key === "Enter") {
					e.preventDefault()
					handleLogin()
				}
			}}
		>
			<HintFormField
				formFieldProps={{
					"data-cy": "login-email",
					type: "email",
					label: t("email"),
					required: true,
					value: email,
					onChange: (e: any) => setState(prev => ({ ...prev, email: e.target.value })),
					placeholder: "example@gmail.com",
					fullWidth: true,
					autoComplete: "username",
				}}
				displayStatus={
					invalidFields === "email" || invalidFields === "email/password" ? "error" : "hidden"
				}
				translatedHintText={t("invalidEmail")}
			/>
			<HintFormField
				formFieldProps={{
					"data-cy": "login-password",
					type: "password",
					required: true,
					label: t("password"),
					value: password,
					onChange: (e: any) => setState(prev => ({ ...prev, password: e.target.value })),
					fullWidth: true,
					autoComplete: "current-password",
				}}
				displayStatus={
					invalidFields === "password" || invalidFields === "email/password" ? "error" : "hidden"
				}
				translatedHintText={t("enterPassword")}
			/>
			<NewButton
				buttonType="Filled"
				data-cy="submit-login-button"
				text={t("login")}
				disabled={email === "" || password === ""}
				buttonStyles={css`
					border-radius: 6px;
					width: "25%";
				`}
				textStyles={css`
					font-size: 16px;
					line-height: 24px;
				`}
				onClick={e => {
					e.preventDefault()
					handleLogin()
				}}
			/>
		</FormContainer>
	)
}

const FormContainer = styled("form")`
	width: 100%;
	display: inherit;
	justify-content: inherit;
	gap: inherit;
	flex-direction: inherit;
	align-items: inherit;
`
