import { Add as MuiAdd } from "@mui/icons-material"

function Add({ color = "black", size = 24 }: { color?: string; size?: number }) {
	const height = size
	const width = size

	return (
		<MuiAdd
			sx={{
				width: width + "px",
				height: height + "px",
			}}
			htmlColor={color}
		/>
	)
}

export default Add
