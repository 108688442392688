import styled from "styled-components"
import { ProductionSection } from "./ProductionSection"
import { Divider, Stack } from "@mui/material"

export const ProductionSubsection: React.FC<{}> = () => {
	return (
		<RootContainer>
			<ProductionSection stageSubcategory="staging" />
			<StyledDivider />
			<ProductionSection stageSubcategory="loading" />
			<StyledDivider />
			<ProductionSection stageSubcategory="rigging" />
			<StyledDivider />
			<ProductionSection stageSubcategory="power" />
			<StyledDivider />
			<ProductionSection stageSubcategory="show" />
			<StyledDivider />
			<ProductionSection stageSubcategory="house" />
		</RootContainer>
	)
}

const RootContainer = styled(Stack)`
	& {
		gap: 48px;
	}
`

const StyledDivider = styled(Divider)`
	border-color: ${({ theme }) => theme.colorPalette.outline.variant};
	width: 100%;
`
