import { Chip, Stack, styled } from "@mui/material"
import { Check } from "@mui/icons-material"
import { useCallback } from "react"
import { useStaffCrew } from "@/hooks/useStaffCrew"
import { useTranslation } from "react-i18next"

export const StaffFilterChips: React.FC<{}> = () => {
	const { t } = useTranslation()
	const {
		state: { selectedChips },
		setState,
		staffCrewTotals,
	} = useStaffCrew()

	const handleSelect = useCallback(
		(chipName: keyof typeof selectedChips) => {
			switch (chipName) {
				case "all":
					if (selectedChips.all) {
						setState(prev => ({
							...prev,
							selectedChips: {
								all: false,
								venueAdmin: false,
								venueManager: false,
								venueStaff: false,
							},
						}))
					} else {
						setState(prev => ({
							...prev,
							selectedChips: {
								all: true,
								venueAdmin: true,
								venueManager: true,
								venueStaff: true,
							},
						}))
					}

					break
				case "venueAdmin":
				case "venueManager":
				case "venueStaff":
					setState(prev => {
						if (prev.selectedChips[chipName] && prev.selectedChips.all) {
							return {
								...prev,
								selectedChips: {
									...prev.selectedChips,
									all: false,
									[chipName]: false,
								},
							}
						}

						const shallow = {
							...prev.selectedChips,
							all: true,
							[chipName]: !prev.selectedChips[chipName],
						}

						if (
							Object.entries(shallow)
								.filter(([key, _]) => staffCrewTotals[key as keyof typeof staffCrewTotals] > 0)
								.every(([_, val]) => val === true)
						) {
							return {
								...prev,
								selectedChips: shallow,
							}
						}
						return {
							...prev,
							selectedChips: {
								...prev.selectedChips,
								[chipName]: !prev.selectedChips[chipName],
							},
						}
					})
			}
		},
		[selectedChips, setState, staffCrewTotals],
	)

	return (
		<FilterChipContainer direction="row">
			<FilterChip
				label={`${t("all")} (${staffCrewTotals.all})`}
				variant={selectedChips.all && !(staffCrewTotals.all === 0) ? "filled" : "outlined"}
				onClick={() => handleSelect("all")}
				icon={
					selectedChips.all && !(staffCrewTotals.all === 0) ? (
						<Check sx={{ height: "18px", width: "18px" }} />
					) : undefined
				}
				disabled={staffCrewTotals.all === 0}
			/>
			<FilterChip
				label={`${t("venueAdmin")} (${staffCrewTotals.venueAdmin})`}
				variant={
					selectedChips.venueAdmin && !(staffCrewTotals.venueAdmin === 0) ? "filled" : "outlined"
				}
				onClick={() => handleSelect("venueAdmin")}
				icon={
					selectedChips.venueAdmin && !(staffCrewTotals.venueAdmin === 0) ? (
						<Check sx={{ height: "18px", width: "18px" }} />
					) : undefined
				}
				disabled={staffCrewTotals.venueAdmin === 0}
			/>
			<FilterChip
				label={`${t("venueManager")} (${staffCrewTotals.venueManager})`}
				variant={
					selectedChips.venueManager && !(staffCrewTotals.venueManager === 0)
						? "filled"
						: "outlined"
				}
				onClick={() => handleSelect("venueManager")}
				icon={
					selectedChips.venueManager && !(staffCrewTotals.venueManager === 0) ? (
						<Check sx={{ height: "18px", width: "18px" }} />
					) : undefined
				}
				disabled={staffCrewTotals.venueManager === 0}
			/>
			<FilterChip
				label={`${t("venueStaff")} (${staffCrewTotals.venueStaff})`}
				variant={
					selectedChips.venueStaff && !(staffCrewTotals.venueStaff === 0) ? "filled" : "outlined"
				}
				onClick={() => handleSelect("venueStaff")}
				icon={
					selectedChips.venueStaff && !(staffCrewTotals.venueStaff === 0) ? (
						<Check sx={{ height: "18px", width: "18px" }} />
					) : undefined
				}
				disabled={staffCrewTotals.venueStaff === 0}
			/>
		</FilterChipContainer>
	)
}

const FilterChipContainer = styled(Stack)`
	padding: 16px 0px;
	gap: 16px;
`

const FilterChip = styled(Chip)`
	&.MuiChip-root {
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		text-align: center;
		letter-spacing: 0.1px;
		border-radius: 8px;
		padding: 6px 9px;
	}

	& .MuiChip-label {
		font: 500 14px/20px Signika-Medium;
		letter-spacing: 0.1px;
	}

	&.MuiChip-filled {
		background-color: ${({ theme }) => theme.colorPalette.secondary.container.value};
		color: ${({ theme }) => theme.colorPalette.secondary.container.on};
		&:hover {
			background-color: ${({ theme }) => theme.colorPalette.secondary.container.value}E1;
		}
	}

	&.MuiChip-outlined {
		border: 1px solid ${({ theme }) => theme.colorPalette.outline.value};
	}
`
