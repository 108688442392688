import { STAGE_CATEGORY_KEYS, STAGE_SUBCATEGORY_KEYS } from "./constants/STAGE_SUBCATEGORY_KEYS"
import { getObjectEntries, getObjectKeys } from "./typedObjectMapping"

const isCategory = (
	subCat: StageSubcategory | StageCategory,
): subCat is StageSubcategory & StageCategory => {
	return subCat in STAGE_CATEGORY_KEYS
}

export const getCategory = (subCategoryName: StageSubcategory): StageCategory => {
	const valIsCat = isCategory(subCategoryName)

	if (valIsCat) {
		return subCategoryName
	}

	switch (subCategoryName) {
		case "staging":
		case "house":
		case "loading":
		case "power":
		case "rigging":
		case "show":
			return "productionData"
		case "facility":
		case "informationTech":
		case "parking":
			return "facilitiesData"
	}
}

export const getSection = (fieldName: string) => {
	const foundSection = getObjectEntries(STAGE_SUBCATEGORY_KEYS).find(([_, sectionFields]) =>
		sectionFields.find(value => value === fieldName),
	)

	if (!foundSection) return ""

	return foundSection[0]
}

export const getSectionFields = (section: string) => {
	const sectionKey = getObjectKeys(STAGE_SUBCATEGORY_KEYS).find(val => val === section)

	if (!sectionKey) return []

	return STAGE_SUBCATEGORY_KEYS[sectionKey]
}
