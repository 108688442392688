import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export interface ConfirmCloseContextType {
	setShouldPreventClose: (shouldPreventClose: boolean) => void
	shouldPreventClose: boolean
	handleConfirmNavgate: (callbackFn: () => void) => void
}

export const ConfirmCloseContext = createContext<ConfirmCloseContextType>({
	setShouldPreventClose: () => {},
	shouldPreventClose: false,
	handleConfirmNavgate: () => undefined,
})

export const ConfirmCloseProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const { t } = useTranslation()
	const [shouldPreventClose, setShouldPreventClose] = useState(false)

	const handleConfirmNavgate = useCallback(
		(callbackFn: () => void) => {
			if (shouldPreventClose) {
				const shouldContinue = window.confirm(t("discardPrompt"))
				if (shouldContinue) {
					setShouldPreventClose(false)
					callbackFn()
				}
			} else {
				callbackFn()
			}
		},
		[shouldPreventClose, t],
	)

	useEffect(() => {
		const handleBeforeUnload = (event: Event) => {
			if (shouldPreventClose) {
				event.preventDefault()
			}
		}

		window.addEventListener("beforeunload", handleBeforeUnload)
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload)
		}
	}, [shouldPreventClose])

	return (
		<ConfirmCloseContext.Provider
			value={{ setShouldPreventClose, shouldPreventClose, handleConfirmNavgate }}
		>
			{children}
		</ConfirmCloseContext.Provider>
	)
}

export const useConfirmCloseContext = () => {
	return useContext(ConfirmCloseContext)
}
