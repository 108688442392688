import { useTranslation } from "react-i18next"
import useAxios from "../hooks/useAxios"
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query"

type VenueStageType = {
	id: string
	translationKey: string
	translatedValue: string
}

export const useVenueStageTypes: () => {
	venueStageTypes: VenueStageType[]
	getVenueStageType: UseMutateFunction<VenueStageType, unknown, string, unknown>
} = () => {
	const axios = useAxios()
	const { t } = useTranslation()

	const { data: venueStageTypes } = useQuery({
		queryKey: ["venue-stage-types"],
		queryFn: async () => {
			return axios
				.get<{ data: { name: string; id: string }[] }>("/venues/v1/venue-stage-types")
				.then(data =>
					data.data.data
						.map(stageType => {
							return {
								id: stageType.id,
								translationKey: stageType.name,
								translatedValue: t(stageType.name),
							}
						})
						// Alpha sort by translated value here to ensure all languages have correctly sorted output
						.sort((a: VenueStageType, b: VenueStageType) =>
							a.translatedValue.localeCompare(b.translatedValue),
						),
				)
		},
		meta: {
			errorMessage: t("errorTryAgain"),
		},
	})

	const { mutate: getVenueStageType } = useMutation({
		mutationFn: async (venueStageTypeId: string) => {
			return axios
				.get<{ data: { name: string; id: string } }>(
					`/venues/v1/venue-stage-types/${venueStageTypeId}`,
				)
				.then(data => ({
					id: data.data.data.id,
					translationKey: data.data.data.name,
					translatedValue: t(data.data.data.name),
				}))
		},
	})

	return {
		venueStageTypes: venueStageTypes || [],
		getVenueStageType,
	}
}
