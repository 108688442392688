import { styled } from "@mui/material"

interface PlaceholderContentProps {
	text: string
	width?: string
	height?: string
}

export const PlaceholderContent: React.FC<PlaceholderContentProps> = ({
	text,
	width = "100%",
	height = "100%",
}: PlaceholderContentProps) => {
	return (
		<PlaceholderContainer width={width} height={height} data-cy-component="PlaceholderContent_Component">
			<p>{text}</p>
		</PlaceholderContainer>
	)
}

const PlaceholderContainer = styled("div", {
	shouldForwardProp: prop => prop !== "height" && prop !== "width",
})<{ width: string; height: string }>`
	background-color: ${({ theme }) => theme.colorPalette.surface.container.low};
	border: 2px solid ${({ theme }) => theme.colorPalette.surface.variant};
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${({ width }) => width};
	height: ${({ height }) => height};

	& > p {
		font: 400 16px/24px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.outline.value};
	}
`