import { styled, TextFieldProps } from "@mui/material"
import FormField from "../atoms/FormField"
import { ErrorOutline } from "@mui/icons-material"

interface HintFormFieldProps {
	formFieldProps: TextFieldProps & { "data-cy"?: string }
	displayStatus: "hidden" | "hint" | "error"
	translatedHintText: string
}

export const HintFormField: React.FC<HintFormFieldProps> = ({
	formFieldProps,
	translatedHintText,
	displayStatus,
}) => {
	return (
		<>
			<TextField>
				<FormField {...formFieldProps} />
			</TextField>
			{displayStatus === "error" ? (
				<SmallContainer>
					<ErrorOutline />
					<ErrorText>{translatedHintText}</ErrorText>
				</SmallContainer>
			) : (
				displayStatus === "hint" && (
					<SmallContainer>
						<HintText>{translatedHintText}</HintText>
					</SmallContainer>
				)
			)}
		</>
	)
}

const HintText = styled("small")`
	font: inherit;
	padding-left: 8px;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
`

const ErrorText = styled("small")`
	font: inherit;
	color: ${({ theme }) => theme.colorPalette.error.value};
`

const TextField = styled("div")`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
`

const SmallContainer = styled("div")`
	margin-top: -32px;
	margin-bottom: -20px;
	align-self: start;

	display: flex;
	justify-content: start;
	font: 400 14px/20px Signika-Regular;

	& > svg {
		padding-right: 8px;
		color: ${({ theme }) => theme.colorPalette.error.value};
		height: 18px;
	}
`
