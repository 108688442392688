import mediaQuery from "@/util/mediaQuery"
import { ReactNode } from "react"
import styled from "styled-components"

function SectionContainer({
	title,
	children,
	hideDivider = false,
}: {
	title?: string
	children: ReactNode
	hideDivider?: boolean
}) {
	return (
		<BaseContainer>
			{!hideDivider && (
				<DividerContainer hasTitle={title !== undefined}>
					<Divider />
				</DividerContainer>
			)}

			{title && (
				<TitleDiv>
					<TitleSpan>{title}</TitleSpan>
				</TitleDiv>
			)}

			{children}
		</BaseContainer>
	)
}

const DividerContainer = styled("div")<{ hasTitle: boolean }>`
	width: 288px;
	height: ${({ hasTitle }) => (hasTitle ? "23px" : "45px")};
	padding: ${({ hasTitle }) => (hasTitle ? "22px 22px 0px 22px" : "22px 0px 0px 0px")};
`

const Divider = styled.hr`
	margin: 0 auto 0 auto;
	width: 244px;
	height: 0px;
	padding: 0px;
	border: 1px solid ${({ theme }) => theme.colorPalette.outline.variant};
`

const TitleDiv = styled.div`
	width: 288px;
	height: 56px;
	padding: 18px 22px 18px 22px;
	gap: 10px;
	border-radius: 100px 0px 0px 0px;
`

const TitleSpan = styled.span`
	font-family: Signika-Semibold;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.10000000149011612px;
	text-align: left;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
`

const BaseContainer = styled.div`
	width: 100%;

	${mediaQuery("md")`
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
	`}
`

export default SectionContainer
