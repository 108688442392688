import { Category, CategoryOutlined } from "@mui/icons-material"

function VenueStage({ color = "black", size = 19, fill = false }: UpdatedIconTypes) {
	const height = size / 0.95
	const width = size

	return fill ? (
		<Category width={width} height={height} htmlColor={color} />
	) : (
		<CategoryOutlined width={width} height={height} htmlColor={color} />
	)
}

export default VenueStage
