import { FixedKeyColor, Scheme, ColorTheme, HexCodeValue } from "./newThemeTypes"

/** Base Colors shared by light and dark theme */
const BaseColorTheme: {
	scrim: HexCodeValue
	shadow: HexCodeValue
	primaryFixed: FixedKeyColor
	secondaryFixed: FixedKeyColor
} = {
	primaryFixed: {
		value: "#D3E4FF",
		on: "#001C38",
		dim: "#A1C9FF",
		onVariant: "#004880",
	},
	secondaryFixed: {
		value: "#D7E3F8",
		on: "#101C2B",
		dim: "#BBC7DB",
		onVariant: "#3C4858",
	},
	scrim: "#000",
	shadow: "#000",
}

/** Light Scheme Colors */
const LightScheme: Scheme = {
	primary: {
		value: "#0060A8",
		on: "#FFF",
		container: {
			value: "#D3E4FF",
			on: "#001C38",
		},
		inverse: "#A1C9FF",
	},
	secondary: {
		value: "#545F70",
		on: "#FFF",
		container: {
			value: "#D7E3F8",
			on: "#101C2B",
		},
	},
	tertiary: {
		value: "#6C5677",
		on: "#FFF",
		container: {
			value: "#F4D9FF",
			on: "#261431",
		},
		fixed: {
			value: "#F4D9FF",
			dim: "#D8BDE3",
			on: "#261431",
			onVariant: "#533F5F",
		},
	},
	error: {
		value: "#BA1A1A",
		on: "#FFF",
		container: {
			value: "#FFDAD6",
			on: "#410002",
		},
	},
	surface: {
		dim: "#DAD9DD",
		value: "#FAF9FD",
		bright: "#FAF9FD",
		container: {
			lowest: "#FFF",
			low: "#F4F3F7",
			value: "#EEEDF1",
			high: "#E8E8EB",
			highest: "#E3E2E6",
		},
		variant: "#DFE2EB",
		on: "#1A1C1E",
		onVariant: "#43474E",
		inverse: "#2E3033",
		onInverse: "#F1F0F4",
	},
	outline: {
		value: "#73777F",
		variant: "#C3C6CF",
		inverseVariant: "#43474E",
	},
	stateLayers: {
		primary: {
			on: "#FFFFFF",
			value: "#6750A4",
		},
		secondary: {
			container: {
				on: "#1D192B",
			},
		},
		surface: {
			on: "#1A1C1E",
		},
	},
}

/** Dark Scheme Colors */
const DarkScheme: Scheme = {
	primary: {
		value: "#A1C9FF",
		on: "#00315B",
		container: {
			value: "#004880",
			on: "#D3E4FF",
		},
		inverse: "#0061A8",
	},
	secondary: {
		value: "#BBC7DB",
		on: "#00315B",
		container: {
			value: "#3C4858",
			on: "#D7E3F8",
		},
	},
	tertiary: {
		value: "#D8BDE3",
		on: "#3C2947",
		container: {
			value: "#533F5F",
			on: "#F4D9FF",
		},
		fixed: {
			value: "#F4D9FF",
			dim: "#D8BDE3",
			on: "#261431",
			onVariant: "#633B48",
		},
	},
	error: {
		on: "#690005",
		value: "#FFB4AB",
		container: {
			value: "#93000A",
			on: "#FFDAD6",
		},
	},
	surface: {
		dim: "#121316",
		value: "#121316",
		bright: "#38393C",
		container: {
			lowest: "#0D0E11",
			low: "#1A1C1E",
			value: "#1E2022",
			high: "#292A2D",
			highest: "#333538",
		},
		variant: "#DFE2EB", //NOTE: Not sure about this, using the same as light for now
		on: "#C6C6CA",
		onVariant: "#C3C6CF",
		inverse: "#E3E2E6",
		onInverse: "#1A1C1E",
	},
	outline: {
		value: "#8D9199",
		variant: "#43474E",
		inverseVariant: "#C3C6CF",
	},
	stateLayers: {
		primary: {
			on: "#381E72",
			value: "#D0BCFF",
		},
		secondary: {
			container: {
				on: "#E8DEF8",
			},
		},
		surface: {
			on: "#C6C6CA",
		},
	},
}

/** Gets a ColorTheme object by current scheme setting (dark/light mode) */
export function getColorTheme(darkMode?: boolean): ColorTheme {
	const currentScheme = darkMode ? DarkScheme : LightScheme
	return {
		...currentScheme,
		primary: {
			...currentScheme.primary,
			fixed: BaseColorTheme.primaryFixed,
		},
		secondary: {
			...currentScheme.secondary,
			fixed: BaseColorTheme.secondaryFixed,
		},
		scrim: BaseColorTheme.scrim,
		shadow: BaseColorTheme.shadow,
	}
}
