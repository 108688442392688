import { useAttachments } from "@/hooks/useAttachments"
import { getFileIconName, getPreviewUrl } from "@/util/attachmentUtils"
import { getSectionFields } from "@/util/fieldUtils"
import { UseMutateAsyncFunction } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import {
	createContext,
	SetStateAction,
	Dispatch,
	useState,
	useEffect,
	useCallback,
	useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useVenuesContext } from "./venues"

interface ProductionAttachmentsState {
	category:
		| "productionData"
		| "facilitiesData"
		| "equipmentData"
		| "logisticsData"
		| "localCrewData"
		| ""
	categorySection: string
	translatedSectionFields: {
		translationKey: string
		translatedValue: string
	}[]
	currentUserName: string

	addAttachmentModalOpen: boolean
	editAttachmentModalOpen: boolean
	deleteAttachmentModalOpen: boolean
	addEditModalState: {
		selectedField: string
		selectedAttachmentId?: string
		fieldError: boolean
		previewData: {
			id: string
			filename: string
			previewUrl?: string
		}[]
		addData: Record<
			string,
			{
				file: File
				caption: string
				title: string
				timestamp: string
			}
		>
		editData: Record<
			string,
			{
				caption: string
				title: string
			}
		>
		editFileAttachments: Record<string, FileAttachment>
	}
}

export interface ProductionAttachmentsContextTypes {
	state: ProductionAttachmentsState
	selectedVenue: Venue | null
	selectedStage: Stage | null
	fileAttachments: FileAttachment[]
	requestPending: boolean
	setState: Dispatch<SetStateAction<ProductionAttachmentsState>>
	handleLogoUpload: React.ChangeEventHandler<HTMLInputElement>

	handleFileInput: (fileList: FileList | null) => void

	submitAddAttachments: () => void
	submitEditAttachments: () => void
	initializeEditAttachmentData: () => void

	uploadAttachment: UseMutateAsyncFunction<
		UploadFileResponseData,
		Error,
		{
			category?: string
			file: File
			metadata?: Record<string, any>
		},
		unknown
	>

	bulkUpdateAttachments: UseMutateAsyncFunction<
		FileAttachment[],
		Error,
		(Omit<Partial<FileAttachment>, "file"> & {
			id: string
			file?: Partial<FileObject>
		})[],
		unknown
	>

	deleteAttachment: UseMutateAsyncFunction<
		AxiosResponse<
			{
				data: string
			},
			any
		>,
		Error,
		string,
		unknown
	>

	canSubmitAttachments: boolean
	venueLogo: FileAttachment | null
	venueLogoUploadIsPending: boolean
}

const DEFAULT_ADD_EDIT_MODAL_STATE = {
	selectedAttachmentId: undefined,
	selectedField: "",
	fieldError: false,
	previewData: [],
	addData: {},
	editData: {},
	editFileAttachments: {},
}

const INITIAL_PRODUCTION_ATTACHMENT_STATE: ProductionAttachmentsState = {
	category: "",
	categorySection: "",
	translatedSectionFields: [],
	currentUserName: "",
	addAttachmentModalOpen: false,
	editAttachmentModalOpen: false,
	deleteAttachmentModalOpen: false,
	addEditModalState: DEFAULT_ADD_EDIT_MODAL_STATE,
}

export const ProductionAttachmentsContext = createContext<ProductionAttachmentsContextTypes>({
	state: INITIAL_PRODUCTION_ATTACHMENT_STATE,
	setState: () => null,
	canSubmitAttachments: false,
	selectedStage: null,
	selectedVenue: null,
	fileAttachments: [],

	submitEditAttachments: () => null,
	submitAddAttachments: () => null,
	initializeEditAttachmentData: () => null,
	handleFileInput: () => null,
	uploadAttachment: () => new Promise(() => []),
	bulkUpdateAttachments: () => new Promise(() => []),
	deleteAttachment: () => new Promise(() => []),
	requestPending: false,
	handleLogoUpload: () => null,
	venueLogo: null,
	venueLogoUploadIsPending: false,
})

export default function ProductionAttatchmentsContextProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const { t } = useTranslation()
	const [state, setState] = useState(INITIAL_PRODUCTION_ATTACHMENT_STATE)
	const { selectedStage, selectedVenue } = useVenuesContext()
	const {
		fileAttachments = [],
		uploadAttachment,
		bulkUpdateAttachments,
		deleteAttachment,
		bulkUpdateIsPending,
		uploadIsPending,
		deleteIsPending,
		updateIsPending,
	} = useAttachments("venueStage", selectedStage?.id || "")

	const {
		fileAttachments: venueAttachments = [],
		uploadAttachment: uploadVenueLogo,
		uploadIsPending: venueLogoUploadIsPending,
	} = useAttachments("venue", selectedVenue?.id || "")

	const handleLogoUpload: React.ChangeEventHandler<HTMLInputElement> = useCallback(
		e => {
			if (e.target.files === null) return
			const currFile = e.target.files.item(0)
			if (currFile === null) return

			uploadVenueLogo({
				replaceExisting: true,
				category: "logo",
				fileAttachmentMetadata: {
					includedInTechPack: true,
				},
				file: currFile,
			}).then(() => {
				toast.success(t("completed"))
			})
		},
		[uploadVenueLogo, t],
	)

	const canSubmitAttachments = useMemo(() => {
		if (state.addAttachmentModalOpen) {
			return (
				state.addEditModalState.previewData.length > 0 &&
				state.addEditModalState.selectedField !== ""
			)
		}

		if (state.editAttachmentModalOpen) {
			return !state.addEditModalState.previewData.every((val, idx) => {
				const originalData = state.addEditModalState.editFileAttachments[val.id]
				const newData = state.addEditModalState.editData[val.id]

				return (
					originalData.metadata?.order === idx &&
					(originalData.file.metadata?.caption || "") === newData.caption &&
					(originalData.file.metadata?.filename || "") === newData.title
				)
			})
		}

		return false
	}, [
		state.addAttachmentModalOpen,
		state.editAttachmentModalOpen,
		state.addEditModalState.previewData,
		state.addEditModalState.selectedField,
		state.addEditModalState.editFileAttachments,
		state.addEditModalState.editData,
	])

	const submitEditAttachments = useCallback(async () => {
		if (!canSubmitAttachments) {
			return
		}
		const uploadPackage = state.addEditModalState.previewData.reduce<
			(Omit<Partial<FileAttachment>, "file"> & { id: string; file?: Partial<FileObject> })[]
		>((prev, curr, idx) => {
			const originalFileMetadata =
				state.addEditModalState.editFileAttachments[curr.id].file.metadata
			const originalMetadata = state.addEditModalState.editFileAttachments[curr.id].metadata
			const editedData = state.addEditModalState.editData[curr.id]

			const tempData: Omit<Partial<FileAttachment>, "file"> & {
				id: string
				file?: Partial<FileObject>
			} = {
				id: curr.id,
			}

			if (originalMetadata.order !== idx) {
				tempData.metadata = {
					...originalMetadata,
					order: idx,
				}
			}

			if (
				originalFileMetadata.filename !== editedData.title ||
				originalFileMetadata.caption !== editedData.caption
			) {
				tempData.file = {}
				tempData.file.metadata = { ...originalFileMetadata }

				if (originalFileMetadata.filename !== editedData.title) {
					tempData.file.metadata.filename = editedData.title
				}

				if (originalFileMetadata.caption !== editedData.caption) {
					tempData.file.metadata.caption = editedData.caption
				}
			}

			if (Object.keys(tempData).length === 1) {
				return prev
			}

			return [
				...prev,
				{
					...tempData,
					metadata: {
						...(tempData.metadata || {}),
						includedInTechPack: true,
					},
				},
			]
		}, [])

		await bulkUpdateAttachments(uploadPackage)

		toast.success(t("updateSuccessful"))
	}, [
		state.addEditModalState.editFileAttachments,
		state.addEditModalState.editData,
		state.addEditModalState.previewData,
		canSubmitAttachments,
		bulkUpdateAttachments,
		t,
	])

	const submitAddAttachments = useCallback(async () => {
		const fullCategory = ["production", state.category, state.addEditModalState.selectedField].join(
			".",
		)

		const uploadPayload = state.addEditModalState.previewData.map(val => {
			const dataEntry = state.addEditModalState.addData[val.id]
			return {
				category: fullCategory,
				file: dataEntry.file,
				metadata: {
					filename: dataEntry.title,
					caption: dataEntry.caption,
				},
			}
		})

		if (uploadPayload.length === 0) {
			return
		}
		const currentAttachmentLength = fileAttachments.filter(
			val => val.category === fullCategory,
		).length

		const responses = await Promise.all(
			uploadPayload.map(val =>
				uploadAttachment(val, {
					onSuccess: () => {},
				}),
			),
		)

		const newUploadPackage = responses.map((val, idx) => {
			const attachmentData = val.file_attachments[val.file_attachments.length - 1]

			return {
				id: attachmentData.id,
				metadata:
					attachmentData.metadata === null
						? {
								order: idx + currentAttachmentLength,
								includedInTechPack: true,
						  }
						: {
								...attachmentData.metadata,
								order: idx + currentAttachmentLength,
								includedInTechPack: true,
						  },
			}
		})

		await bulkUpdateAttachments(newUploadPackage)

		toast.success(t("updateSuccessful"))

		setState(prev => ({
			...prev,
			addAttachmentModalOpen: false,
		}))
	}, [
		state.addEditModalState.selectedField,
		state.addEditModalState.previewData,
		state.addEditModalState.addData,
		fileAttachments,
		uploadAttachment,
		bulkUpdateAttachments,
		setState,
		t,
		state.category,
	])

	const handleFileInput = useCallback(
		(fileList: FileList | null) => {
			if (fileList !== null) {
				setState(prevState => {
					const prevFileKeys = Object.keys(prevState.addEditModalState.addData)
					const newData = { ...prevState.addEditModalState.addData }
					const newPreviewData = [...prevState.addEditModalState.previewData]

					let currFile: File | null
					for (let i = 0; i < fileList.length; i++) {
						currFile = fileList.item(i)
						if (
							currFile !== null &&
							!prevFileKeys.includes(currFile.name + currFile.size.toString())
						) {
							newPreviewData.push({
								id: currFile.name + currFile.size.toString(),
								filename: currFile.name,
								previewUrl:
									getFileIconName(currFile.name) === "img"
										? URL.createObjectURL(currFile)
										: undefined,
							})

							newData[currFile.name + currFile.size.toString()] = {
								file: currFile,
								caption: "",
								title: currFile.name,
								timestamp: new Date().toLocaleString(),
							}
						}
					}

					return {
						...prevState,
						addEditModalState: {
							...prevState.addEditModalState,
							addData: newData,
							previewData: newPreviewData,
						},
					}
				})
			}
		},
		[setState],
	)

	const initializeEditAttachmentData = useCallback(() => {
		if (
			state.category === "" ||
			fileAttachments.length === 0 ||
			state.addEditModalState.selectedField === ""
		) {
			return
		}
		const fullCategory = ["production", state.category, state.addEditModalState.selectedField].join(
			".",
		)

		const { newEditData, newEditFileAttachments } = fileAttachments.reduce<{
			newEditData: Record<string, { caption: string; title: string }>
			newEditFileAttachments: Record<string, FileAttachment>
		}>(
			(prev, curr) => {
				if (curr.category !== fullCategory) {
					return prev
				}
				return {
					newEditData: {
						...prev.newEditData,
						[curr.id]: {
							caption: curr.file.metadata.caption || "",
							title: curr.file.metadata.filename || "",
						},
					},
					newEditFileAttachments: {
						...prev.newEditFileAttachments,
						[curr.id]: {
							...curr,
						},
					},
				}
			},
			{
				newEditData: {},
				newEditFileAttachments: {},
			},
		)

		const newPreviewData = Object.values(newEditFileAttachments)
			.sort((valA, valB) => (valA.metadata.order || 0) - (valB.metadata.order || 0))
			.map((val, idx) => {
				if (newEditFileAttachments[val.id].metadata.order === undefined) {
					newEditFileAttachments[val.id].metadata.order = idx
				}
				return {
					id: val.id,
					filename: val.file.filename,
					previewUrl: getPreviewUrl(val.file),
				}
			})

		setState(prev => ({
			...prev,
			addEditModalState: {
				...prev.addEditModalState,
				previewData: newPreviewData,
				editData: newEditData,
				editFileAttachments: newEditFileAttachments,
			},
		}))
	}, [setState, state.category, state.addEditModalState.selectedField, fileAttachments])

	/* Handles state resetting on modal close */
	useEffect(() => {
		if (!state.addAttachmentModalOpen && !state.editAttachmentModalOpen) {
			setState(prevState => ({
				...prevState,
				addEditModalState: DEFAULT_ADD_EDIT_MODAL_STATE,
			}))
		}
	}, [state.addAttachmentModalOpen, state.editAttachmentModalOpen, setState])

	/* Gets translated section values for select */
	useEffect(() => {
		setState(newState => ({
			...newState,
			translatedSectionFields: getSectionFields(
				state.categorySection === "" ? state.category : state.categorySection,
			).map(val => ({
				translationKey: val,
				translatedValue: t(val),
			})),
		}))
	}, [state.categorySection, state.category, setState, t])

	useEffect(() => {
		if (state.editAttachmentModalOpen) {
			initializeEditAttachmentData()
		}
	}, [state.editAttachmentModalOpen, initializeEditAttachmentData])

	const venueLogo = useMemo(() => {
		return venueAttachments.find(val => val.category === "logo") ?? null
	}, [venueAttachments])

	return (
		<ProductionAttachmentsContext.Provider
			value={{
				state,
				setState,
				submitAddAttachments,
				submitEditAttachments,
				selectedStage,
				selectedVenue,
				fileAttachments,
				uploadAttachment,
				bulkUpdateAttachments,
				deleteAttachment,
				handleFileInput,
				canSubmitAttachments,
				initializeEditAttachmentData,
				venueLogo: venueLogo,
				venueLogoUploadIsPending,
				handleLogoUpload: handleLogoUpload,
				requestPending:
					bulkUpdateIsPending || uploadIsPending || deleteIsPending || updateIsPending,
			}}
		>
			{children}
		</ProductionAttachmentsContext.Provider>
	)
}
