export const getCustomBorder = ({
	strokeWidth = 1,
	color = "black",
	borderRadius = 0,
	dashOffset = 0,
	dashArray = [],
	lineCap = "butt",
}: {
	strokeWidth?: number
	color?: string
	borderRadius?: number
	dashOffset?: number
	dashArray?: number[]
	lineCap?: "butt" | "round" | "square"
}) => {
	return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' ${
		borderRadius !== 0 ? "rx='" + borderRadius + "' ry='" + borderRadius + "' " : ""
	}stroke='${color.replace(
		"#",
		"%23",
	)}' stroke-width='${strokeWidth}' stroke-dasharray='${dashArray.join(
		"%2c ",
	)}' stroke-dashoffset='${dashOffset}' stroke-linecap='${lineCap}'/%3e%3c/svg%3e")
	`
}
