import { History as MUIHistory } from "@mui/icons-material"

function History({ color = "black", size = 24 }: UpdatedIconTypes) {
	const height = size
	const width = size

	return (
		<MUIHistory
			sx={{
				height: height,
				width: width,
			}}
			htmlColor={color}
		/>
	)
}

export default History
