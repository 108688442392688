import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { styled } from "@mui/material"
import { css } from "styled-components"
import { toast } from "react-toastify"
import PasswordChecklist, { RuleNames } from "react-password-checklist"
import { useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import axios from "axios"
import { getAnalytics, logEvent } from "firebase/analytics"
import LoaderOverlay from "../organisms/LoaderOverlay"
import { LoginWrapper } from "../organisms/login/LoginWrapper"
import { HintFormField } from "../molecules/HintFormField"
import NewButton from "../atoms/NewButton"
import { LoginFooter } from "../organisms/login/LoginFooter"
const { REACT_APP_API_URL } = process.env

interface RuleTypes {
	match: boolean
	uppercase?: boolean
	lowercase?: boolean
	number?: boolean
	symbol?: boolean
	minLength?: number
}

interface ValidateResponseData {
	isValid: boolean
	email: string
	passwordRequirements: {
		uppercase: boolean
		lowercase: boolean
		number: boolean
		symbol: boolean
		length: number
	}
}

export const ResetPassword: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const location = useLocation()
	const analytics = getAnalytics()
	const { code } = useParams()
	const [searchParams] = useSearchParams()

	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [isPassValid, setIsPassValid] = useState(false)
	const [rules, setRules] = useState<RuleTypes>({ match: true })

	const { mutate: getRules, isPending: isLoadingRules } = useMutation({
		mutationKey: ["get-password-rules"],
		mutationFn: () => {
			return axios
				.post<{ data: ValidateResponseData }>(
					`${REACT_APP_API_URL}/venues/v1/password/reset/validate`,
					{ code },
				)
				.then(res => res.data.data)
		},

		onSuccess: data => {
			const newRules = Object.keys(data.passwordRequirements).reduce(
				(obj, value: string) => {
					if (!Object.hasOwn(data.passwordRequirements, value)) {
						return obj
					}
					switch (value) {
						case "uppercase":
							return { ...obj, capital: true }
						case "lowercase":
							return { ...obj, lowercase: true }
						case "number":
							return { ...obj, number: true }
						case "symbol":
							return { ...obj, specialChar: true }
						case "length":
							return { ...obj, minLength: true }
						default:
							return obj
					}
				},
				{ match: true },
			)
			setRules(newRules)
		},
		onError: (err: any) => {
			if (err.response.status === 400) {
				toast.error(t("passwordResetLinkExpired"), { toastId: "error-toast" })
				navigate("/login")
			} else {
				toast.error(t("tryAgain"), { toastId: "error-toast" })
			}
		},
	})

	const { mutate: updatePassword, isPending: isLoadingUpdate } = useMutation({
		mutationKey: ["update-password"],
		mutationFn: async () => {
			return await axios.post(`${REACT_APP_API_URL}/venues/v1/password/reset/complete`, {
				code,
				password,
				passwordConfirmation: confirmPassword,
			})
		},

		onSuccess: () => {
			setPassword("")
			setConfirmPassword("")
			toast.success(t("credentialsInvalid"))
			navigate("/login")
		},

		onError: err => {
			console.error({ err })
			toast.error(t("errorTryAgain"))
		},
	})

	useEffect(() => {
		logEvent(analytics, "page_view", {
			page_title: "Update Password",
			page_location: window.location.href,
			page_path: location.pathname,
		})
	}, [location.pathname, analytics])

	useEffect(() => {
		code && getRules()
	}, [code, getRules])

	return (
		<>
			<LoaderOverlay isLoading={isLoadingRules} initialLoading={false} />
			<LoginWrapper>
				<FormContainer
					onKeyDown={e => {
						if (e.key === "Enter") {
							e.preventDefault()
							updatePassword()
						}
					}}
				>
					<StyledTitle>
						{searchParams.get("new-user") === "true" ? "Set Password" : t("resetPassword")}
					</StyledTitle>
					<HintFormField
						formFieldProps={{
							"data-cy": "change-password-field",
							id: "passwordTextField",
							type: "password",
							label: t("password"),
							required: true,
							value: password,
							onChange: e => setPassword(e.target.value),
							fullWidth: true,
						}}
						displayStatus="hidden"
						translatedHintText={t("password")}
					/>

					<HintFormField
						formFieldProps={{
							id: "confirmPasswordTextField",
							"data-cy": "confirm-change-password-field",
							type: "password",
							label: t("confirmPassword"),
							required: true,
							value: confirmPassword,
							onChange: e => setConfirmPassword(e.target.value),
							fullWidth: true,
							sx: {
								marginBottom: "-20px",
							},
						}}
						displayStatus="hidden"
						translatedHintText={t("password")}
					/>

					<StyledChecklist
						rules={Object.keys(rules) as RuleNames[]}
						minLength={rules.minLength ? rules.minLength : undefined}
						value={password}
						valueAgain={confirmPassword}
						onChange={setIsPassValid}
						iconSize={16}
						messages={{
							capital: t("passwordMustContainCapitalLetter"),
							lowercase: t("passwordMustContainLowercaseLetter"),
							match: t("passwordMustMatch"),
							minLength: t("passwordMustBeAtLeastCharacters", { count: rules.minLength }),
							number: t("passwordMustContainNumber"),
							specialChar: t("passwordMustContainSpecialCharacter"),
						}}
					/>

					<NewButton
						buttonType="Filled"
						data-cy="submit-change-password-button"
						text={t("update")}
						buttonStyles={css`
							border-radius: 6px;
							width: "25%";
						`}
						textStyles={css`
							font-size: 16px;
							line-height: 24px;
						`}
						onClick={e => {
							e.preventDefault()
							updatePassword()
						}}
						disabled={!isPassValid || isLoadingUpdate}
					/>
				</FormContainer>
				<LoginFooter isReset updatePage={() => navigate("/login")} />
			</LoginWrapper>
		</>
	)
}

const StyledTitle = styled("h2")`
	font: 400 28px/36px Signika-Regular;
	margin-bottom: -10px;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`

const StyledChecklist = styled(PasswordChecklist)`
	& {
		width: 100%;
		gap: 20px;
	}

	& > li {
		margin: 4px 0px !important;
	}

	& > li > span {
		font: 400 12px/16px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	}

	svg {
		flex: none;
	}
`

const FormContainer = styled("div")`
	width: 448px;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	justify-content: start;
	flex-direction: column;

	align-items: center;
	gap: 40px;
`
