import { createContext, useContext, useState } from "react"

export interface NavigationContextTypes {
	mobileMenuOpen: boolean
	setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const NavigationContext = createContext<NavigationContextTypes>({
	mobileMenuOpen: false,
	setMobileMenuOpen: () => null,
})

export const NavigationContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	return (
		<NavigationContext.Provider value={{ mobileMenuOpen, setMobileMenuOpen }}>
			{children}
		</NavigationContext.Provider>
	)
}

export const useNavigationContext = () => {
	return useContext(NavigationContext)
}
