import { InvalidateQueryFilters, useMutation, useQueryClient } from "@tanstack/react-query"
import useAxios from "./useAxios"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

interface UploadFileAttachmentBody {
	attachedToId: string
	attachedToType: string
	category?: string
	file: File
	metadata?: Record<string, any>
	replaceExisting?: boolean
	fileAttachmentMetadata?: Record<string, any>
}

export const useFiles = () => {
	const { t } = useTranslation()
	const axios = useAxios()
	const queryClient = useQueryClient()

	const { mutateAsync: patchFile } = useMutation({
		mutationKey: ["patch-file"],
		mutationFn: async ({
			fileId,
			updatedData,
		}: {
			fileId: string
			updatedData: Omit<Partial<FileObject>, "id">
		}) => {
			return axios
				.patch<{ data: FileObject }>(`/venues/v1/files/${fileId}`, updatedData)
				.then(res => res.data.data)
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutateAsync: postFile } = useMutation({
		mutationKey: ["post-file"],
		mutationFn: async (fileData: Partial<Omit<FileObject, "id">> & UploadFileRequestData) => {
			return axios
				.post<{ data: UploadFileResponseData }>("/venues/v1/files", fileData)
				.then(res => res.data.data)
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutateAsync: uploadFileAttachment } = useMutation({
		mutationKey: ["upload-file"],
		mutationFn: async ({
			uploadData: {
				file,
				attachedToType,
				attachedToId,
				category,
				metadata,
				replaceExisting = false,
				fileAttachmentMetadata = {},
			},
			invalidateQueryFilters,
		}: {
			uploadData: UploadFileAttachmentBody
			invalidateQueryFilters?: InvalidateQueryFilters
		}) => {
			const uploadedFile = await postFile({
				filename: file.name,
				attachedToType: attachedToType,
				attachedToId: attachedToId,
				category: category,
				metadata: metadata,
				replaceExisting: replaceExisting,
				fileAttachmentMetadata: fileAttachmentMetadata,
			})

			await fetch(uploadedFile.presignedUrl, {
				body: file,
				method: "PUT",
				headers: { "Content-Type": file?.type || "" },
			})

			await patchFile({
				fileId: uploadedFile.id,
				updatedData: {
					disk: "s3",
				},
			})

			if (invalidateQueryFilters) {
				queryClient.invalidateQueries(invalidateQueryFilters)
			}

			return uploadedFile
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	return {
		patchFile,
		postFile,
		uploadFileAttachment,
	}
}
