import { useEffect, useMemo, useState } from "react"
import NavSelect from "../../atoms/navigation/NavSelect"
import { useTranslation } from "react-i18next"
import AddStageModal from "../../organisms/venueStage/AddStageModal"
import { useVenuesContext } from "@/context/venues"

function StageSelector({ zIndex }: { zIndex?: number }) {
	const { t } = useTranslation()
	const { selectedVenue, selectedStage, setSelectedStage, stages, userCanViewVenues } =
		useVenuesContext()
	const [newSelectedStage, setNewSelectedStage] = useState<Stage | null>(null)
	const [modalOpen, setModalOpen] = useState(false)

	const currentStages = useMemo(() => {
		if (selectedVenue) {
			return stages.filter(stage => stage.venueId === selectedVenue.id)
		}
		return stages
	}, [selectedVenue, stages])

	function handleChange(_: any, newValue: Stage | null) {
		setNewSelectedStage(newValue)
	}

	useEffect(() => {
		if (newSelectedStage !== null && newSelectedStage !== selectedStage) {
			setSelectedStage(newSelectedStage)
		}
	}, [newSelectedStage, selectedStage, setSelectedStage])

	if (!selectedStage?.id) {
		return null
	}

	if (!userCanViewVenues) {
		return null
	}

	return (
		<>
			<AddStageModal
				selectedVenueId={selectedVenue?.id || null}
				modalOpen={modalOpen}
				closeModal={() => setModalOpen(false)}
			/>
			<NavSelect
				componentName={t("selectStage")}
				selectValue={selectedStage}
				onChange={handleChange}
				optionValues={currentStages}
				addButton={{
					label: t("addNewStage"),
					onClick: () => setModalOpen(true),
				}}
				zIndex={zIndex}
			/>
		</>
	)
}

export default StageSelector
