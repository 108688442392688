import { Tune } from "@mui/icons-material"

function Production({ color = "black", size = 18 }: UpdatedIconTypes) {
	const height = size
	const width = size

	return <Tune height={height} width={width} htmlColor={color} />
}

export default Production
