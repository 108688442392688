import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import translations from "mt-locales"

export const resources = {
	...translations,
} as const

i18n
	.use(LanguageDetector) // passes i18n down to react-i18next
	.use(initReactI18next)
	.init({
		defaultNS: "common",
		fallbackLng: "en",
		fallbackNS: "common",
		initImmediate: false,
		load: "languageOnly",
		ns: ["currencies", "countries", "terms", "common", "api"],
		nsSeparator: ".",
		resources,
	})
export default i18n
