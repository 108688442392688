import styled from "styled-components"
import useUserContext from "../../hooks/useUserContext"
import mediaQuery from "../../util/mediaQuery"
import LoadingPage from "./LoadingPage"
import { Outlet } from "react-router-dom"
import { useVenuesContext } from "@/context/venues"
import { MobileHeader } from "../organisms/MobileHeader"
import { NavigationBar } from "../organisms/NavigationBar"
import { NavigationContextProvider } from "@/context/navigation"

const App = () => {
	const { isLoading } = useVenuesContext()
	const { getUserLoading } = useUserContext()

	return (
		<NavigationContextProvider>
			<LoadingPage isLoading={isLoading || getUserLoading} initialLoading={true}>
				<ContainerOrganizer>
					<MobileHeader />
					<NavigationBar />

					<MainContainer id="mainContainer">
						<Outlet />
					</MainContainer>
				</ContainerOrganizer>
			</LoadingPage>
		</NavigationContextProvider>
	)
}

const ContainerOrganizer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow-y: hidden;

	background: ${({ theme }) => theme.colorPalette.surface.bright};
	flex-direction: row;

	${mediaQuery("md")`
		flex-direction: column;
	`}
`

const MainContainer = styled.div`
	overflow-y: hidden;
	position: fixed;

	top: 0;
	bottom: 0;
	left: 288px;
	right: 0;

	padding: 0px;
	width: auto;
	height: auto;

	background: ${({ theme }) => theme.colorPalette.surface.bright};
	color: ${({ theme }) => theme.text};

	${mediaQuery("md")`
		top: 60px;
		left: 0;
	`}
`

export default App
