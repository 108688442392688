import { DefaultTheme } from "styled-components"
import { getColorTheme } from "./colorTheme"

function getTheme(isDarkMode: boolean): DefaultTheme {
	const newTheme = getColorTheme(isDarkMode)

	// Eventually all of these should be either removed or migrated to the new theme objects in newTheme.ts
	const depStyles = {
		background: isDarkMode ? "#161E36" : "#F3F3F3",
		black: "#020607",
		borderColor: isDarkMode ? "#F3F3F3" : "#161E36",
		cardColor: isDarkMode ? "#112E55" : "#FCFCFC",
		darkAccent: "#857144",
		darkGb1: "#1D2F33",
		darkGb2: "#274045",
		darkGb3: "#4A7B85",
		drawerLinkTextInactive: isDarkMode ? "#FCFCFC" : "#FCFCFC",
		drawerLinkTextActive: isDarkMode ? "#FCFCFC" : "#201F30",
		drawerLinkBackgroundActive: isDarkMode ? "#201F30" : "#FCFCFC",
		errorColor: "#FF0000",
		errorBackground: "#DDEEEE",
		errorButtonBackground: isDarkMode ? "#EE3333" : "#EE5555",
		errorButtonHover: isDarkMode ? "#EE4444" : "#EE7777",
		fontSize: "1rem",
		interactiveBorderColor: "#4285F4",
		lightAccent: "#D1AF60",
		lightAccentAlt: "#FFDD8F",
		lightGb1: "#A1B6C2",
		lightGb2: "#D5DFE6",
		lightGb3: "#EDF8FF",
		linkColor: isDarkMode ? "#4285F4" : "#4285F4",
		primaryColor: isDarkMode ? "#4285F4" : "#4285F4",
		primaryButtonColor: isDarkMode ? "#4285F4" : "#4285F4",
		primaryButtonText: isDarkMode ? "#FAFAFA" : "#FAFAFA",
		primaryButtonHover: isDarkMode ? "#6CA1F9" : "#6CA1F9",
		secondaryButtonColor: "#95B4F2",
		secondaryButtonBorderColor: "#AAAACC",
		secondaryButtonHover: "#BBBBCC",
		secondaryText: isDarkMode ? "#9ABEF9" : "#1A52AD",
		shrunkLabelFontSize: "12px",
		tertiaryBackgroundColor: "#165186",
		tertiaryTextColor: "#FCFCFC",
		text: isDarkMode ? "#FCFCFC" : "#0A0A0A",
		textNavigation: isDarkMode ? "#FCFCFC" : "#FCFCFC",
		textNavigationSelect: isDarkMode ? "#FCFCFC" : "#0A0A0A",
		textNavigationSelectBackground: isDarkMode ? "#201F30" : "#FCFCFC",
		textLight: isDarkMode ? "#777777" : "#B5B5B5",
		timeStampColor: isDarkMode ? "#F8A12F" : "#5E3603",

		fileIconColor: isDarkMode ? "" : "rgba(0,0,0,0.3)",

		white: "#FFFFFF",

		//Text sizing
		pTextSize: "12px",
		techPackPrimaryBackground: isDarkMode ? "#43474E" : "#E8E8EB",
		techPackSecondaryBackground: isDarkMode ? "#333538" : "#FFFFFF",
		techPackBorder: isDarkMode ? "#C3C6CF" : "#274045",
		techPackAccent: isDarkMode ? "#313234" : "rgba(209, 175, 96, 0.2)",

		techPackHeaderBg: isDarkMode ? "#96C4FC" : "#00589B",
		techPackHeaderText: isDarkMode ? "#313234" : "#FFFFFF",
		techPackHeaderButtonText: isDarkMode ? "#FFFFFF" : "#1A1C1E",
		techPackHeaderButtonAccent: isDarkMode ? "#004172" : "#FFDD8F",
		techPackHeaderButtonBg: isDarkMode ? "#004172" : "#CCE1FD",

		techPackSearchText: isDarkMode ? "#C3C6CF" : "#274045",

		techPackTextHeadings: isDarkMode ? "#bcbcbc" : "#1D2F33",
		techPackText: isDarkMode ? "#C3C6CF" : "#274045",

		techPackAttachment: isDarkMode ? "#313234" : "#D5DFE6",

		techPackModalBackground: isDarkMode ? "#2d2f31" : "#FFFFFF",
		techPackModalPreviewIcon: isDarkMode ? "#313234" : "#4A7B85",

		techPackHighlight: isDarkMode ? "#96C4FC" : "#00589B",
		techPackHighlightText: isDarkMode ? "#313234" : "#FFFFFF",

		techPackIcon: isDarkMode ? "#96C4FC" : "#00589B",
		techPackIconBackground: isDarkMode ? "#96C4FC" : "#00589B",
		techPackIconColor: isDarkMode ? "#313234" : "#FFFFFF",
	}

	return {
		...depStyles,
		colorPalette: newTheme,
	}
}
export default getTheme
