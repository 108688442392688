import { FileImg } from "@/components/atoms/FileImg"
import { PencilOverlay } from "@/components/atoms/PencilOverlay"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { getPreviewUrl } from "@/util/attachmentUtils"
import { getSection } from "@/util/fieldUtils"
import { styled } from "@mui/material"
import { useMemo } from "react"

interface AttachmentsPreviewProps {
	category:
		| "equipmentData"
		| "facilitiesData"
		| "localCrewData"
		| "logisticsData"
		| "productionData"
	field: string
}

export const AttachmentsPreview: React.FC<AttachmentsPreviewProps> = ({ category, field }) => {
	const { fileAttachments, setState: setAttachmentState } = useProductionAttachments()

	const sortedAttachmentList: FileAttachment[] = useMemo(
		() =>
			fileAttachments
				.filter(val => val.category === ["production", category, field].join("."))
				.sort((a, b) => (a.metadata?.order || 0) - (b.metadata?.order || 0)),
		[category, field, fileAttachments],
	)

	const openEditAttachmentModal = (newId?: string) =>
		setAttachmentState(prev => ({
			...prev,
			category: category,
			categorySection: getSection(field),
			editAttachmentModalOpen: true,
			addEditModalState: {
				...prev.addEditModalState,
				selectedField: field,
				selectedAttachmentId: newId,
			},
		}))

	return (
		sortedAttachmentList &&
		sortedAttachmentList.length > 0 && (
			<PreviewContainer>
				{sortedAttachmentList.slice(0, 3).map(val => (
					<ImgContainer onClick={() => openEditAttachmentModal(val.id)} key={val.id}>
						<PencilOverlay
							style={{
								borderRadius: "28px",
							}}
						/>
						<FileImg
							filename={val.file.filename}
							previewUrl={getPreviewUrl(val.file)}
							height="205px"
							width="190px"
						/>
					</ImgContainer>
				))}
				{sortedAttachmentList.length > 3 && (
					<AdditionalImagesContainer onClick={() => openEditAttachmentModal()}>
						{sortedAttachmentList.length - 3}+
					</AdditionalImagesContainer>
				)}
			</PreviewContainer>
		)
	)
}

const ImgContainer = styled("div")`
	position: relative;
	cursor: pointer;
	user-select: none;
`

const AdditionalImagesContainer = styled("div")`
	color: ${({ theme }) => theme.colorPalette.outline.value};
	border: 1px solid ${({ theme }) => theme.colorPalette.outline.value};
	height: 205px;
	width: 190px;
	border-radius: 28px;
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	user-select: none;

	font: 500 57px/64px Signika-Medium;
	letter-spacing: -2.5px;
`

const PreviewContainer = styled("div")`
	padding: 8px 16px;
	height: 221px;
	gap: 8px;
	display: flex;
	align-items: center;
	justify-content: start;
`
