import { forwardRef, ForwardRefRenderFunction, MouseEventHandler } from "react"
import {
	ButtonGroup as MuiButtonGroup,
	ButtonGroupProps as MuiButtonGroupProps,
} from "@mui/material"
import { css } from "styled-components"
import Button from "../atoms/NewButton"
import { Check } from "@mui/icons-material"

type ButtonGroupProps = {
	/** Id of the currently selected button */
	activeButtonId?: number
	buttonList: {
		/** Id (zero-based) of button*/
		id: number
		/** Text the button should display */
		text: string
	}[]
	/** Dispatcher that handles the onClick actions of each of the group buttons
	 * @param {number} buttonId - Id of the button clicked
	 * @param {MouseEvent} e - HTML Mouse Event for a button click
	 */
	onClickDispatch?: (
		buttonId: number,
		e: Parameters<MouseEventHandler<HTMLButtonElement>>[0],
	) => void
} & MuiButtonGroupProps

const ButtonGroup: ForwardRefRenderFunction<HTMLDivElement, ButtonGroupProps> = (
	{ activeButtonId, buttonList, onClickDispatch, ...rest },
	ref,
) => {
	return (
		<MuiButtonGroup {...rest} ref={ref}>
			{buttonList.map(button => {
				const isActive = button.id === activeButtonId

				const buttonStyles = css`
					${button.id !== 0 && "margin-left: -1px;"}

					border-radius: ${button.id === 0
						? "100px 0px 0px 100px"
						: button.id === buttonList.length - 1
						? "0px 100px 100px 0px"
						: "0px"};

					${({ theme }) =>
						isActive && `background-color: ${theme.colorPalette.secondary.container.value};`}

					transition: width 1000ms ease-in;
				`

				const stateLayerStyles = css`
					& > div {
						visibility: ${isActive ? "visible" : "hidden"};
						opacity: ${isActive ? "1" : "0"};
						transition: opacity 150ms ease-in;
						font-size: 16px;
						color: ${({ theme }) => theme.colorPalette.surface.on};
					}
				`

				return (
					<Button
						key={button.id}
						buttonType="Outline"
						borderColor="secondary"
						text={button.text}
						onClick={e => {
							return onClickDispatch ? onClickDispatch(button.id, e) : undefined
						}}
						buttonStyles={buttonStyles}
						textStyles={css`
							color: ${({ theme }) => theme.colorPalette.surface.on};
							margin-left: ${isActive ? 0 : -13.33}px;
							transition: margin 150ms ease-out;
						`}
						stateLayerStyles={stateLayerStyles}
						iconElement={<Check fontSize="inherit" />}
					/>
				)
			})}
		</MuiButtonGroup>
	)
}

export default forwardRef(ButtonGroup)
