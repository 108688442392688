import { styled, Chip as MuiChip, ChipProps, SvgIcon } from "@mui/material"

export const Chip: React.FC<ChipProps> = props => {
	return (
		<StyledChip
			{...props}
			data-cy-component="Component-Chip"
			deleteIcon={
				<SvgIcon data-cy-component="Chip-Delete-SVG">
					<svg viewBox="0 0 24 24" fill="currentColor">
						<path
							data-cy-component="Chip-Delete-Cancel"
							className="cancelIcon"
							d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"
						></path>
						<path
							data-cy-component="Chip-Delete-Close"
							className="closeIcon"
							d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
						></path>
					</svg>
				</SvgIcon>
			}
		/>
	)
}

const StyledChip = styled(MuiChip)`
	&.MuiChip-root {
		cursor: default;
		height: 36px;
		padding: 8px 16px;
		border-radius: 24px;
		gap: 8px;
		background-color: ${({ theme }) => theme.colorPalette.primary.fixed.dim};
		border: none;
	}

	& > .MuiChip-label {
		font-family: Signika-Regular;
		font-size: 14px;
		font-weight: 400;
		color: ${({ theme }) => theme.colorPalette.primary.fixed.on};
		padding: 0px;
	}

	& svg.MuiSvgIcon-root.MuiChip-deleteIcon {
		color: ${({ theme }) => theme.colorPalette.primary.fixed.on};
		width: 16px;
		height: 16px;
		margin: 0px;

		.closeIcon,
		.cancelIcon {
			transition: visibility 300ms ease-out;
			transition: opacity 300ms ease-out;
		}

		.closeIcon {
			visibility: hidden;
			opacity: 0;
		}

		.cancelIcon {
			visibility: visible;
			opacity: 1;
		}

		&:hover {
			.closeIcon {
				visibility: visible;
				opacity: 1;
			}

			.cancelIcon {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
`
