import { createContext, useContext, useEffect, useState } from "react"
import ensureVarIsArray from "../util/ensureVarIsArray"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import useAxios from "../hooks/useAxios"
import { useNavigate } from "react-router-dom"
import useUserContext from "@/hooks/useUserContext"

const DEFAULT_VENUE_PERMISSIONS: VenuePermissions = {
	userId: "",
	staffCrew: {
		update: [],
		view: [],
	},
}

export const VenueContext = createContext<VenuesContextTypes>({
	addStage: () => null,
	addStagePending: false,
	patchStage: () => null,
	patchStagePending: false,
	patchVenue: () => null,
	patchVenuePending: false,
	isLoading: false,
	selectedStage: null,
	setSelectedStage: () => null,
	selectedVenue: null,
	setSelectedVenue: () => null,
	stages: [],
	venues: [],
	userCanViewVenues: true,
	venuePermissions: DEFAULT_VENUE_PERMISSIONS,
})

const LOCAL_STORAGE_SELECTED_VENUE_KEY = "mv-selected-venue"
const LOCAL_STORAGE_SELECTED_STAGE_KEY = "mv-selected-stage"

export const VenueContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null)
	const [selectedStage, setSelectedStage] = useState<Stage | null>(null)
	const { user } = useUserContext()
	const { token } = localStorage
	const axios = useAxios()
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { mutate: handleLogout } = useMutation({
		mutationFn: async () => {
			return axios.get("/venues/v1/logout")
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
		onSuccess: () => {
			localStorage.token = ""
			queryClient.invalidateQueries()
			navigate("/login")
		},
	})

	const { mutate: addStage, isPending: addStagePending } = useMutation({
		mutationKey: ["addStage"],
		mutationFn: async (
			newStageData: Pick<
				Stage,
				"venueId" | "name" | "isMainStage" | "venueStageTypeId" | "capacity"
			>,
		) => {
			let newSlug = newStageData.name
				.replaceAll(" ", "-")
				.split("")
				.filter(c => /^[a-zA-Z0-9_-]*$/.test(c))
				.join("")
				.toLowerCase()

			const conflictingSlug = stages.find(stage => {
				const customSlugUrl = stage.publicUrls.customSlugUrl?.split("/").pop()
				return stage.venueId === newStageData.venueId && customSlugUrl === newSlug
			})

			const resData = await axios
				.post<{ data: Stage }>("/venues/v1/venue-stages", {
					...newStageData,
					slug: conflictingSlug ? undefined : newSlug,
				})
				.then(async data => {
					return data.data.data
				})
			toast.success(t("addStageSuccess"))

			return resData
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				predicate: query =>
					query.queryKey[0] === "venues" ||
					query.queryKey[0] === "data-logs" ||
					query.queryKey[0] === "stages",
			})
			toast.success(t("addStageSuccess"))
		},
	})

	const { mutate: patchStage, isPending: patchStagePending } = useMutation({
		mutationKey: ["patchStage"],
		mutationFn: async ({
			stageId,
			updateData,
		}: {
			stageId: string
			updateData: Partial<Stage>
		}) => {
			const resData = axios.patch<{ data: Stage }>(`/venues/v1/venue-stages/${stageId}`, updateData)
			return resData
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				predicate: query =>
					query.queryKey[0] === "venues" ||
					query.queryKey[0] === "data-logs" ||
					query.queryKey[0] === "stages",
			})
			toast.success(t("completed"))
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutate: patchVenue, isPending: patchVenuePending } = useMutation({
		mutationKey: ["patchVenue"],
		mutationFn: async ({
			venueId,
			updateData,
		}: {
			venueId: string
			updateData: Partial<Omit<Venue, "id">>
		}) =>
			axios
				.patch<{ data: Venue }>(`/venues/v1/venues/${venueId}`, updateData)
				.then(res => res.data.data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				predicate: query =>
					query.queryKey[0] === "venues" ||
					query.queryKey[0] === "data-logs" ||
					query.queryKey[0] === "stages",
			})
			toast.success(t("completed"))
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	// Refetch when logging in to make sure token is present
	const {
		data: venues = [],
		isPending: venuesPending,
		dataUpdatedAt: venuesUpdatedAt,
	} = useQuery({
		queryKey: [
			"venues",
			{
				token: token,
			},
		],
		queryFn: async () => {
			return axios.get<{ data: Venue[] }>("/venues/v1/venues").then(
				res =>
					res.data.data
						.map((v: Venue) => ({
							...v,
							stages: v.stages.sort((a: Stage, b: Stage) =>
								a.name.toString().localeCompare(b.name.toString()),
							),
							contacts: ensureVarIsArray(v.contacts),
							previousNames: ensureVarIsArray(v.previousNames),
							urls: ensureVarIsArray(v.urls),
							notes: ensureVarIsArray(v.notes),
						}))
						.sort((a: Venue, b: Venue) =>
							a.name.toString().localeCompare(b.name.toString()),
						) as Venue[],
			)
		},
		enabled: !!token,
		retry: false,
	})

	const { data: venuePermissions = DEFAULT_VENUE_PERMISSIONS } = useQuery({
		queryKey: [
			"current-user",
			{
				userId: user.userId,
				venueId: selectedVenue?.id || "",
			},
		],
		queryFn: async () => {
			const userData = await axios
				.get<{ data: StaffCrewRow }>(
					`/venues/v1/venues/${selectedVenue?.id}/staff-crew/${user.userId}`,
				)
				.then(res => res.data.data)

			const permissionLevel = userData.roleName

			if (permissionLevel === "Venue Admin") {
				return {
					userId: userData.userId,
					staffCrew: {
						update: ["venueManagers", "venueStaff", "venueAdmins"],
						view: ["venueAdmins", "venueManagers", "venueStaff"],
					},
				} as VenuePermissions
			}

			if (permissionLevel === "Venue Manager") {
				return {
					userId: userData.userId,
					staffCrew: {
						update: ["venueStaff"],
						view: ["venueAdmins", "venueManagers", "venueStaff"],
					},
				} as VenuePermissions
			}
			return DEFAULT_VENUE_PERMISSIONS
		},
		enabled: !!token && user.userId !== "" && selectedVenue !== null,
		meta: {
			errorMessage: t("errorTryAgain"),
		},
		placeholderData: DEFAULT_VENUE_PERMISSIONS,
		retry: false,
	})

	useEffect(() => {
		if (!venues.length) {
			return
		}

		//check for a stored selected venue + stage id
		const previouslySelectedVenueID = localStorage.getItem(LOCAL_STORAGE_SELECTED_VENUE_KEY)
		const previouslySelectedStageID = localStorage.getItem(LOCAL_STORAGE_SELECTED_STAGE_KEY)

		if (previouslySelectedVenueID && previouslySelectedStageID) {
			const newSelectedVenue = venues.find(v => v.id === previouslySelectedVenueID)

			if (newSelectedVenue) {
				const newSelectedStage = newSelectedVenue?.stages.find(
					s => s.id === previouslySelectedStageID,
				)

				if (newSelectedStage) {
					setSelectedVenue(newSelectedVenue)
					setSelectedStage(newSelectedStage)
					return
				}
			}
		}

		//or use first available venue + stage if available
		const firstVenue = venues[0]
		if (firstVenue.id && firstVenue.stages?.length > 0) {
			setSelectedVenue(firstVenue)
			setSelectedStage(firstVenue.stages[0])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venuesUpdatedAt])

	const stages = venues.reduce((acc, venue) => {
		acc.push(...venue.stages)
		return acc
	}, [] as Stage[])

	//temporary workaround while we await user.permissions
	//might make sense to leave here and set to something like userCanViewVenues = user.permissions.venues?.includes("view")
	const userCanViewVenues = true

	//if a user lands on this page with no associated venues then send them home
	useEffect(() => {
		if (
			!venuesPending &&
			!userCanViewVenues &&
			["/staff-crew", "/production", "/venueStage"].some(page =>
				window.location.href.includes(page),
			)
		) {
			handleLogout()
		}
	}, [venuesPending, userCanViewVenues, navigate, handleLogout])

	useEffect(() => {
		if (selectedStage) {
			localStorage.setItem(LOCAL_STORAGE_SELECTED_VENUE_KEY, selectedVenue?.id || "")
			localStorage.setItem(LOCAL_STORAGE_SELECTED_STAGE_KEY, selectedStage.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStage])

	return (
		<VenueContext.Provider
			value={{
				addStage,
				addStagePending,
				patchStage,
				patchStagePending,
				patchVenue: patchVenue,
				patchVenuePending,
				isLoading: venuesPending,
				selectedStage,
				selectedVenue,
				setSelectedStage,
				setSelectedVenue,
				stages,
				venues,
				userCanViewVenues,
				venuePermissions,
			}}
		>
			{children}
		</VenueContext.Provider>
	)
}

export const useVenuesContext = () => {
	return useContext(VenueContext)
}
