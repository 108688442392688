import FormField from "@atoms/FormField"
import { FormEventHandler, useCallback, useEffect, useState } from "react"
import NewModal from "@molecules/NewModal"
import { styled } from "@mui/material"
import { Add, ImportContactsTwoTone } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import Stack from "@mui/material/Stack"
import { useVenuesContext } from "@/context/venues"
import { useProductionContext } from "@/context/production"

const AddVenueContactModal = () => {
	const { t } = useTranslation()
	const { selectedVenue } = useVenuesContext()
	const {
		state: { addContactModalOpen },
		setState,
		updateVenueContacts,
	} = useProductionContext()

	const [invalidLabel, setInvalidLabel] = useState(false)
	const [{ label, phone, email }, setContact] = useState<VenueContact>({
		label: "",
		phone: "",
		email: "",
	})

	const updateContact = useCallback(
		(key: keyof VenueContact, newValue: string) => {
			setContact(prev => ({
				...prev,
				[key]: newValue,
			}))
		},
		[setContact],
	)

	const handleSubmit: FormEventHandler = useCallback(
		e => {
			e.preventDefault()

			if (selectedVenue === null) {
				setState(prev => ({
					...prev,
					addContactModalOpen: false,
				}))
				return
			}

			updateVenueContacts({
				translationKey: "contactAdded",
				contacts: [
					...selectedVenue.contacts,
					{
						label,
						phone,
						email,
					},
				],
			})

			setState(prev => ({ ...prev, addContactModalOpen: false }))
		},
		[updateVenueContacts, setState, selectedVenue, label, phone, email],
	)

	useEffect(() => {
		if (!addContactModalOpen) {
			setContact({
				label: "",
				phone: "",
				email: "",
			})
			setInvalidLabel(false)
		}
	}, [addContactModalOpen, setContact, setInvalidLabel])

	return (
		<NewModal
			size="sm"
			open={addContactModalOpen}
			closeModal={() => setState(prev => ({ ...prev, addContactModalOpen: false }))}
			title={t("addContactInfo")}
			hint={t("requiredField_plural")}
			footerActions={{
				end: [
					"Cancel",
					{
						text: t("addContactInfo"),
						buttonType: "Outline",
						iconElement: <Add />,
						type: "submit",
						form: "add-venue-contact-form",
					},
				],
			}}
			titleIcon={<OutlineContactIcon />}
		>
			<StyledStack
				id="add-venue-contact-form"
				onSubmit={handleSubmit}
				component="form"
				onInvalid={() => setInvalidLabel(true)}
			>
				<FormField
					required
					label={t("contactLabel")}
					value={label}
					onChange={e => updateContact("label", e.target.value)}
					fullWidth
					data-cy="production-VenueContact-Modal-Label-Field"
					error={invalidLabel}
				/>
				<FormField
					fullWidth
					label={t("phoneNumber")}
					value={phone}
					onChange={e => updateContact("phone", e.target.value)}
					data-cy="production-VenueContact-Modal-Phone-Field"
				/>
				<FormField
					label={t("emailAddress")}
					fullWidth
					value={email}
					onChange={e => updateContact("email", e.target.value)}
					data-cy="production-VenueContact-Modal-Email-Field"
				/>
			</StyledStack>
		</NewModal>
	)
}

const OutlineContactIcon = styled(ImportContactsTwoTone)`
	color: inherit;
	& path:last-child {
		opacity: 0;
	}
`

const StyledStack = styled(Stack)<{ component: string }>`
	& {
		row-gap: 16px;
		padding-top: 8px;
	}
`

export default AddVenueContactModal
