import React, { forwardRef, ForwardRefRenderFunction, useState } from "react"
import styled, { css } from "styled-components"
import NewButton from "../atoms/NewButton"
import { useTranslation } from "react-i18next"

type PermalinkProps = {
	baseLink: string
	editText: string
	setEditText: (newValue: string) => void
	maxLength?: number
	/** Determines if and how the user's input should be tested
	 * "RemoveOnSave" (Default) - Automatically removes invalid characters when user presses save
	 * "PreventSave" - Deactivates save button when invalid characters are present in input
	 * "PreventInput" - Prevents user from inputting invalid characters
	 * "None" - Permalink does not test the user's input at all
	 */
	validateInput?: "RemoveOnSave" | "PreventSave" | "PreventInput" | "None"
}

const Permalink: ForwardRefRenderFunction<HTMLDivElement, PermalinkProps> = (
	{ baseLink, editText, setEditText, maxLength, validateInput },
	ref,
) => {
	const { t } = useTranslation()
	const [editMode, setEditMode] = useState(false)
	const [newLink, setNewLink] = useState(editText)

	const filterUrlChars = (testString: string) => {
		return testString
			.split("")
			.filter(c => /^[a-zA-Z0-9_-]*$/.test(c))
			.join("")
			.toLowerCase()
	}

	const saveEditOnClick = () => {
		if (editMode) {
			if (validateInput === undefined || validateInput === "RemoveOnSave") {
				const newUrl = filterUrlChars(newLink.replaceAll(" ", "-"))
				setEditText(newUrl)
			} else {
				setEditText(newLink)
			}

			setEditMode(false)
		} else {
			setNewLink(editText)
			setEditMode(true)
		}
	}

	const cancelCopyOnClick = () => {
		if (editMode) {
			setNewLink(editText)
			setEditMode(false)
		} else {
			navigator.clipboard.writeText(baseLink + "/" + editText)
		}
	}

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.code) {
			case "Enter":
				if (newLink.length > 0) {
					if (validateInput === undefined || validateInput === "RemoveOnSave") {
						const newUrl = filterUrlChars(newLink.replaceAll(" ", "-"))
						setEditText(newUrl)
					} else {
						setEditText(newLink)
					}
				}
				setEditMode(false)
				break
			case "Escape":
			case "Tab":
				setEditMode(false)
		}
	}

	return (
		<PermalinkContainer ref={ref}>
			<StyledPermalink>
				<span className="permalink-caption">{`${t("permalink")}: `}</span>
				<a
					href={`${baseLink}/${editText}`}
					aria-disabled={editMode}
					target="_blank"
					rel="noreferrer"
				>
					{baseLink}/{!editMode && editText}
				</a>
				{editMode && (
					<EditableInput
						autoFocus
						size={newLink.length > 1 ? newLink.length : 1}
						onChange={e =>
							setNewLink(
								validateInput === "PreventInput" ? filterUrlChars(e.target.value) : e.target.value,
							)
						}
						value={newLink}
						spellCheck="false"
						maxLength={maxLength}
						onKeyDown={inputOnKeyDown}
					/>
				)}
			</StyledPermalink>
			<NewButton
				text={editMode ? t("save") : t("edit")}
				buttonType="Outline"
				buttonStyles={css`
					height: 28px;
					width: 57px;
				`}
				onClick={saveEditOnClick}
				disabled={
					editMode &&
					(newLink.length === 0 ||
						(validateInput === "PreventSave" && !/^[a-zA-Z0-9_-]*$/.test(newLink)))
				}
			/>
			<NewButton
				text={editMode ? t("cancel") : t("copy")}
				buttonType={editMode ? "Text" : "Outline"}
				buttonStyles={css`
					height: 28px;
					width: 63px;
				`}
				onClick={cancelCopyOnClick}
			/>
		</PermalinkContainer>
	)
}

const EditableInput = styled("input")`
	font: 400 14px/20px Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.primary.value};

	&:focus {
		border-color: ${({ theme }) => theme.colorPalette.primary.value};
	}
`

const StyledPermalink = styled("span")`
	.permalink-caption {
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font: 600 14px/20px Signika-Semibold;
	}
	& > a {
		font: 400 14px/20px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.primary.value};
		&[aria-disabled="true"] {
			pointer-events: none;
			cursor: default;
		}

		& > span {
			font: inherit;
		}
	}
`

const PermalinkContainer = styled("div")`
	display: flex;
	align-items: center;
	gap: 8px;
	height: 28px;

	letter-spacing: normal;
`

export default forwardRef(Permalink)
