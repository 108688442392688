import { FC, useRef, useState, useEffect } from "react"
import styled, { useTheme } from "styled-components"
import { attachmentDisplayName } from "../../../util/attachmentUtils"
import useTechPack from "../../../hooks/useTechPack"
import PdfFile from "../../../icons/PdfFile"
import VideoFile from "../../../icons/VideoFile"
import ImageFile from "../../../icons/ImageFile"
import File from "../../../icons/File"

export type TechPackParams = {
	category: keyof Stage
	field: string
}

const AttachmentRow: FC<TechPackParams> = ({ category, field }) => {
	const theme = useTheme()
	const containerRef = useRef<HTMLDivElement>(null)
	const [visibleCount, setVisibleCount] = useState(0)
	const { attachments: stateAttachments, setState } = useTechPack()

	const attachments: FileAttachment[] = stateAttachments[category]?.[field] || []

	const updateVisibleCount = () => {
		if (containerRef.current) {
			const containerWidth = containerRef.current.offsetWidth
			const itemWidth = 135
			const count = Math.floor(containerWidth / itemWidth)
			setVisibleCount(count - 1)
		}
	}

	useEffect(() => {
		// ensure the component has rendered ahead of measuring the visible area for attachment display
		const timeoutRender = setTimeout(() => {
			updateVisibleCount()
		}, 500)

		updateVisibleCount()
		window.addEventListener("resize", updateVisibleCount)

		return () => {
			clearTimeout(timeoutRender)
			window.removeEventListener("resize", updateVisibleCount)
		}
	}, [])

	if (attachments.length === 0) {
		return null
	}

	const getFileIcon = (item: FileObject) => {
		const ext = item.filename?.split(".").pop() || ""
		switch (ext) {
			case "pdf":
				return <PdfFile size={32} color={theme.techPackIconColor} />
			case "jpg":
			case "jpeg":
			case "png":
			case "gif":
			case "svg":
			case "webp":
			case "avif":
				return <ImageFile size={32} color={theme.techPackIconColor} />
			case "mp4":
			case "mov":
			case "avi":
			case "wmv":
			case "flv":
			case "mkv":
			case "webm":
				return <VideoFile size={32} color={theme.techPackIconColor} />
			default:
				return <File size={32} color={theme.techPackIconColor} />
		}
	}

	function adaptToFileType(file: any): FileObject {
		return {
			id: file.id || "",
			filename: file.filename || "",
			filesize: file.filesize || 0,
			createdDate: file.createdDate || "",
			metadata: file.metadata || {},
			folderPath: file.folderPath || "",
			disk: file.disk || "",
			createdBy: file.createdBy || "",
			modifiedDate: file.modifiedDate || "",
			modifiedBy: file.modifiedBy || "",
			isDeleted: file.isDeleted || 0,
			status: file.status || {
				upload: "Waiting For Action",
			},
			publicUrls: file.publicUrls || {},
		}
	}

	const handlePlusMoreClick = () => {
		// Calculate the index of the first hidden attachment
		const firstHiddenIndex = visibleCount < attachments.length ? visibleCount : 0

		// Open the modal and set the selected attachment to be the first hidden by the available space
		setState(s => ({
			...s,
			modalOpen: true,
			selectedAttachment: {
				category,
				field,
				selectedIndex: firstHiddenIndex,
			},
		}))
	}

	return (
		<StyledContainer ref={containerRef}>
			{attachments
				.sort((a, b) => (a.metadata?.order || 0) - (b.metadata?.order || 0))
				.slice(0, visibleCount)
				.map((item, index) => {
					if (!item.file) return null
					const adaptedFile = adaptToFileType(item.file)
					const displayName = attachmentDisplayName(adaptedFile, false)
					const previewImage =
						item.file.publicUrls?.streaming?.thumbNailsUrls?.[0] || item.file.publicUrls?.urlPreview
					return (
						<RowContainer
							key={`${item.fileId}`}
							title={displayName}
							onClick={() => {
								setState(s => ({
									...s,
									selectedAttachment: {
										category,
										field,
										selectedIndex: index,
									},
								}))
							}}
						>
							{previewImage ? (
								<ResponsiveImage src={previewImage} alt={displayName} />
							) : (
								<StyledIconContainer>{getFileIcon(adaptedFile)}</StyledIconContainer>
							)}
						</RowContainer>
					)
				})}
			{attachments.length > visibleCount && (
				<RowContainer
					title={`${attachments.length - visibleCount} more`}
					onClick={handlePlusMoreClick}
				>
					<PlusMoreContainer>{attachments.length - visibleCount}+</PlusMoreContainer>
				</RowContainer>
			)}
		</StyledContainer>
	)
}

const StyledContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const RowContainer = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	margin-right: 2rem;
	margin-top: 0.5rem;
`

const ResponsiveImage = styled.img<{ width?: string }>`
	height: auto;
	max-width: ${({ width }) => (width ? width : "100px")};
	width: 100%;
`

const StyledIconContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackIconBackground};
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 100px;
`
const PlusMoreContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackIconColor};
	border: 2px solid ${({ theme }) => theme.techPackIconBackground};
	color: ${({ theme }) => theme.techPackIconBackground};
	font-family: "Signika-Bold", important;
	font-size: 32px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 100px;
`

export default AttachmentRow
