export const USER_TITLE_KEYS = [
	"owner",
	"productionManager",
	"venueCoordinator",
	"hospitalityCoordinator",
	"stageManager",
	"security",
	"talentBuyer",
	"audio",
	"lighting",
	"boxOffice",
	"barManager",
]

export const USER_TITLE_EN_VALUES = [
	"Owner",
	"Production Manager",
	"Venue Coordinator",
	"Hospitality Coordinator",
	"Stage Manager",
	"Security",
	"Talent Buyer",
	"Audio",
	"Lighting",
	"Box Office",
	"Bar Manager",
]
