import { FormControlLabelProps } from "@mui/material/FormControlLabel"
import { styled } from "@mui/material"

const FormControlLabel: React.FC<Pick<FormControlLabelProps, "control" | "label" | "disabled">> = ({
	control,
	label,
	disabled,
}) => {
	return (
		<LabelContainer data-cy-component="FormControlLabel_Component">
			{control}
			<LabelText disabled={disabled} data-cy-component="FormControlLabelText_Component">
				{label}
			</LabelText>
		</LabelContainer>
	)
}

const LabelContainer = styled("div")`
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 0px;
`

const LabelText = styled("label", {
	shouldForwardProp: prop => prop !== "disabled",
})<{ disabled?: boolean }>`
	font: 600 14px Signika-Semibold;
	user-select: none;
	pointer-events: none;
	cursor: default;
	margin-left: 0;
	letter-spacing: 0.15px;

	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	opacity: ${({ disabled }) => (disabled ? 0.38 : 1)};
`

export default FormControlLabel
