import { useButton } from "@mui/base/useButton"
import React, { ForwardedRef, forwardRef } from "react"
import styled, { css, useTheme } from "styled-components"
import { ButtonProps as MuiButtonProps } from "@mui/base/Button"

type ButtonTypes = "Filled" | "Outline" | "Text" | "Elevated" | "Tonal"

export interface ButtonProps<ButtonType extends ButtonTypes> extends MuiButtonProps {
	/** Sets the visual typing of the button */
	buttonType: ButtonType

	/** Text displayed by the button */
	text: string

	/** Only availible on Outline button type, can be set to either primary (default) or secondary */
	borderColor?: ButtonType extends "Outline" ? "primary" | "secondary" : undefined

	/** An icon to be displayed to the left of the button text */
	iconElement?: React.ReactNode

	/** Overrides for the button component styles using styled-components css*/
	buttonStyles?: ReturnType<typeof css>

	/** Overrides for the state layer component styles (change padding/spacing here) styled-components css */
	stateLayerStyles?: ReturnType<typeof css>

	/** Overrides for the text component styles styled-components css */
	textStyles?: ReturnType<typeof css>

	"data-cy"?: string
}

const NewButton = forwardRef(function NewButton<ButtonType extends ButtonTypes>(
	props: ButtonProps<ButtonType>,
	ref: ForwardedRef<any>,
) {
	const { buttonType, text, iconElement, borderColor, form, id, ...rest } = props
	const { getRootProps } = useButton({
		...rest,
		rootRef: ref,
	})
	const { colorPalette } = useTheme()

	const stateLayerColor =
		buttonType === "Filled"
			? colorPalette.stateLayers.primary.on
			: buttonType === "Tonal"
			? colorPalette.stateLayers.secondary.container.on
			: colorPalette.stateLayers.primary.value

	const stateLayerDisabled =
		buttonType === "Outline" || buttonType === "Text"
			? undefined
			: colorPalette.stateLayers.surface.on

	return (
		<BaseButton
			{...getRootProps()}
			disabled={props.disabled}
			buttonType={buttonType}
			borderColor={borderColor === "secondary" ? colorPalette.outline.value : undefined}
			btnStyles={rest.buttonStyles}
			form={form}
			id={id}
			data-cy={props["data-cy"]}
		>
			<StateLayer
				className="stateLayer"
				isText={buttonType === "Text"}
				hasIcon={iconElement !== undefined}
				baseColor={stateLayerColor}
				deactivatedColor={stateLayerDisabled}
				stateStyles={rest.stateLayerStyles}
			>
				{iconElement && <SVGContainer>{iconElement}</SVGContainer>}
				<BaseText textStyles={rest.textStyles}>{text}</BaseText>
			</StateLayer>
		</BaseButton>
	)
})

const BaseButton = styled.button<{
	buttonType: ButtonTypes
	borderColor?: string
	btnStyles?: ReturnType<typeof css>
}>`
	cursor: pointer;
	user-select: none;

	height: 40px;
	border: none;
	border-radius: 100px;

	&:disabled {
		cursor: default;

		& svg {
			opacity: 38%;
		}
	}

	transition: all 150ms ease-out;

	${({ buttonType }) => {
		switch (buttonType) {
			case "Elevated":
				return ElevatedMixin
			case "Filled":
				return FilledMixin
			case "Outline":
				return OutlineMixin
			case "Text":
				return TextMixin
			case "Tonal":
				return TonalMixin
		}
	}}

	${({ btnStyles }) => btnStyles || ""}
`

const SVGContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > svg {
		font-size: 18px;
		color: inherit;
	}
`

const BaseText = styled.span<{ textStyles?: ReturnType<typeof css> }>`
	font: 500 14px/20px Signika-Regular;
	letter-spacing: 0.1px;

	text-align: center;

	${BaseButton}:disabled & {
		opacity: 38%;
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}

	${({ textStyles }) => textStyles || ""}
`

const OutlineMixin = css<{ borderColor?: string }>`
	border-width: 1px;
	border-style: solid;
	border-color: ${({ theme, borderColor }) => borderColor || theme.colorPalette.primary.value};
	color: ${({ theme }) => theme.colorPalette.primary.value};

	background: none;

	&:hover {
		border-color: ${({ theme, borderColor }) => borderColor || theme.colorPalette.outline};
	}

	&:active {
		border-color: ${({ theme, borderColor }) => borderColor || theme.colorPalette.outline};
	}

	&:disabled {
		border-color: ${({ theme }) => theme.colorPalette.surface.on}1F;
		background: none;
		color: ${({ theme }) => theme.colorPalette.surface.on}1F;

		& .MuiSvgIcon-root {
			opacity: 38%;
			color: ${({ theme }) => theme.colorPalette.surface.on};
		}
	}
`

const FilledMixin = css`
	color: ${({ theme }) => theme.colorPalette.primary.on};
	background-color: ${({ theme }) => theme.colorPalette.primary.value};
	box-shadow: none;

	&:hover {
		box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
	}

	&:active {
		box-shadow: none;
	}

	&:disabled {
		background: none;
	}
`

const TextMixin = css`
	color: ${({ theme }) => theme.colorPalette.primary.value};
	background: none;
`

const ElevatedMixin = css`
	color: ${({ theme }) => theme.colorPalette.primary.value};
	box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
	background-color: ${({ theme }) => theme.colorPalette.surface.container.low};

	&:hover {
		box-shadow: 0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004d;
	}

	&:active {
		box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
	}

	&:disabled {
		box-shadow: none;
		background: none;
	}
`

const TonalMixin = css`
	background-color: ${({ theme }) => theme.colorPalette.secondary.container.value};
	color: ${({ theme }) => theme.colorPalette.secondary.container.on};
	box-shadow: none;

	&:hover {
		box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
	}

	&:active {
		box-shadow: none;
	}

	&:disabled {
		background: none;
	}
`

const StateLayer = styled.div<{
	baseColor: string
	isText: boolean
	hasIcon: boolean
	deactivatedColor?: string
	stateStyles?: ReturnType<typeof css>
}>`
	height: 100%;
	width: 100%;
	padding: ${({ isText, hasIcon }) => {
		if (isText) {
			if (hasIcon) {
				return "10px 16px 10px 12px"
			}
			return "10px 12px 10px 12px"
		}

		if (hasIcon) {
			return "10px 24px 10px 16px"
		}
		return "10px 24px 10px 24px"
	}};
	color: inherit;

	border-radius: inherit;
	margin: 0;
	text-align: center;
	background: none;

	gap: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	${BaseButton}:hover && {
		background-color: ${({ baseColor }) => baseColor}14;
	}

	${BaseButton}:active && {
		background-color: ${({ baseColor }) => baseColor}1F;
	}

	${BaseButton}:disabled && {
		${({ deactivatedColor }) => {
			if (deactivatedColor === undefined) {
				return css`
					background: none;
				`
			} else {
				return css`
					background-color: ${deactivatedColor}1F;
				`
			}
		}};
	}

	transition: all 150ms ease-out;

	${({ stateStyles }) => stateStyles || ""}
`

export default NewButton
