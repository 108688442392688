import { styled } from "@mui/material"
import { useTranslation } from "react-i18next"

export const LoginFooter: React.FC<{ isReset: boolean; updatePage: () => void }> = ({
	isReset,
	updatePage,
}) => {
	const { t } = useTranslation()

	return (
		<Footer>
			<LinksContainer>
				<StyledLink onClick={updatePage} target="_blank" data-cy="reset-password-link" tabIndex={0}>
					{isReset ? t("returnToLogIn") : t("resetPassword")}
				</StyledLink>
				{!isReset && (
					<StyledLink href="https://www.eventric.com/privacy-policy/" target="_blank">
						{t("privacyPolicy")}
					</StyledLink>
				)}
			</LinksContainer>
		</Footer>
	)
}

const Footer = styled("div")`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: start;
	gap: 40px;
`

const LinksContainer = styled("div")`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
`

const StyledLink = styled("a")`
	color: ${({ theme }) => theme.colorPalette.primary.value};
	font: 600 16px/24px Signika-Semibold;
	text-decoration: none;
	text-align: center;

	&:visited {
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}

	cursor: pointer;
	user-select: none;
`
