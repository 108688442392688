import { useEffect, useState } from "react"
import { ScaleLoader } from "react-spinners"
import { Backdrop, styled } from "@mui/material"
import { useTheme } from "styled-components"
import { createPortal } from "react-dom"
import tinycolor from "tinycolor2"

interface LoaderOverlayProps {
	isLoading: boolean
	initialLoading?: boolean
}

const LoaderOverlay: React.FC<LoaderOverlayProps> = ({ isLoading, initialLoading = true }) => {
	const [overrideLoadingState, setOverrideLoadingState] = useState(initialLoading)
	const { colorPalette } = useTheme()

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout> | null = null

		if (!isLoading && overrideLoadingState) {
			timer = setTimeout(() => setOverrideLoadingState(false), 500)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [isLoading, overrideLoadingState])

	return createPortal(
		<StyledOverlay open={isLoading || overrideLoadingState} id="loaderOverlayStuff">
			<ScaleLoader color={colorPalette.primary.value} />
		</StyledOverlay>,
		document.body,
	)
}

const StyledOverlay = styled(Backdrop)`
	z-index: 9999;
	background-color: ${({ theme }) =>
		tinycolor(theme.colorPalette.scrim).setAlpha(0.15).toRgbString()};
`

export default LoaderOverlay
