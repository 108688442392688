import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import useAxios from "./useAxios"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useFiles } from "./useFiles"

export const useAttachments = (objectType: string, objectId: string) => {
	const { t } = useTranslation()
	const axios = useAxios()
	const queryClient = useQueryClient()
	const { uploadFileAttachment } = useFiles()

	const { data: fileAttachments, isPending: queryIsPending } = useQuery({
		queryKey: [
			"file-attachments",
			{
				attachedToType: objectType,
				attachedToId: objectId,
			},
		],
		queryFn: async () => {
			return axios
				.post<{ data: FileAttachment[] }>(
					`/venues/v1/attachment/object/${objectType}/${objectId}/fileAttachments`,
					{
						includeUrls: true,
					},
				)
				.then(res => res.data.data)
		},
		meta: {
			errorMessage: t("errorTryAgain"),
		},
		enabled: objectId !== "",
	})

	const { mutateAsync: updateAttachment, isPending: updateIsPending } = useMutation({
		mutationKey: [
			"update-attachment",
			{
				attachedToType: objectType,
				attachedToId: objectId,
			},
		],
		mutationFn: async (params: { id: string; body: Omit<Partial<FileAttachment>, "id"> }) => {
			return axios
				.patch<{ data: FileAttachment }>(`/venues/v1/file-attachments/${params.id}`, params.body)
				.then(res => res.data.data)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"file-attachments",
					{
						attachedToType: objectType,
						attachedToId: objectId,
					},
				],
			})
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutateAsync: bulkUpdateAttachments, isPending: bulkUpdateIsPending } = useMutation({
		mutationKey: [
			"bulk-update-attachments",
			{
				attachedToType: objectType,
				attachedToId: objectId,
			},
		],
		mutationFn: async (
			updateData: (Omit<Partial<FileAttachment>, "file"> & {
				id: string
				file?: Partial<FileObject>
			})[],
		) => {
			return axios
				.post<{ data: FileAttachment[] }>(`/venues/v1/file-attachments/update`, updateData)
				.then(res => res.data.data)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"file-attachments",
					{
						attachedToType: objectType,
						attachedToId: objectId,
					},
				],
			})
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutateAsync: uploadAttachment, isPending: uploadIsPending } = useMutation({
		mutationKey: [
			"upload-attachment",
			{
				attachedToType: objectType,
				attachedToId: objectId,
			},
		],
		mutationFn: async (fileData: {
			category?: string
			file: File
			metadata?: Record<string, any>
			replaceExisting?: boolean
			fileAttachmentMetadata?: Record<string, any>
		}) => {
			return uploadFileAttachment({
				uploadData: {
					attachedToId: objectId,
					attachedToType: objectType,
					...fileData,
				},
				invalidateQueryFilters: {
					queryKey: [
						"file-attachments",
						{
							attachedToType: objectType,
							attachedToId: objectId,
						},
					],
				},
			})
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"file-attachments",
					{
						attachedToType: objectType,
						attachedToId: objectId,
					},
				],
			})
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutateAsync: deleteAttachment, isPending: deleteIsPending } = useMutation({
		mutationKey: [
			"delete-attachment",
			{
				attachedToType: objectType,
				attachedToId: objectId,
			},
		],
		mutationFn: async (attachmentId: string) => {
			return axios.delete<{ data: string }>(`/venues/v1/file-attachments/${attachmentId}`)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["file-attachments"],
			})
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	return {
		bulkUpdateAttachments,
		fileAttachments,
		updateAttachment,
		uploadAttachment,
		deleteAttachment,
		queryIsPending,
		updateIsPending,
		bulkUpdateIsPending,
		uploadIsPending,
		deleteIsPending,
	}
}
