import { styled } from "@mui/material"
import { DynamicFile } from "@/icons/DynamicFile"
import { MouseEventHandler } from "react"

interface FileImgProps {
	filename?: string
	previewUrl?: string
	height?: string
	width?: string
	style?: React.CSSProperties
	onClick?: MouseEventHandler<HTMLDivElement>
}

export const FileImg: React.FC<FileImgProps> = ({
	previewUrl,
	style,
	height,
	width,
	filename,
	onClick,
}) => {
	return (
		<ImgContainer height={height} width={width} onClick={onClick}>
			{previewUrl ? (
				<FileIconWrapper height={height} width={width}>
					<StyledImg style={style} src={previewUrl} alt={filename || previewUrl} />
				</FileIconWrapper>
			) : (
				<>
					<FileIconWrapper height={height} width={width}>
						<DynamicFile style={style} filename={filename} width="50%" height="50%" />
					</FileIconWrapper>
				</>
			)}
		</ImgContainer>
	)
}

const ImgContainer = styled("div", {
	shouldForwardProp: prop => prop !== "height" && prop !== "width",
})<{ height?: string; width?: string }>`
	border-radius: 28px;
	height: ${({ height }) => (height ? height : "auto")};
	width: ${({ width }) => (width ? width : "auto")};
`

const FileIconWrapper = styled("div", {
	shouldForwardProp: prop => prop !== "height" && prop !== "width",
})<{ height?: string; width?: string }>`
	height: ${({ height }) => (height ? height : "136px")};
	width: ${({ width }) => (width ? width : "136px")};

	border-radius: 28px;
	background-color: ${({ theme }) => theme.colorPalette.surface.dim};
	color: ${({ theme }) => theme.colorPalette.outline.value};
	display: flex;
	justify-content: center;
	align-items: center;
`

const StyledImg = styled("img")`
	border-radius: 28px;
	height: 100%;
	width: 100%;
	object-fit: cover;
`
